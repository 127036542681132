import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Outlet, useParams } from "react-router-dom";
import { Button, Stack } from "@mui/joy";
import { useState, useCallback, useMemo } from "react";
import PersonRemoveRoundedIcon from "@mui/icons-material/PersonRemoveRounded";
import EmailIcon from "@mui/icons-material/EmailRounded";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import PageHeader from "../../components/page-header";
import { PageNavTab } from "../../components/page-nav-tab";
import StickyHeader from "../../components/sticky-header";
import AddIcon from "@mui/icons-material/Add";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddCamera from "./components/add-camera";
import Loader from "../../components/loader";
import DeleteAccount from "../accounts/components/delete";
import DeactivateAccount from "./components/deactivate";
import ActivationEmail from "./components/activation-email";
import Box from "@mui/joy/Box";
import Restore from "./components/restore";
import Activate from "./components/activate";
import { useGetAccount, useGetAccountCounters } from "../../query/accounts-query";
import MediaInfoDrawer from "../../components/camera/media-info-drawer";
import AddNoteModal from "./components/note-modal";

export const AccountIndex = () => {
    const { id, mediaId } = useParams();
    const navigate = useNavigate();
    const [ showAddCamera, setShowAddCamera ] = useState(false);
    const [ deleteAccount, setDeleteAccount ] = useState<boolean>(false);
    const [ showNotesModal, onChangeShowNotesModal ] = useState<boolean>(false);
    const [ deactivateAccount, setDeactivateAccount ] = useState<boolean>(false);
    const [ activationEmail, setActivationEmail ] = useState<boolean>(false);
    const [ restoreAccount, setRestoreAccount ] = useState<boolean>(false);
    const [ activateAccount, setActivateAccount ] = useState<boolean>(false);
    const { data, isLoading } = useGetAccount(parseInt(id as string));
    const { data: counters, refetch: refetchCounters } = useGetAccountCounters(parseInt(id as string));
    let account = data ?? null;

    const showAddCameraClick = useCallback(() => {
        setShowAddCamera(true);
    }, []);

    const onDeleteAccountClick = useCallback(() => {
        setDeleteAccount(true);
    }, []);

    const onResendEmailClick = useCallback(() => {
        setActivationEmail(true);
    }, []);

    const deleteAccountSuccessCallBack = useCallback(() => {
        setDeleteAccount(false);
        navigate(`/accounts`);
    }, [ navigate ]);

    const deleteAccountFailureCallBack = useCallback(() => {
        setDeleteAccount(false);
    }, []);

    const onDeleteAccountClose = useCallback(() => {
        setDeleteAccount(false);
    }, []);

    const onDeactivateAccountClick = useCallback(() => {
        setDeactivateAccount(true);
    }, []);

    const deactivateAccountSuccessCallBack = useCallback(() => {
        setDeactivateAccount(false);
    }, []);

    const onDeactivateAccountClose = useCallback(() => {
        setDeactivateAccount(false);
    }, []);

    const activationEmailSuccessCallback = useCallback(() => {
        setActivationEmail(false);
    }, []);

    const onActivationEmailClose = useCallback(() => {
        setActivationEmail(false);
    }, []);

    const tabs = useMemo(() => ([
        { name: "General", to: `/account/${id}` },
        { name: `Subscriptions [${counters?.subscriptions ?? 0}]`, to: `/account/${id}/subscriptions` },
        { name: `Subscriptions History [${counters?.subscriptionsHistory ?? 0}]`, to: `/account/${id}/subscriptions-history` },
        { name: `Cameras [${counters?.cameras ?? 0}]`, to: `/account/${id}/cameras` },
        { name: `Media [${counters?.media ?? 0}]`, to: `/account/${id}/media` },
        { name: `Exchange History [${counters?.exchangeHistory ?? 0}]`, to: `/account/${id}/exchange-history` },
        { name: `Camera Deactivation [${counters?.cameraDeactivation ?? 0}]`, to: `/account/${id}/camera-deactivation` },
        { name: `Equipment Return [${counters?.equipmentReturn ?? 0}]`, to: `/account/${id}/equipment-return` },
        { name: `Notes [${counters?.notes ?? 0}]`, to: `/account/${id}/notes` },
    ]), [id, counters])

    return (
        <>
            <DeleteAccount
                open={deleteAccount}
                onClose={onDeleteAccountClose}
                account={account}
                onSuccessCallback={deleteAccountSuccessCallBack}
                onFailureCallback={deleteAccountFailureCallBack}
            />
            <AddNoteModal refetchCounters={refetchCounters} accountId={parseInt(id as string)} open={showNotesModal} onClose={() => onChangeShowNotesModal(false)} />
            <DeactivateAccount open={deactivateAccount} onClose={onDeactivateAccountClose} account={account}
                               onSuccessCallback={deactivateAccountSuccessCallBack}/>
            <ActivationEmail open={activationEmail} onClose={onActivationEmailClose} account={account}
                             onSuccessCallback={activationEmailSuccessCallback}/>
            <Activate
                open={activateAccount}
                onClose={() => {
                    setActivateAccount(false);
                }} account={account}
                onSuccessCallback={() => {
                    window.location.reload();
                }}/>
            <AddCamera open={showAddCamera} account={account} onClose={() => {
                setShowAddCamera(false);
            }}/>
            <Restore
                open={restoreAccount}
                account={account}
                onClose={() => {
                    setRestoreAccount(false);
                }}
                onSuccessCallback={() => {
                    window.location.reload();
                }}
            />
            <StickyHeader>
                <PageHeader title={`Account: ${account?.first_name}`} breadCrumbs={[ {
                    name: "Accounts",
                    to: "/accounts"
                }, { name: `Account: ${account?.email}` } ]}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        {(!isLoading && account?.status.toLowerCase() === "inactive") &&
                          <Button color="warning" size="sm" onClick={() => setActivateAccount(true)}>
                            &nbsp;Activate
                          </Button>
                        }
                        {(!isLoading && account?.status.toLowerCase() === "deleted") &&
                          <Button color="warning" size="sm" onClick={() => setRestoreAccount(true)}>
                            <RefreshIcon/>
                            &nbsp;Restore
                          </Button>
                        }
                        <Button color="warning" size="sm" onClick={onResendEmailClick}
                                disabled={account?.status.toLowerCase() === "deleted"}>
                            <EmailIcon/>
                            &nbsp;Resend activation email
                        </Button>
                        <Button size={"sm"} onClick={showAddCameraClick}><AddIcon/> Add camera</Button>
                        <Button color="warning" size="sm" onClick={() => onChangeShowNotesModal(true)}><NoteAddIcon/> Add note</Button>
                        {
                            !isLoading && account?.status === "active"
                            &&
                          <Button color={"warning"} size={"sm"} onClick={onDeactivateAccountClick}>Deactivate</Button>
                        }
                        <Button color="danger" size="sm" onClick={onDeleteAccountClick}>
                            <PersonRemoveRoundedIcon/>
                            &nbsp;Delete
                        </Button>
                    </Stack>
                </PageHeader>
                <PageNavTab list={tabs}/>
            </StickyHeader>
            {isLoading && <Loader/>}
            {!isLoading && (
                <Box sx={{
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                    gap: 1,
                    px: { xs: 2, md: 6 },
                    py: { md: 3 },
                }}>
                    <Outlet context={{ account: account }}/>
                </Box>
            )}
            {id && mediaId ? <MediaInfoDrawer account id={id} mediaId={mediaId}/> : null}
        </>
    );
};
