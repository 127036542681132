import * as React from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/joy/Button";
import { ChangeEvent, useMemo, useState } from "react";
import { useGetCameraModels } from "../../../query/cameras-query";
import { capitalize } from "lodash";

type FiltersProps = {
    filters: any,
    applyFilters: any
}

const defaultFilters = {
    search: "",
    status: null
}

export const Filters = ({filters, applyFilters}: FiltersProps) => {
    const { data } = useGetCameraModels();

    const [filterData, setFilterData] = useState<Record<string, any>>({...defaultFilters, ...filters});

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFilterData({
            ...filterData,
            [name]: value
        });
    }

    const onSelectChange = (name: string, value: number) => {
        setFilterData({
            ...filterData,
            [name]: value
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const o = Object.fromEntries(Object.entries(filterData).filter(([_, v]) => (v != null && v != "")));
        applyFilters(o);
    }

    const modelsOptions = useMemo(() => {
        if (data?.list) {
            return data.list.map((item) => ({
                value: item.id,
                label: capitalize(item.label)
            }));
        }
        return [];
    }, [data]);

    return (
        <form onSubmit={onSubmit}>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: 'sm',
                    py: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: '160px',
                    },
                }}
            >
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search by name</FormLabel>
                    <Input size="sm" name={"search"} onChange={onChange} placeholder="Search by Name, Phone.." startDecorator={<SearchIcon />} />
                </FormControl>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search by email</FormLabel>
                    <Input size="sm" name="email" onChange={onChange} placeholder="Search by Email" startDecorator={<SearchIcon />} />
                </FormControl>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search by ICCID</FormLabel>
                    <Input size="sm" name="iccid" onChange={onChange} placeholder="Search by ICCID" startDecorator={<SearchIcon />} />
                </FormControl>
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search by IMEI</FormLabel>
                    <Input size="sm" name="imei" onChange={onChange} placeholder="Search by IMEI" startDecorator={<SearchIcon />} />
                </FormControl>
                <React.Fragment>
                    <FormControl size="sm">
                        <FormLabel>Camera model</FormLabel>
                        <Select
                            size="sm"
                            multiple
                            name="camera_models"
                            placeholder="Filter by camera model"
                            onChange={(event: any, value: any) => {
                                onSelectChange('camera_models', value);
                            }}
                            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                        >
                            {modelsOptions.map((item, idx) => (
                                <Option key={idx} value={item.value}>{item.label}</Option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>Status</FormLabel>
                        <Select
                            size="sm"
                            placeholder="Filter by status"
                            onChange={(event: any, value: any) => {
                                onSelectChange('status', value);
                            }}
                            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                        >
                            <Option value="">All</Option>
                            <Option value="active">Active</Option>
                            <Option value="inactive">Inactive</Option>
                            <Option value="deleted">Deleted</Option>
                        </Select>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>&nbsp;</FormLabel>
                        <Button size={"sm"} type={"submit"}>Apply</Button>
                    </FormControl>
                </React.Fragment>
            </Box>
        </form>
    );
}

export default Filters;
