import * as React from "react";
import { useOutletContext } from "react-router-dom";
import { Button, FormControl, FormLabel, Input, Box } from "@mui/joy";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import AspectRatio from "@mui/joy/AspectRatio";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ProfileImg from "../../images/profile.png";
import { ChangeEvent, useState, useContext } from "react";
import ApiUsers from "../../api/users";
import { AppContext } from "../../components/app-context";
import { User } from "../../types/user";
import { useGetRoles } from "../../query/users-query";
import { errorsToObject, handleAxiosError } from "../../components/utils";
import { AxiosError } from "axios";

type Form = {
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    phone?: string,
    role: string,
};

export const General = () => {

    const { onNotification } = useContext(AppContext);
    const { user } = useOutletContext<{ user: User }>();
    const initialForm = {
        username: user?.username,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone: user?.phone || "",
        role: user?.role?.name,
    };
    const { data: rolesResponse } = useGetRoles();
    const [ loading, onChangeLoading ] = useState<boolean>(false);
    const [ formData, setFormData ] = useState<Form>(initialForm);
    const [ errors, setErrors ] = useState<any[]>([]);
    const [ changed, setChanged ] = useState(false);

    const roles = Object.values(rolesResponse ?? {});

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    };

    const onSelectChange = (name: string, value: number) => {
        setFormData({
            ...formData,
            [name]: value
        });
        setChanged(true);
    };

    const onCancel = () => {
        setFormData(initialForm);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onChangeLoading(true);

        try {
            await ApiUsers.assignRole(user.id, formData.role);
            onNotification("User info updated");
        } catch (error) {
            handleAxiosError(
                error as AxiosError,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }
        onChangeLoading(false);
    };

    return (
        <Stack
            spacing={4}
            sx={{
                display: "flex",
                maxWidth: "800px",
                mx: "auto",
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}
        >
            <form onSubmit={onSubmit}>
                <Card>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">User info</Typography>
                    </Box>
                    <Divider/>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
                    >
                        <Stack direction="column" spacing={1}>
                            <AspectRatio
                                ratio="1"
                                maxHeight={200}
                                sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                            >
                                <img
                                    src={ProfileImg}
                                    srcSet={ProfileImg}
                                    loading="lazy"
                                    alt=""
                                />
                            </AspectRatio>
                        </Stack>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                            <Stack spacing={1}>
                                <FormControl sx={{ display: { sm: "flex-column", md: "flex-row" } }}>
                                    <FormLabel>First Name</FormLabel>
                                    <Input size="sm" placeholder="First name" name={"first_name"} onChange={onChange}
                                           value={formData?.first_name || ""}/>
                                </FormControl>
                                <FormControl sx={{ display: { sm: "flex-column", md: "flex-row" } }}>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input size="sm" placeholder="Last name" sx={{ flexGrow: 1 }} onChange={onChange}
                                           name={"last_name"} value={formData?.last_name || ""}/>
                                </FormControl>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        size="sm"
                                        type="email"
                                        startDecorator={<EmailRoundedIcon/>}
                                        placeholder="email"
                                        sx={{ flexGrow: 1 }}
                                        name={"email"}
                                        value={formData?.email}
                                        onChange={onChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Phone</FormLabel>
                                    <Input size="sm" startDecorator={<PhoneRoundedIcon/>} onChange={onChange}
                                           value={formData?.phone || ""}/>
                                </FormControl>
                            </Stack>
                            <Stack>
                                <FormControl>
                                    <FormLabel>Role</FormLabel>
                                    <Select
                                        size="sm"
                                        name={"first_name"}
                                        onChange={(event: any, value: any) => {
                                            onSelectChange("role", value);
                                        }}
                                        value={formData?.role}
                                    >
                                        {roles.map(role =>
                                            <Option key={role.name} value={role.name}>{role.name}</Option>
                                        )}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Stack>
                    <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
                        <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                            <Button size="sm" variant="outlined" onClick={onCancel} color="neutral">
                                Cancel
                            </Button>
                            <Button size="sm" type="submit" disabled={!changed} loading={loading} variant="solid">
                                Save
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </form>
        </Stack>
    );
};
