import * as React from 'react';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Stack} from '@mui/joy';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Alert from '@mui/joy/Alert';
import Typography from '@mui/joy/Typography';
import {models, storage, searchSingleCamera, createCamera} from "../../../api/cameras";
import {errorsToObject, isValidImei} from "../../../components/utils";
import ModalWrapper from "../../../components/modal";
import FormHelperText from "@mui/joy/FormHelperText";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ModalLoader from "../../../components/modal/loader";
import TextInput from "../../../components/form/TextInput";
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import {AppContext} from "../../../components/app-context";
import {useNavigate} from "react-router-dom";
import {attachCamera} from "../../../api/account-cameras";
import {handleAxiosError} from "../../../components/utils";
import { useGetCameraModels } from "../../../query/cameras-query";

type ModalProps = {
    open: boolean,
    onClose: any,
    account: any
}

export const AddCamera = ({open, account, onClose}: ModalProps) => {

    const {onNotification} = useContext(AppContext);
    const { data } = useGetCameraModels();
    const [isLoading, setIsLoading] = useState(true);
    const [sdCards, setSdCards] = useState<any[]>([]);
    const cameraModels = data?.list ?? [];
    const [searchDone, setSearchDone] = useState(false);
    const [foundCamera, setFoundCamera] = useState<any>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const responseStorage = await storage();
            setSdCards(responseStorage.data.list);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsLoading(false);
    }

    const searchCallback = (foundCamera: any) => {
        setSearchDone(true);
        setFoundCamera(foundCamera);
    }

    const onSearchBack = () => {
        setSearchDone(false);
        setFoundCamera(null);
    }

    const onModalClose = () => {
        setSearchDone(false);
        setFoundCamera(null);

        onClose();
    }

    let showMoveCamera = false;
    let showCreateCamera = false;
    let showCameraAttached = false;

    if (!isLoading && searchDone) {
        if (foundCamera.id) {
            if (foundCamera.accountCamera && foundCamera.accountCamera.account_id === account.id) {
                showCameraAttached = true;
            } else {
                showMoveCamera = true;
            }
        } else {
            showCreateCamera = true;
        }
    }

    return (
        <ModalWrapper open={open} onClose={onModalClose}>
            <DialogTitle>Add camera</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {isLoading && <ModalLoader/>}
                    {!isLoading && !searchDone && <SearchCamera searchCallback={searchCallback}/>}
                    {showCreateCamera && <CreateCameraForm account={account} camera={foundCamera} onClose={onModalClose} cameraModels={cameraModels} sdCards={sdCards} onSearch={onSearchBack} />}
                    {showMoveCamera && <MoveCamera account={account} camera={foundCamera} onClose={onModalClose} cameraModels={cameraModels} sdCards={sdCards} onSearch={onSearchBack} />}
                    {showCameraAttached && <CameraAttached onClose={onModalClose} onSearch={onSearchBack} />}
                </Stack>
            </DialogContent>
        </ModalWrapper>
    );
}

export default AddCamera;

type SearchCameraProps = {
    searchCallback: any
}

const SearchCamera = ({searchCallback}: SearchCameraProps) => {

    const {onNotification} = useContext(AppContext);
    const [imei, setImei] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        if (error.length > 0) {
            setError('');
        }

        setImei(value);
        if (value.length === 15) {
            if (!isValidImei(value)) {
                setError(`${name.toUpperCase()} should be 15 digits number`);
            }
        }
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!isValidImei(imei)) {
            setError('IMEI should be 15 digits number');
            return;
        }

        setIsLoading(true);

        try  {
            const {data} = await searchSingleCamera(imei);
            searchCallback(data.camera || {imei: imei});
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsLoading(false);
    }

    const hasError = error.length > 0;

    return (
        <form onSubmit={onSubmit}>
            <FormControl error={hasError}>
                <FormLabel>IMEI (search for a camera first)</FormLabel>
                <Input disabled={isLoading} name={"imei"} onChange={onChange} value={imei}/>
                {
                    hasError &&
                    <FormHelperText>
                        <InfoOutlined />
                        {error}
                    </FormHelperText>
                }
            </FormControl>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{paddingTop: "20px"}}
            >
                <Button type="submit" color={"primary"} variant={"solid"} disabled={imei.length === 0} loading={isLoading}>Search</Button>
            </Stack>
        </form>
    );
}

type Form = {
    id: number | null,
    name?: string,
    imei: string,
    iccid?: string,
    model_id?: number,
    storage_id?: number
}

type CreateCameraProps = {
    camera: any,
    onSearch: any,
    cameraModels: any[],
    sdCards: any[],
    onClose: any,
    account: any
};


const MoveCamera = ({account, camera, cameraModels, sdCards, onSearch, onClose}: CreateCameraProps) => {

    const {onNotification} = useContext(AppContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<Form>({
        id: camera?.id,
        imei: camera?.imei,
        iccid: camera?.iccid || "",
        name: "",
        model_id: camera?.model_id,
        storage_id: camera?.storage_id
    });
    const [errors, setErrors] = useState<any>({});

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSelectChange = (name: string, value: number) => {
        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const {data} = await attachCamera(account.id, formData);
            onNotification("Camera successfully attached");
            navigate(`/account/${account.id}/cameras`);
            onClose();
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    }

    const cameraAttached = camera.accountCamera !== null;

    return (
        <form onSubmit={onSubmit}>
            {cameraAttached && (
                <Alert
                    key={"Warning"}
                    sx={{ alignItems: 'flex-start' }}
                    startDecorator={<WarningIcon />}
                    variant="soft"
                    color={"warning"}
                >
                    <div>
                        <div>Warning</div>
                        <Typography level="body-sm" color={"warning"}>
                            This camera attached to another account. You can move this camera to a new account or go back to search.
                        </Typography>
                    </div>
                </Alert>
            )}
            {!cameraAttached && (
                <Alert
                    key={"Warning"}
                    sx={{ alignItems: 'flex-start' }}
                    startDecorator={<WarningIcon />}
                    variant="soft"
                    color={"warning"}
                >
                    <div>
                        <div>Warning</div>
                        <Typography level="body-sm" color={"warning"}>
                            Camera already exist. You can move this camera to account or go back to search.
                        </Typography>
                    </div>
                </Alert>
            )}
            <TextInput
                label={'Name'}
                placeholder={'Enter Name'}
                name={'name'}
                onChange={onChange}
                value={formData.name}
                error={errors['name']}
            />
            <TextInput
                label={'IMEI'}
                placeholder={'Enter IMEI'}
                readOnly={true}
                name={'imei'}
                onChange={onChange}
                value={formData.imei}
            />
            <TextInput
                label={'ICCID'}
                placeholder={'Enter ICCID'}
                name={'iccid'}
                onChange={onChange}
                value={formData.iccid}
                error={errors['iccid']}
            />
            <FormControl>
                <FormLabel>Model</FormLabel>
                <Select
                    placeholder="Select a model"
                    name="model_id"
                    color={!!errors['model_id'] ? "danger" : "neutral"}
                    onChange={(event: any, value: any) => {
                        onSelectChange('model_id', value);
                    }}
                    value={formData.model_id}
                    sx={{minWidth: 200}}
                >
                    {cameraModels.map((model: any) => {
                        return (
                            <Option key={model.id} value={model.id}>{model?.label}</Option>
                        )
                    })}
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>SD Card</FormLabel>
                <Select
                    placeholder="Select SD Card"
                    name="storage_id"
                    onChange={(event: any, value: any) => {
                        onSelectChange('storage_id', value);
                    }}
                    value={formData.storage_id}
                    sx={{minWidth: 200}}
                >
                    {sdCards.map((card: any) => {
                        return (
                            <Option key={card.id} value={card.id}>{card?.label}</Option>
                        )
                    })}
                </Select>
            </FormControl>
            <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{paddingTop: "20px"}}
                >
                <Button color={"neutral"} onClick={onClose}>Close</Button>
                <Button type="button" color={"warning"} loading={isLoading} onClick={onSearch}>Back To Search</Button>
                <Button type="submit" loading={isLoading}>Attach</Button>
            </Stack>
        </form>
    )
}

type CameraAttachedProps = {
    onSearch: any,
    onClose: any,
};

const CameraAttached = ({onSearch, onClose}: CameraAttachedProps) => {

    return (
        <form>
            <Alert
                key={"Warning"}
                sx={{ alignItems: 'flex-start' }}
                startDecorator={<WarningIcon />}
                variant="soft"
                color={"warning"}
            >
                <div>
                    <div>Warning</div>
                    <Typography level="body-sm" color={"warning"}>
                        This camera already attached to account.
                    </Typography>
                </div>
            </Alert>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{paddingTop: "20px"}}
            >
                <Button color={"neutral"} onClick={onClose}>Close</Button>
                <Button type="button" color={"warning"} onClick={onSearch}>Back To Search</Button>
            </Stack>
        </form>
    )
}

const CreateCameraForm = ({camera, account, cameraModels, sdCards, onSearch, onClose}: CreateCameraProps) => {

    const {onNotification} = useContext(AppContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<Form>({
        id: null,
        imei: camera.imei,
        iccid: camera?.iccid || "",
        name: "",
        model_id: 0,
        storage_id: 0
    });
    const [errors, setErrors] = useState<any>({});

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSelectChange = (name: string, value: number) => {
        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const {data} = await createCamera(formData);
            const response = await attachCamera(account.id, {
                id: data.id,
                name: formData.name || "Exodus Camera",
                imei: data.imei,
                iccid: data.iccid
            });
            onNotification("Camera successfully created");
            navigate(`/camera/${data.id}`);
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    }

    return (
        <form onSubmit={onSubmit}>
            <Alert
                key={"Info"}
                sx={{ alignItems: 'flex-start' }}
                startDecorator={<InfoIcon />}
                variant="soft"
                color={"success"}
            >
                <div>
                    <div>Info</div>
                    <Typography level="body-sm" color={"success"}>
                        Camera with such IMEI not registered yet. You can create a new camera.
                    </Typography>
                </div>
            </Alert>
            <TextInput label={'Name'} placeholder={'Enter Name'} name={'name'} onChange={onChange} value={formData.name}/>
            <TextInput label={'IMEI'} placeholder={'Enter IMEI'} readOnly={true} name={'imei'} onChange={onChange} value={formData.imei}/>
            <TextInput
                label={'ICCID'}
                placeholder={'Enter ICCID'}
                name={'iccid'}
                onChange={onChange}
                value={formData.iccid}
                error={errors['iccid']}
            />
            <FormControl>
                <FormLabel>Model</FormLabel>
                <Select
                    placeholder="Select a model"
                    name="model_id"
                    color={!!errors['model_id'] ? "danger" : "neutral"}
                    disabled={isLoading}
                    onChange={(event: any, value: any) => {
                        onSelectChange('model_id', value);
                    }}
                    sx={{minWidth: 200}}
                >
                    {cameraModels.map((model: any) => {
                        return (
                            <Option key={model.id} value={model.id}>{model?.label}</Option>
                        )
                    })}
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>SD Card</FormLabel>
                <Select
                    placeholder="Select SD Card"
                    name="storage_id"
                    disabled={isLoading}
                    onChange={(event: any, value: any) => {
                        onSelectChange('storage_id', value);
                    }}
                    sx={{minWidth: 200}}
                >
                    {sdCards.map((card: any) => {
                        return (
                            <Option key={card.id} value={card.id}>{card?.label}</Option>
                        )
                    })}
                </Select>
            </FormControl>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{paddingTop: "20px"}}
            >
                <Button type="submit" loading={isLoading}>Create</Button>
                <Button type="submit" color={"warning"} loading={isLoading} onClick={onSearch}>Back To Search</Button>
                <Button color={"neutral"} onClick={onClose}>Close</Button>
            </Stack>
        </form>
    );
}

