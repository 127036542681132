import Box from "@mui/joy/Box";
import {
    AreaChart,
    XAxis,
    ResponsiveContainer,
    YAxis,
    CartesianGrid,
    Area,
    Tooltip, PieChart, Pie, Cell, PieLabelRenderProps, TooltipProps,
} from "recharts";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/joy/CircularProgress";
import React from "react";

export type Item = Record<string, any>;

type Props = {
    axis: {
        x: string;
        y: string;
    };
    loading?: boolean;
    label?: boolean;
    fields?: string[];
    field?: string;
    title: string;
    data: Item[];
    height?: number;
}

const COLORS = [ "#0B6BCB", "#C41C1C", "#51BC51", "#F3C896" ];

const getColor = (id: number) => {
    return COLORS[id] ? COLORS[id] : COLORS[id % COLORS.length];
}

const Graph = ({ axis, title, data, field = "count", loading, fields = [], height = 300 }: Props) => {
    return (
        <Box>
            <Typography level="h2" sx={{marginBottom: 1, fontSize: 'xl', textAlign: "center"}}>{loading ? "Loading..." : title}</Typography>
            {loading
                ? <Box sx={{height, display: "flex", alignItems: "center", justifyContent: "center"}}><CircularProgress/></Box>
                : (
                    <ResponsiveContainer height={height}>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 20, right: 20, bottom: 20, left: 20,
                            }}
                        >
                            <defs>
                                {COLORS.map((color, idx) => (
                                    <linearGradient key={idx} id={`areaGradient_${idx}`} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="35%" stopColor={color} stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor={color} stopOpacity={0.1}/>
                                    </linearGradient>
                                ))}
                            </defs>
                            <XAxis
                                dataKey="label"
                                label={{ value: axis.x, offset: 0, position: "bottom" }}
                            />
                            {fields.length > 0
                                ? <YAxis/>
                                : <YAxis
                                    dataKey={field}
                                    label={{ value: axis.y, offset: 20, angle: -90, position: "insideBottomLeft" }}
                                />
                            }
                            <Tooltip/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            {fields.length > 0
                                ? fields.map((field, idx: number) => (
                                    <Area type="monotone" key={idx} dataKey={field} stroke={getColor(idx)} fill={`url(#areaGradient_${idx % COLORS.length})`}/>
                                ))
                                : <Area type="monotone" dataKey={field} stroke="#185EA5" fill="url(#areaGradient_0)"/>
                            }
                        </AreaChart>
                    </ResponsiveContainer>
                )}
        </Box>
    );
}


const CustomTooltip = ({ active, payload }: TooltipProps<any, any>) => {
    if (active && payload && payload.length) {
        const value = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
            payload[0].value,
        );
        return (
            <Box sx={{ background: "#fff", p: 0.2, border: `1px solid rgba(0,0,0,0.1)`}}>
                <Typography>
                    <span style={{ textTransform: "uppercase"}}>
                        {payload[0].payload.label}: </span>{value}
                </Typography>
            </Box>
        );
    }

    return null;
};

export const PieGraph = ({ title, data, field = "count", loading, height = 300 }: Props) => {
    const renderCustomizedLabel = ({ index = 0 } : PieLabelRenderProps) => {
        const label = data[index].label;
        const value = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
            data[index].count
        );

        return (`${label.toUpperCase()}: ${value}`);
    };
    return (
        <Box>
            <Typography level="h2" sx={{marginBottom: 1, fontSize: 'xl', textAlign: "center"}}>{loading ? "Loading..." : title}</Typography>
            {loading
                ? <Box sx={{height, display: "flex", alignItems: "center", justifyContent: "center"}}><CircularProgress/></Box>
                : (
                    <ResponsiveContainer height={height}>
                        <PieChart
                            margin={{
                                top: 20, right: 20, bottom: 20, left: 20,
                            }}
                        >
                            <Pie
                                data={data}
                                dataKey={field}
                                label={renderCustomizedLabel}
                                cx="50%"
                                labelLine
                                cy="50%"
                                outerRadius={120}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip content={(contentProps) => (<CustomTooltip {...contentProps}/>)} />
                        </PieChart>
                    </ResponsiveContainer>
                )
            }
        </Box>
    );
}

export default Graph;
