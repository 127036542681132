import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import CameraRoundedIcon from "@mui/icons-material/CameraRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import { Profile } from "./profile";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { closeSidebar } from "./utils";
import { AppContext } from "../../components/app-context";
import { PermissionType } from "../../components/ability";
import Logo from "../../images/logo_only.png";

type NavMenuItem = {
    to: string,
    name: string,
    icon: any,
    activeOn: string[],
    permission: PermissionType | null,
}

const sideNavMenu = [
    { to: "/", name: "Dashboard", icon: <DashboardRoundedIcon/>, activeOn: [] },
    { to: "/accounts", name: "Accounts", icon: <PeopleRoundedIcon/>, activeOn: [ "/accounts", "/account" ] },
    { to: "/cameras", name: "Cameras", icon: <CameraRoundedIcon/>, activeOn: [ "/cameras", "/camera" ] },
    {
        to: "/exchange-history",
        name: "Exchange history",
        icon: <HistoryRoundedIcon/>,
        activeOn: [ "/exchange-history" ]
    },
    {
        to: "/firmwares",
        name: "Firmwares",
        icon: <MemoryRoundedIcon/>,
        activeOn: [ "/firmware" ],
        permission: { action: "manage", subject: "Firmware" }
    },
    {
        to: "/users",
        name: "Users",
        icon: <PeopleRoundedIcon/>,
        activeOn: [ "/users", "/user" ],
        permission: { action: "manage", subject: "User" }
    },
] as NavMenuItem[];

export default function Sidebar() {

    const location = useLocation();
    const { ability } = useContext(AppContext);
    const [ currentPath, setCurrentPath ] = useState("");

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [ location ]);

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: "fixed", md: "sticky" },
                transform: {
                    xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
                    md: "none",
                },
                transition: "transform 0.4s, width 0.4s",
                zIndex: 10,
                height: "100dvh",
                width: "var(--Sidebar-width)",
                top: 0,
                p: 2,
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRight: "1px solid",
                borderColor: "divider",
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ":root": {
                        "--Sidebar-width": "220px",
                        [theme.breakpoints.up("lg")]: {
                            "--Sidebar-width": "240px",
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: "fixed",
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    opacity: "var(--SideNavigation-slideIn)",
                    backgroundColor: "var(--joy-palette-background-backdrop)",
                    transition: "opacity 0.4s",
                    transform: {
                        xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
                        lg: "translateX(-100%)",
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box>
                <Link to="/" style={{
                    display: "flex",
                    textDecoration: "none",
                    gap: 1,
                    alignItems: "center"
                }}>
                    <IconButton variant="soft" color="primary" size="sm">
                        <img style={{ width: "20px", display: "inline-block" }} src={Logo} alt={""}/>
                    </IconButton>
                    <Typography sx={{ ml: 1 }} level="title-lg">Exodus</Typography>
                </Link>
            </Box>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: "hidden auto",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        "--List-nestedInsetStart": "30px",
                        "--ListItem-radius": (theme) => theme.vars.radius.sm,
                    }}
                >
                    {sideNavMenu.map((item, index) => {
                        const { to, name, icon } = item;

                        if (item.permission && ability.cannot(item.permission.action, item.permission.subject)) {
                            return null;
                        }

                        const isSelected = currentPath === to || item.activeOn.some(activeOnItem => {
                            return currentPath === activeOnItem || (activeOnItem.length > 0 && currentPath.startsWith(activeOnItem));
                        });

                        return (
                            <ListItem key={`side-nav-${index}`}>
                                <ListItemButton to={to} component={Link} selected={isSelected}>
                                    {icon}
                                    <ListItemContent>
                                        <Typography level="title-sm">{name}</Typography>
                                    </ListItemContent>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
            <Divider/>
            <Profile/>
        </Sheet>
    );
}
