import React from 'react';
import {Routes, Route} from 'react-router';
import './App.scss';
import {SignInPage} from "./sign-in";
import {Dashboard} from "./dashboard";
import {Main} from "./main";
import {NotFound} from "./dashboard/not-found";
import {Accounts} from "./accounts";
import {AccountIndex} from "./account";
import {General as AccountGeneral} from "./account/general";
import {Media as AccountMedia} from "./account/media";
import {Cameras as AccountCameras} from "./account/cameras";
import {AppProvider} from "../components/app-provider";
import {CameraIndex} from "./camera";
import {General as CameraGeneral} from "./camera/general";
import {Media as CameraMedia} from "./camera/media";
import {Commands as CameraCommands} from "./camera/commands";
import {Reports as CameraReports} from "./camera/reports";
import {Settings as CameraSettings} from "./camera/settings";
import {default as CameraReport} from "./camera/report";
import {Cameras} from "./cameras";
import {EventsLog} from "./camera/events-log";
import {Sim as CameraSim} from "./camera/sim";
import {General as UserGeneral} from "./user/general";
import {Users} from "./users";
import {UserIndex} from "./user";
import {ProfileIndex} from "./profile";
import {General as ProfileGeneral} from "./profile/general";
import {Password as ProfilePassword} from "./profile/password";
import {Subscriptions as AccountSubscriptions} from "./account/subscriptions";
import {SubscriptionsHistory as AccountSubscriptionsHistory} from "./account/subscriptions-history";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from '@mui/joy/styles';
import PrivateRoute from "../components/private-route";
import NotificationBar from "../components/notification";
import {Firmwares} from "./firmwares";
import {ExchangeHistory} from "./account/exchange-history";
import {CameraDeactivation} from "./account/camera-deactivation";
import {EquipmentReturn} from "./account/equipment-return";
import CameraErrors from "./camera/errors";
import ExchangeHistoryPage from "./exchange";
import CameraMediaErrors from "./camera/media-errors";
import { QueryClientProvider } from "react-query";
import queryClient from "../query/client";
import Notes from "./account/notes";

function App() {
    return (
        <CssVarsProvider>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <AppProvider>
                    <NotificationBar/>
                    <Routes>
                        <Route path={"/login"} element={<SignInPage/>}/>

                        <Route element={<PrivateRoute><Main/></PrivateRoute>}>
                            <Route path={"/"} element={<Dashboard/>}/>
                            <Route path={"/accounts"} element={<PrivateRoute><Accounts/></PrivateRoute>}/>
                            <Route path={"/users"} element={<Users/>}/>
                            <Route path={"/cameras"} element={<Cameras/>}/>
                            <Route path={"/account/:id"} element={<AccountIndex/>}>
                                <Route index element={<AccountGeneral/>} />
                                <Route path="cameras" element={<AccountCameras/>} />
                                <Route path="notes" element={<Notes/>} />
                                <Route path="media" element={<AccountMedia/>} >
                                    <Route path=":mediaId" element={<AccountMedia/>} />
                                </Route>
                                <Route path="subscriptions" element={<AccountSubscriptions/>} />
                                <Route path="subscriptions-history" element={<AccountSubscriptionsHistory/>} />
                                <Route path="exchange-history" element={<ExchangeHistory/>} />
                                <Route path="camera-deactivation" element={<CameraDeactivation/>} />
                                <Route path="equipment-return" element={<EquipmentReturn/>} />
                            </Route>
                            <Route path={"/camera/:id"} element={<CameraIndex/>}>
                                <Route index element={<CameraGeneral/>} />
                                <Route path="media" element={<CameraMedia/>} >
                                    <Route path=":mediaId" element={<CameraMedia/>} />
                                </Route>
                                <Route path="settings" element={<CameraSettings/>} />
                                <Route path="errors" element={<CameraErrors/>} />
                                <Route path="media-errors" element={<CameraMediaErrors/>} />
                                <Route path="sim" element={<CameraSim/>} />
                                <Route path="commands" element={<CameraCommands/>} />
                                <Route path="reports" element={<CameraReports/>} />
                                <Route path="report" element={<CameraReport/>} />
                                <Route path="events-log" element={<EventsLog/>} />
                            </Route>
                            <Route path={"/firmwares"} element={<Firmwares/>}/>
                            <Route path={"/user/:id"} element={<UserIndex/>}>
                                <Route index element={<UserGeneral/>} />
                            </Route>
                            <Route path={"/profile"} element={<ProfileIndex/>}>
                                <Route index element={<ProfileGeneral/>} />
                                <Route path="password" element={<ProfilePassword/>} />
                            </Route>
                            <Route path={"/exchange-history"} element={<ExchangeHistoryPage/>}/>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </AppProvider>
            </QueryClientProvider>
        </CssVarsProvider>
    );
}

export default App;
