import * as React from "react";
import { Outlet, useParams } from "react-router-dom";
import { Button, Stack } from "@mui/joy";
import PersonRemoveRoundedIcon from "@mui/icons-material/PersonRemoveRounded";
import PageHeader from "../../components/page-header";
import { PageNavTab } from "../../components/page-nav-tab";
import StickyHeader from "../../components/sticky-header";
import Loader from "../../components/loader";
import { useGetOneUser } from "../../query/users-query";

export const UserIndex = () => {
    let { id } = useParams();
    const { data: user, isLoading } = useGetOneUser(Number(id));

    return (
        <>
            <StickyHeader>
                <PageHeader title={`User: ${user?.username}`}
                            breadCrumbs={[ { name: "Users", to: "/users" }, { name: `User: ${user?.username}` } ]}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Button color="danger" size="sm">
                            <PersonRemoveRoundedIcon/>
                            Delete
                        </Button>
                    </Stack>
                </PageHeader>
                <PageNavTab list={[
                    { name: "General", to: `/user/${id}` }
                ]}/>
            </StickyHeader>
            {isLoading ? <Loader/> : <Outlet context={{ user: user }}/>}
        </>
    );
};
