import * as React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import IconButton from "@mui/joy/IconButton";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import DownloadIcon from "@mui/icons-material/DownloadRounded";
import CardOverflow from "@mui/joy/CardOverflow";
import Card from "@mui/joy/Card";
import { MediaType } from "../types";
import { fileSize, formatDateTime } from "../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import Image from "../image";

type MediaProps = {
    media: MediaType;
    account?: boolean;
};

export const Media = (props: MediaProps): any => {
    const { media, account } = props;
    const { file_size, name, created_at, original, thumb } = media;
    const params = useParams();
    const navigate = useNavigate();

    const handleClickMedia = useCallback(() => {
        const route = account ? `/account/${params.id}/media/${media.id}` : `/camera/${params.id}/media/${media.id}`;
        navigate(route);
    }, [ params, media, account, navigate ]);

    return (
        <Card variant="outlined" size="sm" sx={{ maxWidth: "320px" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ flex: 1 }}>
                    <Typography level="body-sm">{fileSize(file_size)}</Typography>
                </Box>
                <Dropdown>
                    <MenuButton variant="plain" size="sm"
                                sx={{ maxWidth: "32px", maxHeight: "32px", borderRadius: "100%" }}>
                        <IconButton
                            component="span"
                            variant="plain"
                            color="neutral"
                            size="sm"
                        >
                            <MoreVertRoundedIcon/>
                        </IconButton>
                    </MenuButton>
                    <Menu placement="bottom-end" size="sm"
                          sx={{ zIndex: "99999", p: 1, gap: 1, "--ListItem-radius": "var(--joy-radius-sm)" }}>
                        <MenuItem component="a" download={true} href={original}>
                            <DownloadIcon/>
                            Download file
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", cursor: "pointer" }}
                 onClick={handleClickMedia}>
                <CardOverflow
                    sx={{ borderBottom: "1px solid", borderTop: "1px solid", borderColor: "neutral.outlinedBorder" }}>
                    <Image src={thumb} alt={name}/>
                </CardOverflow>
                <Typography level="body-xs">{formatDateTime(created_at)}</Typography>
            </Box>
        </Card>
    );
};

export default Media;

