import * as React from "react";
import Stack from "@mui/joy/Stack";
import { useOutletContext } from "react-router-dom";
import { TableHover } from "../../components/table";
import TableSheet from "../../components/table-sheet";
import Box from "@mui/joy/Box";
import { useSearchParams } from "react-router-dom";
import { getNumberFromSearchParams, handleAxiosError } from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";
import { Account, AccountNote } from "../../types/account";
import { useGetAccountCounters, useGetAccountNotes } from "../../query/accounts-query";
import CircularProgress from "@mui/joy/CircularProgress";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { IconButton, ButtonGroup, Typography } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useContext, useState } from "react";
import accounts from "../../api/accounts";
import NoteModal from "./components/note-modal";
import { AppContext } from "../../components/app-context";
import DeleteNote from "./components/deleteNote";

export default function Notes() {
    const { account } = useOutletContext<{ account: Account }>();
    const [ editedNote, onChangeEditedNote ] = useState<AccountNote | undefined>(undefined);
    const [ deleteId, onChangeDeleteId ] = useState<number | undefined>(undefined);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, isLoading, refetch } = useGetAccountNotes(account.id);
    const { refetch: refetchCounters } = useGetAccountCounters(account.id);
    const totalPages = data?.pagination?.totalPages ?? 1;

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    const notes = data?.list ?? [];

    const onDeleteCallback = useCallback(async () => {
        await refetch();
        await refetchCounters();
    }, [refetch, refetchCounters]);

    return (
        <>
            {
                editedNote ?
                    <NoteModal
                        refetchCounters={refetchCounters}
                        note={editedNote}
                        open
                        accountId={account.id}
                        onClose={() => onChangeEditedNote(undefined)}
                    />
                    : null
            }
            {
                deleteId ?
                    <DeleteNote
                        noteId={deleteId}
                        accountId={account.id}
                        onSuccessCallback={onDeleteCallback}
                        onClose={() => onChangeDeleteId(undefined)}
                        open
                    />
                    : null
            }
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{ width: "100px" }}>ID</th>
                        <th style={{ width: "150px" }}>Author</th>
                        <th style={{ width: "150px" }}>Camera</th>
                        <th style={{ width: "200px" }}>Details</th>
                        <th style={{ width: "100px" }}>Created at</th>
                        <th style={{ width: "100px" }}/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        isLoading
                            ? <tr style={{ textAlign: "center" }}>
                                <td colSpan={6}><CircularProgress/></td>
                            </tr>
                            : notes.length > 0
                                ? notes.map((item) => (
                                    <tr key={item.id}>
                                        <td style={{ width: "100px" }}>
                                            <Typography level="body-xs">{item.id}</Typography>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <Typography level="body-xs">{item.author.name}</Typography>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <Typography level="body-xs">{item.camera?.name ?? "-"}</Typography>
                                        </td>
                                        <td style={{ width: "200px" }}>
                                            <Typography level="body-xs">{item.details}</Typography>
                                        </td>
                                        <td style={{ width: "300px" }}>
                                            <Typography level="body-xs">{item.createdAt}</Typography>
                                        </td>
                                        <td style={{ width: "100px" }}>
                                            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                                <ButtonGroup
                                                    color="neutral"
                                                    disabled={false}
                                                    size="sm"
                                                    variant="outlined"
                                                >
                                                    <IconButton
                                                        variant={"outlined"}
                                                        color={"primary"}
                                                        onClick={() => onChangeEditedNote(item)}
                                                    >
                                                        <EditRoundedIcon fontSize={"small"}/>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => onChangeDeleteId(item.id)}
                                                        variant={"outlined"}
                                                        color={"danger"}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </ButtonGroup>
                                            </Box>
                                        </td>
                                    </tr>
                                ))
                                : <tr>
                                    <td colSpan={6} style={{ textAlign: "center" }}>
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{
                                                paddingTop: "20px",
                                                paddingBottom: "20px"
                                            }}
                                        >
                                            <Typography level="body-md">Notes are not found</Typography>
                                        </Stack>
                                    </td>
                                </tr>
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
}
