import * as React from "react";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import { Media as CameraMedia } from "../../components/camera/media";
import { findCameraMedia } from "../../api/cameras";
import { useEffect, useState, useContext } from "react";
import { useOutletContext } from "react-router-dom";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import NoContent from "../../components/no-content";
import Typography from "@mui/joy/Typography";
import Pagination from "../../components/pagination";
import { PaginationMetadata } from "../../components/types";
import { AppContext } from "../../components/app-context";
import { handleAxiosError } from "../../components/utils";

export function Media() {
    const { onNotification } = useContext(AppContext);
    const { camera } = useOutletContext<{ camera: any }>();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ mediaList, setMediaList ] = useState<any[]>([]);
    const [ paginationMetadata, setPaginationMetadata ] = useState<PaginationMetadata>({
        totalPages: 1,
        currentPage: 1,
        limit: 10,
    });

    const handlePaginationChange = (page: number, perPage: number) => {
        fetchData(camera.id, page, perPage);
    };

    useEffect(() => {
        fetchData(camera.id, paginationMetadata.currentPage, paginationMetadata.limit);
    }, [ camera.id ]);

    const fetchData = async (id: number, page: number, limit: number) => {
        try {
            const { data } = await findCameraMedia(id, page, limit);
            setMediaList(data.list);
            setPaginationMetadata(data.pagination);
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    };

    return (
        <Stack spacing={4} sx={{
            display: "flex",
            minWidth: "600px",
            paddingLeft: "48px",
            paddingRight: "48px",
            px: { xs: 2, md: 6 },
        }}>
            {isLoading && <Grid container sx={{
                justifyContent: "center",
                alignContent: "center",
                padding: "100px"
            }}><CircularProgress sx={{
                "--CircularProgress-size": "60px"
            }}/></Grid>}
            {(!isLoading && mediaList.length === 0) && <NoContent><Typography>No media found</Typography></NoContent>}
            {(!isLoading && mediaList.length > 0) && (
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                        gap: 2,
                    }}
                >
                    {mediaList.map(media => {
                        return (
                            <CameraMedia key={media.id} media={media}/>
                        );
                    })}
                </Box>
            )}
            {!isLoading && mediaList.length > 0 && (
                <Pagination
                    count={paginationMetadata.totalPages}
                    page={paginationMetadata.currentPage}
                    onChange={handlePaginationChange}
                    limit={paginationMetadata.limit}
                />
            )}
        </Stack>
    );
}
