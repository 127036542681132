import client from './api';
import { FirmwareListResponse } from "../types/firmware";

const all = async (filters: any, page: number, limit: number):Promise<FirmwareListResponse> => {
    return client.get('/firmware', {
        params: {
            filters,
            page,
            limit
        }
    });
}

const create = async (data: any) => {
    return client.post('/firmware', data, {headers: { "Content-Type": "multipart/form-data" }});
}

const view = async (id: number) => {
    return client.get(`/firmware/${id}`);
}

const downloadUrl = (id: number) => {
    return client.get(`/firmware/${id}/download-url`);
}

const remove = async (id: number) => {
    return client.delete(`/firmware/${id}`);
}

export default {
    all,
    create,
    view,
    remove,
    downloadUrl
}
