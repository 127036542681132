import * as React from 'react';
import {useContext} from "react";
import {useOutletContext} from "react-router-dom";
import {Button, FormControl, FormLabel, Input, Box} from '@mui/joy';
import AspectRatio from '@mui/joy/AspectRatio';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import ProfileImg from '../../images/profile.png';
import {ChangeEvent, useState} from "react";
import {isEqual} from "../../components/utils";
import ApiProfile from "../../api/profile";
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";
import {errorsToObject} from "../../components/utils";

type Form = {
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    phone?: string
};

export const General = () => {

    const {onNotification} = useContext(AppContext);
    const {user} = useOutletContext<{ user: any }>();
    const initialForm = {
        username: user?.username,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone: user?.phone || ""
    }

    const [initialFormData, setInitialFormData] = useState<Form>(initialForm);
    const [formData, setFormData] = useState<Form>(initialForm);
    const [errors, setErrors] = useState<any[]>([]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSubmit = async () => {
        try {
            await ApiProfile.update(formData);
            onNotification('Profile info successfully updated', 'success');
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }
    }

    const onCancel = () => {
        setFormData(initialForm);
    }

    const changed = !isEqual(initialFormData, formData);

    return (
        <Stack
            spacing={4}
            sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}
        >
            <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">My Profile</Typography>
                </Box>
                <Divider />
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
                >
                    <Stack direction="column" spacing={1}>
                        <AspectRatio
                            ratio="1"
                            maxHeight={200}
                            sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
                        >
                            <img
                                src={ProfileImg}
                                srcSet={ProfileImg}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </Stack>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                        <Stack spacing={1}>
                            <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }}}>
                                <FormLabel>Username</FormLabel>
                                <Input size="sm" placeholder="Username" name={"username"} onChange={onChange} value={formData?.username || ""} />
                            </FormControl>
                            <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }}}>
                                <FormLabel>First Name</FormLabel>
                                <Input size="sm" placeholder="First name" name={"first_name"} onChange={onChange} value={formData?.first_name || ""} />
                            </FormControl>
                            <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }}}>
                                <FormLabel>Last Name</FormLabel>
                                <Input size="sm" placeholder="Last name" sx={{ flexGrow: 1 }} onChange={onChange} name={"last_name"} value={formData?.last_name || ""} />
                            </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <FormControl sx={{ flexGrow: 1 }}>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    size="sm"
                                    type="email"
                                    startDecorator={<EmailRoundedIcon />}
                                    placeholder="email"
                                    sx={{ flexGrow: 1 }}
                                    name={"email"}
                                    value={formData?.email}
                                    onChange={onChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input
                                    name="phone"
                                    size="sm"
                                    startDecorator={<PhoneRoundedIcon/>}
                                    onChange={onChange}
                                    value={formData?.phone || ""}
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                </Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm" variant="outlined" disabled={!changed} onClick={onCancel} color="neutral">
                            Cancel
                        </Button>
                        <Button size="sm" disabled={!changed} onClick={onSubmit} variant="solid">
                            Save
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </Stack>
    );
}