import * as React from 'react';
import {ChangeEvent, useState, useContext, useEffect} from "react";
import {CommandType} from "../../../components/types";
import {
    Modal,
    ModalDialog,
    Button,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Stack,
    Sheet, Chip,
} from '@mui/joy';
import FileDownload from '@mui/icons-material/FileDownload';
import ApiCameraCommands from "../../../api/camera-command";
import {AppContext} from "../../../components/app-context";
import {formatDateTime, handleAxiosError} from "../../../components/utils";
import {model} from "../../../api/cameras";
import {FirmwareType} from "../../../components/types";
import ApiFirmware from "../../../api/firmware";
import {downloadUrl, fileSize} from "../../../components/utils";
import Select from "../../../components/form/Select";
import {ColorPaletteProp} from "@mui/material";

function chipStatusColor(status: string): string {

    if (status === "deleted") {
        return "danger";
    }

    if (status === "inactive") {
        return "neutral";
    }

    return "success";
}

type TriggerCommandModalProps = {
    open: boolean,
    onClose: any,
    command?: CommandType,
    successCallback: any,
    camera: any
}

export const TriggerUpdateFirmwareCommandModal = ({open, onClose, command, successCallback, camera}: TriggerCommandModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [latestFirmware, setLatestFirmware] = useState<FirmwareType|null>(null);
    const [firmwares, setFirmwares] = useState<FirmwareType[]>([]);
    const [selectedFirmware, setSelectedFirmware] = useState<FirmwareType|null>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const cameraModel = await model(camera.model.id);
            const firmwares = await ApiFirmware.all({model_id: camera.model.id}, 1, 999);
            setLatestFirmware(cameraModel.data.latestFirmware);
            setFirmwares(firmwares.data.list);
            if (firmwares.data.list.length > 0) {
                setSelectedFirmware(firmwares.data.list[0]);
            }
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const onDownload = async (firmware: FirmwareType) => {
        try {
            const {data} = await ApiFirmware.downloadUrl(firmware.id);
            downloadUrl(data.url, firmware.filename);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const onFirmwareChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        const firmware = firmwares.find((firmware: any) => firmware.id === value) || null;
        setSelectedFirmware(firmware);
    }

    const onConfirmClick = async () => {
        setIsLoading(true);
        try {
            await ApiCameraCommands.trigger(
                camera.id,
                "UpdateFirmware",
                {params: {firmwareId: selectedFirmware?.id}}
            );
            successCallback();
            onClose();
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsLoading(false);
    }

    let statusColor = chipStatusColor(selectedFirmware?.status || "") as ColorPaletteProp;

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog" style={{minWidth: "600px"}}>
                <DialogTitle>
                    Firmware Update
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography level={"h4"}>Camera: <strong>{camera?.accountCamera?.name}</strong></Typography>
                    <Sheet variant="outlined" color="neutral" sx={{pl: 2, pr: 2, pt: 3, pb: 3}}>
                        <Typography>Latest: {latestFirmware ? latestFirmware?.version : 'N/A'}</Typography>
                        <Typography>Latest Created At: {latestFirmware ?  formatDateTime(latestFirmware?.created_at) : 'N/A'}</Typography>
                        <Typography>Camera: {camera?.report?.firmware_version || "Unknown"}</Typography>
                    </Sheet>
                    <Sheet variant="outlined" color="neutral" sx={{pl: 2, pr: 2, pt: 3, pb: 3, gap: 3, display: 'flex', flexDirection: 'column'}}>
                        <Stack>
                            <Typography level="title-md">Available firmware versions:</Typography>
                            <Select
                                placeholder="Select firmware version"
                                name="newFirmware"
                                data={firmwares.map(firmware => ({
                                    value: firmware.id,
                                    label: firmware.version,
                                }))}
                                onChange={onFirmwareChange}
                                value={selectedFirmware?.id}
                            />
                        </Stack>
                        <Stack direction="column">
                            <Stack direction={"row"} gap={2} sx={{mb: 1}}>
                                <Typography><b>Status:</b></Typography><Chip color={statusColor}>{selectedFirmware?.status || "N/A"}</Chip>
                            </Stack>
                            <Stack direction={"row"} gap={2} sx={{mb: 1}}>
                                <Typography><b>Created At:</b></Typography><Typography>{selectedFirmware ? formatDateTime(selectedFirmware?.created_at) : 'N/A'}</Typography>
                            </Stack>
                            <Typography><b>Manual download and install:</b></Typography>
                            {
                                selectedFirmware &&
                                <Stack direction="row" justifyContent="space-between" alignContent={"center"} alignItems={"center"} >
                                    <Typography>{selectedFirmware?.filename}</Typography>
                                    <Stack direction="row" alignContent={"center"} alignItems={"center"} gap={2}>
                                        <Typography>{fileSize(selectedFirmware.file_size)}</Typography>
                                        <IconButton
                                            variant={"outlined"}
                                            color={'primary'}
                                            onClick={() => selectedFirmware ? onDownload(selectedFirmware) : {}}>
                                            <FileDownload/>
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            }
                        </Stack>
                    </Sheet>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="solid"
                        color="warning"
                        loading={isLoading}
                        onClick={onConfirmClick}
                        disabled={!selectedFirmware}
                    >
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default TriggerUpdateFirmwareCommandModal;