import * as React from 'react';
import {Sheet} from "@mui/joy";

type TableSheetProps = {
    children?: any,
    sx?: any
};

export const TableSheet = ({children}: TableSheetProps) => {
    return (
        <Sheet
            variant="outlined"
            sx={{
                width: "100%",
                borderRadius: "sm",
                boxShadow: 'sm',
                flexShrink: 1,
                overflow: "auto",
            }}
        >
            {children}
        </Sheet>
    );
}

export default TableSheet;