import * as React from 'react';
import Container from '@mui/joy/Container';
import Grid from '@mui/joy/Grid';
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";


export const NotFound = () => {
  return (
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: 'neutral.400'}}>
              <Stack direction="column" alignItems="center" spacing={2}>
                  <Typography color="neutral" fontSize="8em">404</Typography>
                  <Typography color="neutral" level="h3">Page Not Found.</Typography>
                  <Typography color="neutral" level="title-md">Sorry, we can't find the page you're looking for.</Typography>
              </Stack>
          </Box>
  );
}