import client from './api';

const settings = async (id: number) => {
    return client.get(`camera/${id}/settings`);
}

const updateSettings = async (id: number, settings: any) => {
    return client.post(`camera/${id}/settings`, {
        settings: settings
    });
}

export default {
    settings,
    updateSettings
}