import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import {Button} from "@mui/joy";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import TextInput from "../../../components/form/TextInput";
import {ChangeEvent, useContext, useState} from "react";
import ApiCamera from "../../../api/cameras";
import {errorsToObject} from "../../../components/utils";
import {AppContext} from "../../../components/app-context";
import {handleAxiosError} from "../../../components/utils";
import {useOutletContext} from "react-router-dom";

const defaultsForm = {
    name: "",
    imei: "",
    iccid: ""
}

const Info = () => {

    const {camera, fetchCamera} = useOutletContext<{camera: any, fetchCamera: any}>();
    const {onNotification} = useContext(AppContext);
    const [formData, setFormData] = useState<any>({
        ...defaultsForm,
        ...{imei: camera?.imei, iccid: camera?.iccid, name: camera?.accountCamera?.name || ""}
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await ApiCamera.update(camera.id, formData);
            onNotification("Camera updated");
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    }

    const onCancel = () => {
        setFormData({
            ...defaultsForm,
            ...{imei: camera?.imei, iccid: camera?.iccid, name: camera?.accountCamera?.name || ""}
        })
    }

    return (
        <form onSubmit={onSubmit}>
            <Card>
                <Box>
                    <Typography level="title-md">Camera info</Typography>
                </Box>
                <Divider />
                <Stack direction="row" spacing={3} sx={{ display: 'flex', my: 1 }}>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                        <Stack spacing={1}>
                            <TextInput error={errors['name'] || ""} label={"Camera Name"} placeholder={"Name"} name={"name"} onChange={onChange} value={formData?.name}/>
                            <TextInput error={errors['imei'] || ""} label={"IMEI"} placeholder={"IMEI"} name={"imei"} onChange={onChange} value={formData?.imei}/>
                            <TextInput error={errors['iccid'] || ""} label={"ICCID"} placeholder={"ICCID"} name={"iccid"} onChange={onChange} value={formData?.iccid}/>
                        </Stack>
                    </Stack>
                </Stack>
                <CardOverflow>
                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm" onClick={onCancel} variant="outlined" color="neutral">
                            Cancel
                        </Button>
                        <Button size="sm" type={"submit"} loading={isLoading} variant="solid">
                            Save
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </form>
    );
}

export default Info;