import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import {ChangeEvent, useState, useContext} from "react";
import {CameraModelType} from "../../../components/types";
import Select from "../../../components/form/Select";
import {AppContext} from "../../../components/app-context";
import ApiCamera from "../../../api/cameras";
import {handleAxiosError} from "../../../components/utils";

type ChangeModelModalProps = {
    open: boolean,
    onClose: any,
    successCallback: any,
    cameraId: number,
    models: CameraModelType[],
    initialModel: CameraModelType,
}

export const ChangeModelModal = ({open, onClose, successCallback, models, initialModel, cameraId}: ChangeModelModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [modelId, setModelId] = useState<number>(initialModel.id);

    const onSubmit = async () => {
        try {
            await ApiCamera.update(cameraId, {model_id: modelId});
            onNotification('Camera model successfully changed', 'success');
            successCallback();
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification
            );
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setModelId(parseInt(value));
    }

    const onModalClose = () => {
        setModelId(initialModel.id);
        onClose();
    }

    return (
        <Modal open={open} onClose={onModalClose}>
            <ModalDialog variant="outlined" role="alertdialog" minWidth={500}>
                <DialogTitle>
                    Change Camera Model
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Select
                        label="Model"
                        placeholder="Select a model"
                        name="model"
                        data={models.map(model => ({
                            value: model.id,
                            label: model.label,
                        }))}
                        onChange={onChange}
                        value={modelId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="solid"
                        color="primary"
                        onClick={onSubmit}
                        disabled={modelId === initialModel.id}
                    >
                        Change
                    </Button>
                    <Button variant="plain" color="neutral" onClick={onModalClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default ChangeModelModal;