import * as React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import DownloadIcon from "@mui/icons-material/DownloadRounded";
import IconButton from "@mui/joy/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../components/image";
import { emptyMedia } from "../types";
import CircularProgress from "@mui/joy/CircularProgress";
import { useGetOneAccountMedia, useGetOneMedia } from "../../query/media-query";

type Props = {
    mediaId: string;
    id: string;
    account?: boolean;
}

const MediaInfoSidebar = ({ id, account, mediaId }: Props) => {
    const navigate = useNavigate();
    const func = account ? useGetOneAccountMedia : useGetOneMedia;
    const { data, isLoading } = func(Number(id), Number(mediaId));
    const media = data?.current ?? emptyMedia;
    const navigation = useMemo(() => ({
        next: data?.next ?? null,
        prev: data?.prev ?? null
    }), [ data ]);

    const {
        name,
        file,
        size,
        dateTaken,
        videoLength,
        status,
        weather,
        original,
        createdAt,
        type,
    } = media;

    const isVideo = type === "Video";

    const onClose = useCallback(() => {
        const route = account ? `/account/${id}/media` : `/camera/${id}/media`;
        navigate(route);
    }, [ id, account, navigate ]);

    const onNavigatePrev = useCallback(() => {
        const route = account ? `/account/${id}/media/${navigation.prev}` : `/camera/${id}/media/${navigation.prev}`;
        navigate(route);
    }, [ id, navigate, account, navigation ]);

    const onNavigateNext = useCallback(() => {
        const route = account ? `/account/${id}/media/${navigation.next}` : `/camera/${id}/media/${navigation.next}`;
        navigate(route);
    }, [ id, navigate, account, navigation ]);

    const fields = [
        { value: name, label: "File Name" },
        { value: size, label: "Size" },
        { value: videoLength, label: "Length", hide: !isVideo },
        { value: status, label: "Status" },
        { value: dateTaken, label: "Date Taken" },
        { value: createdAt, label: "Created At" },
    ];

    const rightFields = [
        { value: weather.temp, label: "Temperature" },
        { value: weather.barometer, label: "Barometer" },
        {
            value: `${weather.wind?.direction ?? "-"}, ${weather.wind?.degree ?? "-"}, ${weather.wind?.speed ?? "-"}`,
            label: "Wind"
        },
        { value: weather.moonPhase.label, label: "Moon" },
    ]

    return (
        <Box sx={{
            position: "fixed",
            top: 0,
            transition: "left 0.2s ease",
            left: {
                xs: 0,
                md: "var(--Sidebar-width)"
            },
            display: "flex",
            flexDirection: "column",
            width: {
                xs: "100vw",
                md: "calc(100vw - var(--Sidebar-width))"
            },
            zIndex: 9996,
            height: "100vh",
            background: "#fff",
            borderLeft: `1px solid var(--joy-palette-divider)`
        }}>
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
                <Typography level="title-md" sx={{ flex: 1 }}>{name}</Typography>
                <IconButton component="span" variant="plain" color="neutral" size="sm" onClick={onClose}>
                    <CloseRoundedIcon/>
                </IconButton>
            </Box>
            <Box sx={{
                width: "100%",
                height: "50%",
                minHeight: "50%",
                overflow: "hidden",
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                }}>
                    {isVideo ? (
                        <video height="100%" controls>
                            <source src={original} type="video/mp4"/>
                        </video>
                    ) : (
                        <Image
                            alt={name}
                            withoutAspect
                            src={original}
                        />
                    )}
                </Box>
            </Box>
            <Box>
                <Box sx={{
                    display: "flex",

                }}>
                    <Box sx={{
                        p: 2,
                        width: "50%",
                    }}>
                        {fields.map((field, idx) => (
                            field.hide ? null : (
                                <Box key={idx} sx={{display: "flex", pb: 2}}>
                                    <Typography sx={{width: 100}} textColor="text.secondary" level="title-sm">{field.label}</Typography>
                                    <Typography level="body-sm" textColor="text.primary">
                                        {field.value}
                                    </Typography>
                                </Box>
                            )
                        ))}
                    </Box>
                    <Box sx={{
                        p: 2,
                        width: "50%",
                    }}>
                        {rightFields.map((field, idx) => (
                            <Box key={idx} sx={{display: "flex", pb: 2}}>
                                <Typography sx={{width: 100}} level="title-sm" textColor="text.secondary">{field.label}</Typography>
                                <Typography level="body-sm" textColor="text.primary">
                                    {field.value}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                }}>
                    <Box>
                        <a href={original} style={{ flex: 1 }} download={isVideo ? file : name}>
                            <Button
                                sx={{
                                    width: 250
                                }}
                                variant="outlined"
                                startDecorator={<DownloadIcon/>}
                            >
                                Download
                            </Button>
                        </a>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                flex: "1",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                width: "100%",
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Button
                        variant="plain"
                        sx={{
                            width: "49%"
                        }}
                        disabled={!navigation.prev}
                        onClick={onNavigatePrev}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="plain"
                        sx={{
                            width: "49%"
                        }}
                        disabled={!navigation.next}
                        onClick={onNavigateNext}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
            {isLoading
                ? (
                    <Box sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.1)",
                        backdropFilter: "blur(4px)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <CircularProgress/>
                    </Box>
                ) : null
            }
        </Box>
    );
};

export default MediaInfoSidebar;
