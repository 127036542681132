import PageHeader, { BreadCrumb } from "../page-header";
import { Box } from "@mui/material";
import TableSheet from "../table-sheet";
import { TableHover } from "../table";
import CircularProgress from "@mui/joy/CircularProgress";
import Pagination, { PaginationProps } from "../pagination";
import * as React from "react";
import { ReactElement, ReactNode } from "react";

type DataHeader = {
    width?: number;
    label: string;
}

type Props = {
    title: string;
    breadcrumbs: BreadCrumb[];
    headers: DataHeader[];
    loading?: boolean;
    empty?: boolean;
    placeholder?: string;
    children: ReactElement | ReactNode | ReactElement[] | ReactNode[];
    filters?: ReactElement | ReactNode | ReactElement[] | ReactNode[];
    actions?: ReactElement | ReactNode | ReactElement[] | ReactNode[];
    pagination: PaginationProps;
}

const ListPage = (props: Props) => {
    const { title, pagination, filters, actions, breadcrumbs, children, placeholder, empty, loading, headers } = props;
    return (
        <>
            <PageHeader title={title} breadCrumbs={breadcrumbs}>
                <>{actions}</>
            </PageHeader>
            <Box sx={{
                display: "flex",
                overflow: "auto",
                flexDirection: "column",
                gap: 1,
                px: { xs: 2, md: 6 },
            }}>
                {filters}
                <TableSheet>
                    <TableHover>
                        <thead>
                        <tr>
                            {headers.map((item, idx) => (
                                <th style={{ width: item.width }} key={idx}>{item.label}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        <>
                            {loading &&
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan={headers.length}><CircularProgress/></td>
                              </tr>
                            }
                            {!loading && !empty
                                ? children
                                : !loading && <tr>
                              <td colSpan={headers.length}
                                  style={{ textAlign: "center" }}>{placeholder ?? "No data"}</td>
                            </tr>
                            }
                        </>
                        </tbody>
                    </TableHover>
                </TableSheet>
                <Box sx={{ py: { md: 2 } }}>
                    <Pagination {...pagination}/>
                </Box>
            </Box>
        </>
    );
};

export default ListPage;
