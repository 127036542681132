import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import Option from "@mui/joy/Option";
import Divider from "@mui/joy/Divider";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import Select from "@mui/joy/Select";
import Textarea from "@mui/joy/Textarea";
import * as React from "react";
import Box from "@mui/joy/Box";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import accounts from "../../../api/accounts";
import { AppContext } from "../../../components/app-context";
import { useGetAccountCameras, useGetAccountNotes } from "../../../query/accounts-query";
import { AccountNote } from "../../../types/account";
import { handleAxiosError } from "../../../components/utils";

type Props = {
    open: boolean;
    accountId: number;
    onClose: () => void;
    note?: AccountNote;
    refetchCounters: () => void;
}


const NoteModal = ({ open, accountId, note, refetchCounters, onClose }: Props) => {
    const { onNotification } = useContext(AppContext);
    const { data: cameras } = useGetAccountCameras(accountId, 1, 100);
    const { refetch } = useGetAccountNotes(accountId);
    const [ loading, onChangeLoading ] = useState<boolean>(false);
    const [ data, onChangeData ] = useState({
        camera_id: note?.camera?.id ?? undefined,
        details: note?.details ?? ""
    });

    const handleChangeData = useCallback((field: Record<string, string | number | undefined>) => {
        onChangeData({
            ...data,
            ...field
        });
    }, [ data ]);

    const onSubmit = useCallback(async () => {
        try {
            onChangeLoading(true);
            if (note?.id) {
                await accounts.updateNote(accountId, note.id, {
                    note: {
                        camera_id: data.camera_id,
                        details: data.details
                    }
                })
            } else {
                await accounts.createNote(accountId, {
                    note: {
                        camera_id: data.camera_id,
                        details: data.details
                    }
                });
            }
            onChangeLoading(false);
            refetchCounters();
            onChangeData({
                camera_id: undefined,
                details: ""
            });
            refetch();
            onNotification(`Note was successfully ${note?.id ? "updated" : "added"}`);
            onClose();
        } catch (error: any) {
            onChangeLoading(false);
            handleAxiosError(error, onNotification);
        }
    }, [ accountId, note, refetch, refetchCounters, onNotification, data, onClose ]);

    const camerasOptions = useMemo(() => {
        return (cameras?.list ?? []).map((item) => ({
            value: item.id,
            label: item.accountCamera.name
        }));
    }, [ cameras ]);

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined">
                <DialogTitle>
                    {note ? "Update note" : "Add note"}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <Box sx={{ width: 500 }}>
                        <FormControl sx={{ mb: 1 }}>
                            <FormLabel>Select camera</FormLabel>
                            <Select
                                name="camera_id"
                                value={data.camera_id}
                                onChange={(event, value) => handleChangeData({
                                    camera_id: value ? Number(value) : undefined
                                })}
                                placeholder="Select camera or leave empty"
                            >
                                <Option value="">Not selected</Option>
                                {camerasOptions.map((item) => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Details</FormLabel>
                            <Textarea
                                value={data.details}
                                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeData({ details: event.target.value })}
                                minRows={4}
                                placeholder="Enter description..."
                                name="details"
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" disabled={!data.details} loading={loading} onClick={onSubmit}>
                        {note ? "Update" : "Add"}
                    </Button>
                    <Button variant="plain" color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

export default NoteModal;
