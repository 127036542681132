import * as React from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
import Stack from '@mui/joy/Stack';

export const ModalLoader = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{padding: "20px"}}
        >
            <CircularProgress
                sx={{
                    "--CircularProgress-size": "65px"
                }}
            />
        </Stack>
    );
}

export default ModalLoader;