import {
    useQuery,
} from 'react-query'
import { createQueryKeys, inferQueryKeys } from "@lukemorales/query-key-factory";
import firmwareApi from "../api/firmware";

const firmwareQK = createQueryKeys("firmware",{
    list: (params?: GetListParams) => [ params ?? {} ],
});

type GetListParams = {
    filters?: any;
    page: number;
    limit: number;
}

export type ExchangeQK = inferQueryKeys<typeof firmwareQK>;

export const useGetFirmwares = (params: GetListParams) => {
    return useQuery({
        queryKey: firmwareQK.list(params).queryKey,
        queryFn: () => firmwareApi
            .all(params.filters, params.page, params.limit)
            .then((response) => response.data),
    });
};
