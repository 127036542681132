import * as React from 'react';
import {Table} from "@mui/joy";

type TableProps = {
    children?: any
};

export const TableHover = ({children}: TableProps) => {
    return (
        <Table
            className={"dashboard-table"}
            hoverRow
            stickyHeader
            sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
            }}>
            {children}
        </Table>
    );
}

export const Th = ({children}: TableProps) => {
    return (
        <th style={{padding: '12px 6px' }}>{children}</th>
    );
}