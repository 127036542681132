import * as React from 'react';
import Stack from "@mui/joy/Stack";
import {useEffect, useState, useContext} from "react";
import {useOutletContext} from "react-router-dom";
import Typography from '@mui/joy/Typography';
import CircularProgress from "@mui/joy/CircularProgress";
import {TableHover} from "../../components/table";
import TableSheet from "../../components/table-sheet";
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";
import Box from '@mui/joy/Box';
import {useSearchParams} from "react-router-dom";
import {getNumberFromSearchParams} from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";

export function EquipmentReturn() {
    const {onNotification} = useContext(AppContext);
    const {account} = useOutletContext<{ account: any }>();
    const [isLoading, setIsLoading] = useState(true);
    const [equipmentReturns, setEquipmentReturns] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, 'page', 1);
    const limit = getNumberFromSearchParams(searchParams, 'limit', 10);
    const [totalPages, setTotalPages] = useState<number>(1);

    const handlePaginationChange = (page: number, perPage: number)  => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    }

    useEffect(() => {
        fetchEquipmentReturns(account.id, currentPage, limit);
    }, [searchParams]);

    const fetchEquipmentReturns = async (id: number, page: number, limit: number) => {
        try {
            setEquipmentReturns([]);
            setIsLoading(false);
            setTotalPages(1);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    return (
        <>
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{width: '100%'}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading && (<tr style={{textAlign: "center"}}><td colSpan={8}><CircularProgress /></td></tr>)}
                    {!isLoading && equipmentReturns.length === 0 && (
                        <tr>
                            <td colSpan={8} style={{textAlign: "center"}}>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{
                                        paddingTop: "20px",
                                        paddingBottom: "20px"
                                    }}
                                >
                                    <Typography level="body-md">Equipment returns not found</Typography>
                                </Stack>
                            </td>
                        </tr>

                    )}

                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
}