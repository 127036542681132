import {
    useQuery,
} from 'react-query'
import { createQueryKeys, inferQueryKeys } from "@lukemorales/query-key-factory";
import accountsApi from "../api/accounts";
import * as accountsCameraApi from "../api/account-cameras";
import accountsMediaApi from "../api/account-media";
import * as accountsSubscriptionApi from "../api/account-subscriptions";

const accountsQK = createQueryKeys("accounts",{
    list: (params?: GetListParams) => [ params ?? {} ],
    one: (id: number) => [ id ],
    oneNotes: (id: number) => [ id ],
    oneCounters: (id: number) => [ id ],
    oneCameras: (id: number, params: GetListParams) => [ { id, params } ?? {} ],
    oneMedia: (id: number, params: GetListParams) => [ { id, params } ?? {} ],
    oneSubscriptions: (id: number, params: GetListParams) => [ { id, params } ?? {} ],
    oneSubscriptionsHistory: (id: number, params: GetListParams) => [ { id, params } ?? {} ],
});

type GetListParams = {
    filters?: any;
    page: number;
    limit: number;
}

export type AccountsQK = inferQueryKeys<typeof accountsQK>;

export const useGetAccounts = (params: GetListParams) => {
    return useQuery({
        queryKey: accountsQK.list(params).queryKey,
        queryFn: () => accountsApi
            .all(params.filters, params.page, params.limit)
            .then((response) => response.data),
    });
};

export const useGetAccount = (id: number) => {
    return useQuery({
        queryKey: accountsQK.one(id).queryKey,
        queryFn: () => accountsApi
            .view(id)
            .then((response) => response.data),
    });
};

export const useGetAccountCounters = (id: number) => {
    return useQuery({
        queryKey: accountsQK.oneCounters(id).queryKey,
        queryFn: () => accountsApi
            .getCounters(id)
            .then((response) => response.data),
    });
};

export const useGetAccountNotes = (id: number) => {
    return useQuery({
        queryKey: accountsQK.oneNotes(id).queryKey,
        queryFn: () => accountsApi
            .getNotes(id)
            .then((response) => response.data),
    });
};

export const useGetAccountCameras = (id: number, page: number, limit: number) => {
    return useQuery({
        queryKey: accountsQK.oneCameras(id, { page, limit }).queryKey,
        queryFn: () => accountsCameraApi
            .cameras(id, page, limit)
            .then((response) => response.data),
    });
};


export const useGetAccountMedia = (id: number, page: number, limit: number) => {
    return useQuery({
        queryKey: accountsQK.oneMedia(id, { page, limit }).queryKey,
        queryFn: () => accountsMediaApi
            .all(id, page, limit)
            .then((response) => response.data),
    });
};


export const useGetAccountSubscriptions = (id: number, page: number, limit: number) => {
    return useQuery({
        queryKey: accountsQK.oneSubscriptions(id, { page, limit }).queryKey,
        queryFn: () => accountsSubscriptionApi
            .getSubstiptionsByAccountId(id, page, limit)
            .then((response) => response.data),
    });
};

export const useGetAccountSubscriptionsHistory = (id: number, page: number, limit: number) => {
    return useQuery({
        queryKey: accountsQK.oneSubscriptionsHistory(id, { page, limit }).queryKey,
        queryFn: () => accountsSubscriptionApi
            .getSubstiptionsHistoryByAccountId(id, page, limit)
            .then((response) => response.data),
    });
};
