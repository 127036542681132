import * as React from "react";
import Stack from "@mui/joy/Stack";
import { useOutletContext } from "react-router-dom";
import IconButton from "@mui/joy/IconButton";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircularProgress from "@mui/joy/CircularProgress";
import { formatDateTime } from "../../components/utils";
import { TableHover } from "../../components/table";
import TableSheet from "../../components/table-sheet";
import { useSearchParams } from "react-router-dom";
import { getNumberFromSearchParams } from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";
import { useGetAccountSubscriptions } from "../../query/accounts-query";
import { Account } from "../../types/account";
import { Subscription } from "../../types/subscription";

type RowProps = {
    subscription: Subscription
}

const Row = ({ subscription }: RowProps) => {
    const [ open, setOpen ] = React.useState(false);

    return (
        <React.Fragment>
            <tr>
                <td>
                    {subscription.cameras && subscription.cameras.length > 0 && (
                        <IconButton
                            aria-label="expand row"
                            variant="plain"
                            color="neutral"
                            size="sm"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    )}
                </td>
                <td>
                    <Typography level="body-xs" fontWeight="lg">{subscription.name}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                    <Typography level="body-xs">{formatDateTime(subscription.start_date)}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                    <Typography level="body-xs">{formatDateTime(subscription.end_date)}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                    <Typography level="body-xs">
                        {subscription.usage ? subscription.usage : 0}
                        {subscription.limit ? ` / ${subscription.limit}` : ""}
                    </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                    <Typography level="body-xs">
                        {subscription.usage_hd ? subscription.usage_hd : 0}
                        {subscription.limit_hd ? ` / ${subscription.limit_hd}` : ""}
                    </Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                    <Typography level="body-xs">{subscription.photos_count ? subscription.photos_count : 0}</Typography>
                </td>
                <td style={{ textAlign: "center" }}>
                    <Typography level="body-xs">{subscription.videos_count ? subscription.videos_count : 0}</Typography>
                </td>
            </tr>
            <tr>
                <td style={{ height: 0, padding: 0 }} colSpan={8}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{ p: 1, pl: 6, boxShadow: "inset 0 3px 6px 0 rgba(0 0 0 / 0.08)" }}
                        >
                            <Typography level="title-sm" component="div">
                                Cameras
                            </Typography>
                            <Table
                                borderAxis="bothBetween"
                                size="sm"
                                aria-label="purchases"
                                sx={{
                                    "& > thead > tr > th:nth-of-type(n + 3), & > tbody > tr > td:nth-of-type(n + 3)": { textAlign: "right" },
                                    "--TableCell-paddingX": "0.5rem",
                                }}
                            >
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{ textAlign: "center" }}>Usage</th>
                                    <th style={{ textAlign: "center" }}>Hd</th>
                                    <th style={{ textAlign: "center" }}>Photos Count</th>
                                    <th style={{ textAlign: "center" }}>Videos Count</th>
                                    <th style={{ textAlign: "center" }}>Date added</th>
                                </tr>
                                </thead>
                                <tbody>
                                {subscription.cameras.map((camera, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Typography level="body-xs" fontWeight="lg">{camera.name}</Typography>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <Typography level="body-xs">{camera.usage ? camera.usage : 0}</Typography>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <Typography
                                                level="body-xs">{camera.usage_hd ? camera.usage_hd : 0}</Typography>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <Typography
                                                level="body-xs">{camera.photos_count ? camera.photos_count : 0}</Typography>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <Typography
                                                level="body-xs">{camera.videos_count ? camera.videos_count : 0}</Typography>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <Typography level="body-xs">{camera.date_added ?? "-"}</Typography>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>
        </React.Fragment>
    );
};

export function Subscriptions() {
    const { account } = useOutletContext<{ account: Account }>();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, isLoading } = useGetAccountSubscriptions(account.id, currentPage, limit);
    const subscriptionsList = data?.list ?? [];
    const totalPages = data?.pagination?.totalPages ?? 1;

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    return (
        <>
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{ width: 40 }} aria-label="empty"/>
                        <th style={{ width: "15%" }}>Name</th>
                        <th style={{ textAlign: "center" }}>Date Start</th>
                        <th style={{ textAlign: "center" }}>Date End</th>
                        <th style={{ width: "15%", textAlign: "center" }}>Usage/Limit Usage</th>
                        <th style={{ textAlign: "center" }}>Hd/Limit Hd</th>
                        <th style={{ textAlign: "center" }}>Photos count</th>
                        <th style={{ textAlign: "center" }}>Videos Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading && (<tr style={{ textAlign: "center" }}>
                        <td colSpan={8}><CircularProgress/></td>
                    </tr>)}
                    {!isLoading && subscriptionsList.length > 0 && subscriptionsList.map((subscription, index) => (
                        <Row key={index} subscription={subscription}/>
                    ))}
                    {!isLoading && subscriptionsList.length === 0 && (
                        <tr>
                            <td colSpan={8} style={{ textAlign: "center" }}>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{
                                        paddingTop: "20px",
                                        paddingBottom: "20px"
                                    }}
                                >
                                    <Typography level="body-md">Subscriptions not found</Typography>
                                </Stack>
                            </td>
                        </tr>

                    )}

                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
}
