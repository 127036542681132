import moment from "moment";
import isDeepEqual from 'fast-deep-equal/react';
import {AxiosError} from "axios";
import {OnNotificationType} from "./types";

export const formatDateTime = (dateTime: string): string =>  {
    if (moment(dateTime).isValid()) return moment(dateTime, "YYYY-MM-DD hh:mm:ss").format('MM/DD/YYYY hh:mm');
    return "-";
}

export const errorsToObject = (errors: any[]): any => {
    const result = {};
    for (let i = 0; i < errors.length; i++) {
        const error = errors[i];
        // @ts-ignore
        result[error.field] =error.message;
    }

    return result;
}

export const fileSize = (size: number = 0): string => {

        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = 0;
        while (size >= 1024) {
            size /= 1024;
            ++i;
        }
        return `${size.toFixed(2)} ${units[i]}`;
}

export const formatLength = (length: number = 0): string => {
    let hours   = Math.floor(length / 3600);
    let minutes = Math.floor((length - (hours * 3600)) / 60);
    let seconds = length - (hours * 3600) - (minutes * 60);

    let hoursString = hours.toString();
    if (hours < 10) {
        hoursString = "0" + hoursString;
    }

    let minutesString = minutes.toString();
    if (minutes < 10) {
        minutesString = "0" + minutesString;
    }

    let secondsString = seconds.toString();
    if (seconds < 10) {
        secondsString = "0" + seconds.toString();
    }
    return hoursString + ':' + minutesString + ':' + secondsString;
}

export function isValidImei(imei: string): boolean {
    return imei.length === 15 && /^\d+$/.test(imei);
}

export function isValidIccid(iccid: string): boolean {
    return iccid.length >= 15 && iccid.length <= 24 && /^\d+$/.test(iccid);
}


export function isEqual(a: object, b: object): boolean {
    return isDeepEqual(a, b);
}

export function downloadUrl(url: string, filename: string) {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getNumberFromSearchParams(searchParams: URLSearchParams, name: string, byDefault: number): number {
    if (searchParams.has(name)) {
        const value = searchParams.get(name);
        if (value !== null) {
            return parseInt(value);
        }
    }
    return byDefault;
}

type ResponseData = {
    message?: string,
}

export function handleAxiosError (error: AxiosError, onNotification: OnNotificationType, handleValidationErrors?: (data: any) => void) {
    const { response } = error;
    if (response) {
        const { status, data } = response;
        if (status < 500) {
            if (Array.isArray(data) && handleValidationErrors) {
                handleValidationErrors(data);
                return;
            }

            const responseData = data as ResponseData;
            if (responseData.message) {
                onNotification(responseData.message, 'danger');
                return;
            }
        }
    }

    onNotification('Internal server error', 'danger');
}
