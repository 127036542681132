import {Navigate, useLocation} from "react-router-dom";
import {AppContext} from "./app-context";
import {useContext} from "react";

export default function PrivateRoute({ children }: { children: any }) {
    const { authenticated } = useContext(AppContext)
    let location = useLocation();
    if (!authenticated) {
        return <Navigate to={"/login"} state={{ from: location }} replace/>
    }

    return children;
}