import * as React from 'react';
import Stack from "@mui/joy/Stack";
import Info from "./components/info";
import Storage from "./components/storage";
import Model from "./components/model";
import Box from '@mui/joy/Box';


export const General = () => {

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Stack spacing={4} sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: { md: 6 },
            }}>
                <Info/>
                <Model/>
                <Storage/>
            </Stack>
        </Box>
    );
}