import * as React from "react";
import { ChangeEvent, useContext, useState } from "react";
import { Stack } from "@mui/joy";
import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import { errorsToObject } from "../../../components/utils";
import ModalWrapper from "../../../components/modal";
import TextInput from "../../../components/form/TextInput";
import Select from "../../../components/form/Select";
import ApiUsers from "../../../api/users";
import { AppContext } from "../../../components/app-context";
import { handleAxiosError } from "../../../components/utils";
import { useGetRoles } from "../../../query/users-query";

type ModalProps = {
    open: boolean,
    onClose: any,
    successCallback: any
}

type Form = {
    first_name?: string,
    last_name?: string,
    email?: string,
    phone?: string,
    password?: string,
    role?: string | null,
}

const formDefaults = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    role: null,
};

export const CreateUser = ({ open, onClose, successCallback }: ModalProps) => {

    const { onNotification } = useContext(AppContext);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ userReady, setUserReady ] = useState(false);
    const { data: rolesResponse } = useGetRoles();
    const roles = Object.values(rolesResponse ?? {});
    const [ formData, setFormData ] = useState<Form>(formDefaults);
    const [ errors, setErrors ] = useState<any>({});
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    };

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            await ApiUsers.create(formData);

            setFormData(formDefaults);
            onNotification("User successfully created");
            await successCallback();

        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    };

    return (
        <ModalWrapper open={open} onClose={onClose}>
            <DialogTitle>Create new user</DialogTitle>
            <DialogContent>Fill in the information about the user.</DialogContent>
            <>
                <Stack spacing={2}>
                    <TextInput label={"First Name"} readOnly={userReady} placeholder={"Enter First Name"}
                               name={"first_name"} onChange={onChange} value={formData.first_name}
                               error={errors["first_name"] || ""}/>
                    <TextInput label={"Last Name"} readOnly={userReady} placeholder={"Enter Last Name"}
                               name={"last_name"} onChange={onChange} value={formData.last_name}
                               error={errors["last_name"] || ""}/>
                    <TextInput label={"Email"} type={"email"} readOnly={userReady} placeholder={"Enter Email"}
                               name={"email"} onChange={onChange} value={formData.email}
                               error={errors["email"] || errors["username"] || ""}/>
                    <TextInput label={"Phone"} readOnly={userReady} placeholder={"Enter Phone"} name={"phone"}
                               onChange={onChange} value={formData.phone} error={errors["phone"] || ""}/>
                    <TextInput label={"Password"} readOnly={userReady} placeholder={"Enter Password"} name={"password"}
                               onChange={onChange} value={formData.password} error={errors["password"] || ""}/>
                    <Select
                        label={"Role"}
                        placeholder={"Select Role"}
                        name={"role"}
                        onChange={onChange}
                        value={formData.role}
                        error={errors["role"] || ""}
                        data={roles.map(role => {
                            return {
                                value: role.name,
                                label: role.name,
                            };
                        })}
                    />


                    <Button type="submit" onClick={onSubmit} loading={isLoading}>Create</Button>
                    <Button color={"neutral"} onClick={onClose}>Close</Button>
                </Stack>
            </>
        </ModalWrapper>
    );
};

export default CreateUser;
