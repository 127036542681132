import * as React from 'react';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {formatDateTime} from "../../../components/utils";
import JSONPretty from 'react-json-pretty';

import ModalWrapper from "../../../components/modal";

type ViewReportProps = {
    open: boolean,
    onClose: any,
    report: any,
    next?: number,
    prev?: number,
    reportChange: any
}

export const ViewReportModal = ({open, onClose, report, next, prev, reportChange}: ViewReportProps) => {

    const {decodedReport, created_at} = report;

    return (
        <ModalWrapper open={open} onClose={onClose}>
            <DialogTitle>
                Report for {formatDateTime(created_at)}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <JSONPretty
                    data={JSON.stringify(decodedReport, null, 2)}
                    style={{
                        overflow: 'auto',
                    }}
                    theme={{
                        main: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        error: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        key: 'color:brown;',
                        string: 'color:olive;',
                        value: 'color:navy;',
                        boolean: 'color:navy;',
                    }}
                ></JSONPretty>
            </DialogContent>
            <Divider />
            <DialogActions sx={{justifyContent: "space-between"}}>
                <Button variant="outlined" color="primary" disabled={prev === null} onClick={() => { reportChange(prev) }}>
                    Prev <ArrowRightIcon/>
                </Button>
                <Button variant="solid" color="neutral" onClick={onClose}>
                    Close
                </Button>
                <Button variant="outlined" color="primary" disabled={next === null} onClick={() => { reportChange(next) }}>
                    <ArrowLeftIcon/> Next
                </Button>
            </DialogActions>
        </ModalWrapper>
    );
}

export default ViewReportModal;