import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';

type ModalWrapperProps = {
    open: boolean,
    onClose: any
    children: any
}

export const ModalWrapper = ({open, onClose, children}: ModalWrapperProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog color="neutral" size="md" variant="outlined" sx={{minWidth: 560}}>
                {children}
            </ModalDialog>
        </Modal>
    );
}

export default ModalWrapper;