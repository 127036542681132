import * as React from 'react';
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {useState} from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/joy/IconButton";

type PasswordInputProps = {
    label: string,
    placeholder: string,
    name: string,
    error?: string,
    onChange: any,
    value: any,
    disabled?: boolean,
    readOnly?: boolean
};

export const PasswordInput = (props: PasswordInputProps) => {

    const [showPassword, setShowPassword] = useState(false);

    const onToggleShow = () => {
        setShowPassword(!showPassword);
    }

    const hasError = !!props.error;
    const type = showPassword ? "text" : "password";
    const readOnly = props.readOnly || false;

    return (
        <FormControl error={hasError}>
            <FormLabel>{props.label}</FormLabel>
            <Input
                disabled={props.disabled || false}
                readOnly={readOnly}
                type={type}
                placeholder={props.placeholder}
                name={props.name}
                endDecorator={<IconButton size={"sm"} onClick={onToggleShow}>{showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}</IconButton>}
                onChange={props.onChange}
                value={props.value} />
            {
                hasError &&
                    <FormHelperText>
                        <InfoOutlined />
                        {props.error}
                    </FormHelperText>
            }
        </FormControl>
    );
}

export default PasswordInput;