import { useState, useEffect } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import CircularProgress from "@mui/joy/CircularProgress";
import * as React from "react";

type Props = {
    src: string;
    withoutAspect?: boolean;
    alt?: string;
}

const ImageComponent = ({ src, withoutAspect, alt }: Props) => {
    const [ loaded, onChangeLoaded ] = useState<boolean>(false);

    useEffect(() => {
        if (!src) return;
        const image = new Image();
        image.src = src;
        image.onload = () => {
            onChangeLoaded(true);
        };
        image.onerror = () => {
            onChangeLoaded(false);
        }
    }, [ src ]);

    if (withoutAspect) {
        return (
            loaded
                ? <img src={src} style={{height: "100%"}} alt={alt}/>
                : <Box>
                    <CircularProgress/>
                </Box>
        )
    }

    return (
        loaded
            ? (
                <AspectRatio ratio="16/9" color="primary" sx={{ borderRadius: 0 }}>
                    <img src={src} alt={alt}/>
                </AspectRatio>
            )
            : <AspectRatio ratio="16/9" color="primary" sx={{ borderRadius: 0 }}/>
    );
};

export default ImageComponent;
