import client from './api-v1';
import { CameraMediaListResponse, FullMediaResponse } from "../types/media";

const all = async (accountId: number, page: number, limit: number):Promise<CameraMediaListResponse> => {
    return client.get(`/account-media/${accountId}`, {
        params: {
            page,
            limit,
        }
    });
}
const one = async (accountId: number, mediaId: number):Promise<FullMediaResponse> => {
    return client.get(`/account-media/${accountId}/${mediaId}`);
}

export default {
    one,
    all
}
