import * as React from 'react';
import {useOutletContext} from "react-router-dom";
import {IconButton} from "@mui/joy";
import {useEffect, useState, useContext} from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import OpenInNew from '@mui/icons-material/OpenInNew';
import {TableHover} from "../../components/table";
import TableSheet from "../../components/table-sheet";
import ApiCameras from "../../api/cameras";
import ViewReportModal from "./components/view-report-modal";
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";
import Box from '@mui/joy/Box';
import {useSearchParams} from "react-router-dom";
import {getNumberFromSearchParams} from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";

export const Reports = () => {

    const {onNotification} = useContext(AppContext);
    const {camera} = useOutletContext<{camera: any}>();
    const [reports, setReports] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showReport, setShowReport] = useState<any>({show: false, report: null, next: null, prev: null});
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, 'page', 1);
    const limit = getNumberFromSearchParams(searchParams, 'limit', 10);
    const [totalPages, setTotalPages] = useState<number>(1);

    const handlePaginationChange = (page: number, perPage: number)  => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    }

    useEffect(() => {
        fetchData(camera.id, currentPage, limit);
    }, [camera.id, searchParams]);

    const fetchData = async (id: number, page: number, limit: number) => {
        try {
            const {data} = await ApiCameras.findCameraReports(id, page, limit);
            setReports(data.list);
            setTotalPages(data.pagination.totalPages || 1);
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const onReportShowClick = (index: number) => {
        const nextIndex = index - 1;
        const prevIndex = index + 1;
        setShowReport({
            show: true,
            report: reports[index],
            next: nextIndex >= 0 ? nextIndex : null,
            prev: prevIndex <= reports.length - 1 ? prevIndex : null
        })
    }

    const onReportClose = () => {
        setShowReport({show: false, report: null, next: null, prev: null});
    }

    return (
        <>
            {
                showReport.show
                && <ViewReportModal
                    open={showReport.show}
                    onClose={onReportClose}
                    report={showReport.report}
                    next={showReport.next}
                    prev={showReport.prev}
                    reportChange={onReportShowClick}
                />
            }
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{width: 180}}>Created At</th>
                        <th style={{width: 150}}>Firmware Version</th>
                        <th style={{width: 100}}>Temperature</th>
                        <th style={{width: 100}}>Battery</th>
                        <th style={{width: 100}}>Power Source</th>
                        <th style={{width: 100}}>Voltage</th>
                        <th style={{width: 150}}>SD Card</th>
                        <th style={{width: 100}}>Signal</th>
                        <th style={{width: 80}} />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        isLoading
                            ? <tr style={{textAlign: "center"}}><td colSpan={9}><CircularProgress /></td></tr>
                            : reports.length > 0
                                ? reports.map((report: any, index) => {
                                    const {decodedReport, created_at} = report;

                                    if (!decodedReport) {
                                        return <tr key={`report-${index}`} style={{textAlign: "center"}}><td colSpan={19}>Empty report</td></tr>
                                    }

                                    return (
                                        <tr key={`report-${index}`}>
                                            <td>{created_at}</td>
                                            <td>{decodedReport?.FirmwareVersion || ""}</td>
                                            <td>{decodedReport?.Temperature || ""}</td>
                                            <td>{decodedReport?.Battery || ""}</td>
                                            <td>{decodedReport?.PowerSource || ""}</td>
                                            <td>{decodedReport?.Voltage || ""}</td>
                                            <td>{decodedReport?.SDCardFree || "N/A"} / {decodedReport?.SDCardSize || "N/A"}</td>
                                            <td>{decodedReport?.Signal || ""}</td>
                                            <td>
                                                <IconButton size={"sm"} onClick={() => {
                                                    onReportShowClick(index);
                                                }} color={"primary"} variant={"outlined"}>
                                                    <OpenInNew />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    );
                                })
                                : <tr style={{textAlign: "center"}}><td colSpan={9}>No reports found</td></tr>
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
}
