import * as React from 'react';
import {Box} from '@mui/joy';

type NoContentProps = {
    children?: any
};

const NoContent = ({children}: NoContentProps) => {

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignContent: "center", padding: "100px"}}>
            {children}
        </Box>
    );
}

export default NoContent;