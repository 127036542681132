import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import {Button, FormControl, FormLabel, Input} from "@mui/joy";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import AspectRatio from "@mui/joy/AspectRatio";
import Textarea from "@mui/joy/Textarea";
import {useEffect, useState} from "react";
import {models} from "../../../api/cameras";
import {useOutletContext} from "react-router-dom";
import {CameraModelType} from "../../../components/types";
import {ChangeModelModal} from "./change-model-modal";
import { useGetCameraModels } from "../../../query/cameras-query";

const Model = () => {

    const {camera, fetchCamera} = useOutletContext<{camera: any, fetchCamera: any}>();
    const { data } = useGetCameraModels();
    const cameraModels = data?.list ?? [];
    const [showModal, setShowModal] = useState<boolean>(false);

    const onChangeClick = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const modalSuccessCallback = () => {
        setShowModal(false);
        fetchCamera();
    }

    const currentModel: CameraModelType | undefined = cameraModels.find((model: CameraModelType) => model?.id === camera?.model.id);

    return (
        <>
            {currentModel && (
                <ChangeModelModal
                    open={showModal}
                    onClose={closeModal}
                    successCallback={modalSuccessCallback}
                    models={cameraModels}
                    initialModel={currentModel}
                    cameraId={camera.id}
                />
            )}
            <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">Camera Model</Typography>
                </Box>
                <Divider />
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ display: 'flex', my: 1 }}
                >
                    <Stack direction="column" spacing={1}>
                        <AspectRatio
                            ratio="1"
                            maxHeight={200}
                            sx={{ flex: 1, minWidth: 120 }}
                        >
                            <img
                                src="https://exodusoutdoorgear.com/cdn/shop/products/EOGTC006MASTER_cf48e990-34f1-4fca-b67c-ffc56dc99c2f_720x.png?v=1673366578"
                                srcSet="https://exodusoutdoorgear.com/cdn/shop/products/EOGTC006MASTER_cf48e990-34f1-4fca-b67c-ffc56dc99c2f_720x.png?v=1673366578"
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </Stack>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                        <Stack spacing={1}>
                            <FormLabel>Name</FormLabel>
                            <FormControl>
                                <Input size="sm" placeholder="Name" sx={{ flexGrow: 1 }} readOnly value={currentModel?.name || ""} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Code</FormLabel>
                                <Input size="sm" placeholder="Camera Name" readOnly value={currentModel?.code || ""} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea minRows={2} readOnly value={currentModel?.description || ""} />
                            </FormControl>
                        </Stack>
                    </Stack>
                </Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm" variant="solid" onClick={onChangeClick}>
                            Change
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
        </>
    );
}

export default Model;
