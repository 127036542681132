import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useContext, useState } from "react";
import { AppContext } from "../../../components/app-context";
import { handleAxiosError } from "../../../components/utils";
import accounts from "../../../api/accounts";

type ModalWrapperProps = {
    open: boolean,
    onClose: () => void;
    accountId: number;
    noteId: number;
    onSuccessCallback: () => void;
}

export const DeleteNote = ({ open, accountId, noteId, onClose, onSuccessCallback }: ModalWrapperProps) => {

    const { onNotification } = useContext(AppContext);
    const [ isLoading, setIsLoading ] = useState(false);

    const onConfirmClick = async () => {
        try {
            setIsLoading(true);
            await accounts.deleteNote(accountId, noteId);
            onSuccessCallback();
            setIsLoading(false);
            onClose();
            onNotification("Note was successfully deleted");
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon/>
                    Confirm camera note
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <p>Are you sure that you want <strong>delete</strong> note? This action cannot be undone!</p>
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="danger" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

export default DeleteNote;
