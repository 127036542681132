import client from './api';

const help = async () => {
    return client.get(`camera/commands/help`);
}

const commands = async (id: number, page: number, limit: number) => {
    return client.get(`camera/${id}/commands`, {
        params: {
            page,
            limit
        }
    });
}

const trigger = async (id: number, command: string, data?: any) => {
    return client.post(`camera/${id}/commands/${command}`, data);
}

const cancel = async (id: number, commandId: number) => {
    return client.delete(`camera/${id}/commands/${commandId}`);
}

export default {
    help,
    commands,
    trigger,
    cancel
}