import {
    useQuery,
} from 'react-query'
import { createQueryKeys, inferQueryKeys } from "@lukemorales/query-key-factory";
import { camerasList, models } from "../api/cameras";

const camerasQK = createQueryKeys("cameras",{
    list: (params?: GetListParams) => [ params ?? {} ],
    models: null,
});

type GetListParams = {
    filters?: any;
    page: number;
    limit: number;
}

export type CamerasQK = inferQueryKeys<typeof camerasQK>;

export const useGetCamerasList = (params: GetListParams) => {
    return useQuery({
        queryKey: camerasQK.list(params).queryKey,
        queryFn: () => camerasList(params.filters, params.page, params.limit)
            .then((response) => response.data),
    });
};

export const useGetCameraModels = () => {
    return useQuery({
        queryKey: camerasQK.models.queryKey,
        queryFn: () => models()
            .then((response) => response.data),
    });
};
