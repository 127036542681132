import * as React from 'react';
import ModalWrapper from "../../../components/modal";
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogActions from '@mui/joy/DialogActions';
import Button from '@mui/joy/Button';
import JSONPretty from 'react-json-pretty';

type ViewJson = {
    open: boolean,
    json: string,
    onClose: () => {}
}

export const ViewJson = ({open, onClose, json}: ViewJson) => {
    return (
        <ModalWrapper open={open} onClose={onClose}>
            <ModalClose variant="plain" sx={{ m: 1 }} onClick={onClose} />
            <DialogTitle>View JSON</DialogTitle>
            <Divider />
            <DialogContent>
                <JSONPretty
                    data={json}
                    style={{
                        overflow: 'auto',
                    }}
                    theme={{
                        main: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        error: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        key: 'color:brown;',
                        string: 'color:olive;',
                        value: 'color:navy;',
                        boolean: 'color:navy;',
                    }}
                ></JSONPretty>
            </DialogContent>
            <Divider />
            <DialogActions sx={{justifyContent: "space-around"}}>
                <Button variant="solid" color="neutral" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </ModalWrapper>
    );
}

export default ViewJson;