import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListPage from "../../components/listPage";
import { ButtonGroup, Button, IconButton, Chip, ColorPaletteProp, Box, Typography, Avatar } from "@mui/joy";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useSearchParams } from "react-router-dom";
import CreateAccount from "./components/create";
import ExportAccountsModal from "./components/export";
import DeleteAccount from "./components/delete";
import Filters from "./components/filters";
import { getNumberFromSearchParams } from "../../components/utils";
import qs from "qs";
import { useGetAccounts } from "../../query/accounts-query";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function chipStatusColor(status: string): string {

    if (status.toLowerCase() === "deleted") {
        return "danger";
    }

    if (status.toLowerCase() === "inactive") {
        return "warning";
    }

    return "success";
}

export const Accounts = () => {
    const navigate = useNavigate();
    const [ showCreateAccount, setShowCreateAccount ] = useState(false);
    const [ deleteAccount, setDeleteAccount ] = useState<any>({
        show: false,
        account: null
    });
    const [ show, onChangeShow ] = useState<boolean>(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const filters = qs.parse(searchParams.toString()).filters || {};
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, refetch, isLoading } = useGetAccounts({
        filters,
        page: currentPage,
        limit
    });

    const accounts = data?.list ?? [];
    const totalPages = data?.pagination?.totalPages ?? 1;

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                filters: filters,
                limit: perPage,
                page,
            })
        );
    };

    const onDeleteAccountClick = useCallback((account: any) => {
        setDeleteAccount({
            show: true,
            account: account
        });
    }, []);

    const deleteAccountSuccessCallBack = useCallback(async () => {
        setDeleteAccount({
            show: false,
            account: null
        });
        await refetch();
    }, [ refetch ]);

    const deleteAccountFailureCallBack = useCallback(() => {
        setDeleteAccount({
            show: false,
            account: null
        });
    }, []);

    const onDeleteAccountClose = useCallback(() => {
        setDeleteAccount({
            show: false,
            account: null
        });
    }, []);

    const onCreateAccountClose = useCallback(async (created: boolean) => {
        setShowCreateAccount(false);
        if (created) {
            await refetch;
        }
    }, [ refetch ]);

    const applyFilters = useCallback((filters: any) => {
        setSearchParams(
            qs.stringify({
                filters: filters,
                page: 1,
                limit: limit
            })
        );
    }, [ setSearchParams, limit ]);

    const headers = [
        { width: 250, label: "Name" },
        { width: 150, label: "Phone" },
        { width: 100, label: "Cameras count" },
        { width: 100, label: "Registration" },
        { width: 100, label: "Status" },
        { width: 150, label: "Created At" },
        { width: 80, label: "" },
    ];

    const pagination = {
        count: totalPages,
        page: currentPage,
        onChange: handlePaginationChange,
        limit: limit
    };

    const search = useMemo(() => {
        //@ts-ignore
        if (filters?.search && filters?.search !== "") return filters.search;
        return "";
    }, [ filters ]);

    const getTextWithHighlights = useCallback((text: string) => {
        if (text.toLowerCase().includes(search.toLowerCase())) {
            const reg = new RegExp("(" + search + ")", "gi");
            const includedText = text.replaceAll(reg, value => `<span style="background: #0B6BCB; color: #fff">${value}</span>`);
            return <span dangerouslySetInnerHTML={{ __html: includedText }}/>;
        }
        return text;
    }, [ search ]);

    return (
        <>
            <ListPage
                title="Manage Accounts"
                pagination={pagination}
                headers={headers}
                breadcrumbs={[ { name: "Accounts" } ]}
                loading={isLoading}
                empty={accounts.length < 1}
                placeholder="Accounts not found"
                actions={(
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button size="sm" color="neutral" onClick={() => onChangeShow(true)}>
                            <FileDownloadIcon/>
                            Export
                        </Button>
                        <Button sx={{ ml: 1 }} color="primary" size="sm" onClick={() => {
                            setShowCreateAccount(true);
                        }}>
                            <PersonAddRoundedIcon/>
                            Create
                        </Button>
                    </Box>
                )}
                filters={(
                    <Box>
                        <Chip
                            color="primary"
                            variant="solid"
                            className="chip-total"
                        >
                            Total: {data?.pagination?.total ?? 0}
                        </Chip>
                        <Filters filters={filters} applyFilters={applyFilters}/>
                    </Box>
                )}
            >
                <>
                    {accounts.map((account) => {
                        const chipColor = chipStatusColor(account.status) as ColorPaletteProp;
                        const initials = (account?.first_name.substring(0, 1) + account?.last_name.substring(0, 1)).toUpperCase();
                        return (
                            <tr key={account.id} onClick={() => navigate(`/account/${account.id}`)}
                                className={account.status.toLowerCase() === "deleted" ? "danger" : ""}
                                style={{ cursor: "pointer" }}
                            >
                                <td>
                                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                        <Avatar size="sm">{initials}</Avatar>
                                        <Box sx={{
                                            overflow: "hidden"
                                        }}>
                                            <Typography
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden"
                                                }}
                                                level="body-xs"
                                                title={`${account?.first_name} ${account?.last_name}`}
                                            >
                                                {getTextWithHighlights(`${account?.first_name} ${account?.last_name}`)}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden"
                                                }}
                                                level="body-xs"
                                                title={account?.email}
                                            >
                                                {getTextWithHighlights(`${account?.email ?? "-"}`)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </td>
                                <td>
                                    <Typography
                                        level="body-xs">{getTextWithHighlights(`${account?.phone ?? "-"}`)}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">{account.totalCameras ?? "0"}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-xs">{account?.registration_source}</Typography>
                                </td>
                                <td>
                                    <Chip color={chipColor} size={"sm"}>{account.status}</Chip>
                                </td>
                                <td>
                                    <Typography level="body-xs">{account?.created_at}</Typography>
                                </td>
                                <td
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <ButtonGroup
                                        color="neutral"
                                        size="sm"
                                        variant="outlined"
                                    >
                                        <IconButton variant={"outlined"} color={"primary"}
                                                    to={`/account/${account.id}`}
                                                    component={Link}><EditRoundedIcon
                                            fontSize={"small"}/></IconButton>
                                        <IconButton variant={"outlined"} color={"danger"} size={"sm"}
                                                    onClick={() => {
                                                        onDeleteAccountClick(account);
                                                    }}><DeleteIcon/></IconButton>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        );
                    })}
                </>
            </ListPage>
            <DeleteAccount
                open={deleteAccount.show}
                onClose={onDeleteAccountClose}
                account={deleteAccount.account}
                onSuccessCallback={deleteAccountSuccessCallBack}
                onFailureCallback={deleteAccountFailureCallBack}
            />
            <CreateAccount open={showCreateAccount} onClose={onCreateAccountClose}/>
            <ExportAccountsModal filters={filters} open={show} onClose={() => onChangeShow(false)}/>
        </>
    );
};
