import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import {FormControl, FormLabel, Input} from "@mui/joy";
import {useOutletContext} from "react-router-dom";
import NoContent from "../../components/no-content";

const Report = () => {

    const {camera} = useOutletContext<{camera: any}>();

    const {report} = camera || {};

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Stack spacing={4} sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
            }}>
                {report && <DrawReport report={report} camera={camera}/>}
                {!report && <NoContent><Typography>No Latest Report</Typography></NoContent>}
            </Stack>
        </Box>
    );
}

export default Report;

type DrawReportProps = {
    report: any,
    camera: any
}

const DrawReport = ({report, camera}: DrawReportProps) => {

    const {report_date, ui} = report;

    return (
        <Card>
            <Box sx={{ mb: 1 }}>
                <Typography level="title-md">Camera report</Typography>
                <Typography level="body-sm">
                    Latest report on: {report_date || "never"}
                </Typography>
            </Box>
            <Divider />
            <Stack
                direction="row"
                spacing={3}
                sx={{ display: 'flex', my: 1 }}
            >
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <Stack spacing={1}>
                        {ui.map((item: any) => {

                            const {name, value} = item;

                            return (
                                <FormControl key={`${camera?.id}-${name}`}>
                                    <FormLabel>{name}</FormLabel>
                                    <Input size="sm" placeholder="Camera Name" readOnly value={value} />
                                </FormControl>
                            );
                        })}

                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}