import * as React from 'react';
import {ChangeEvent, useEffect, useState} from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import {models} from "../../../api/cameras";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/joy/Button";
import { useGetCameraModels } from "../../../query/cameras-query";

type FiltersProps = {
    filters: any,
    applyFilters: any
}

const defaultFilters = {
    search: "",
    status: null,
    model_id: null
}

export const Filters = ({filters, applyFilters}: FiltersProps) => {
    const [filterData, setFilterData] = useState<any>({...defaultFilters, ...filters});
    const { data } = useGetCameraModels();
    const modelsList = data?.list ?? [];

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFilterData({
            ...filterData,
            [name]: value
        });
    }

    const onSelectChange = (name: string, value: number) => {
        setFilterData({
            ...filterData,
            [name]: value
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const o = Object.fromEntries(Object.entries(filterData).filter(([_, v]) => (v != null && v != "")));
        applyFilters(o);
    }

    const onReset = async (event: any) => {
        setFilterData({});
        applyFilters({});
    }

    return (
        <form onSubmit={onSubmit}>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: 'sm',
                    py: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: '160px',
                    },
                }}
            >

                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search</FormLabel>
                    <Input size="sm" placeholder="Search by IMEI, ICCID, Name.." name={"search"} onChange={onChange} value={filterData.search} startDecorator={<SearchIcon />} />
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>SIM</FormLabel>
                    <Select
                        size="sm"
                        placeholder="Filter by sim"
                        name={"sim_status"}
                        value={filterData.sim_status}
                        onChange={(event: any, value: any) => {
                            onSelectChange('sim_status', value);
                        }}
                        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    >
                        <Option value="">All</Option>
                        <Option value="active">Active</Option>
                        <Option value="disabled">Not active</Option>
                        <Option value="not_found">Not found</Option>
                        <Option value="failed">Failed</Option>
                    </Select>
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Paused</FormLabel>
                    <Select
                        size="sm"
                        placeholder="Filter by paused"
                        name={"is_paused"}
                        value={filterData.is_paused || ""}
                        onChange={(event: any, value: any) => {
                            onSelectChange('is_paused', value);
                        }}
                        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    >
                        <Option value="">All</Option>
                        <Option value="1">Yes</Option>
                        <Option value="0">No</Option>
                    </Select>
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Status</FormLabel>
                    <Select
                        size="sm"
                        placeholder="Filter by status"
                        defaultValue={""}
                        name={"status"}
                        value={filterData.status}
                        onChange={(event: any, value: any) => {
                            onSelectChange('status', value);
                        }}
                        slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    >
                        <Option value="">All</Option>
                        <Option value="active">Active</Option>
                        <Option value="inactive">Not Active</Option>
                        <Option value="stolen">Stolen</Option>
                        <Option value="returned">Returned</Option>
                        <Option value="deleted">Deleted</Option>
                    </Select>
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Model</FormLabel>
                    <Select
                        size="sm"
                        name={"model_id"}
                        onChange={(event: any, value: any) => {
                            onSelectChange('model_id', value);
                        }}
                        value={filterData.model_id}
                        defaultValue={""}
                        placeholder="All"
                    >
                        <Option value="">All</Option>
                        {modelsList.map(model =>
                            <Option key={model.id} value={model.id}>{model.label}</Option>
                        )}
                    </Select>
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>&nbsp;</FormLabel>
                    <Button type={"submit"} size={"sm"}>Apply</Button>
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>&nbsp;</FormLabel>
                    <Button type={"button"} onClick={onReset} size={"sm"} color={"neutral"}>Reset</Button>
                </FormControl>
            </Box>
        </form>
    );
}

export default Filters;
