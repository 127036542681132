import client from "./api";
import { ExchangeHistoryListResponse } from "../types/exchange";

export const getGlobalExchangeHistory = async (page: number, limit: number): Promise<ExchangeHistoryListResponse> => {
    return client.get(`/equipment/exchange/history`, {
        params: {
            page,
            limit
        }
    });
};
