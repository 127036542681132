import * as React from 'react';
import {ChangeEvent, useState, useContext} from 'react';
import {Stack} from '@mui/joy';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import {errorsToObject} from "../../../components/utils";
import {useNavigate} from "react-router-dom";
import ApiAccounts from "../../../api/accounts";
import ModalWrapper from "../../../components/modal";
import TextInput from "../../../components/form/TextInput";
import {AppContext} from "../../../components/app-context";
import {handleAxiosError} from "../../../components/utils";

type ModalProps = {
    open: boolean,
    onClose: any
}

type Form = {
    first_name?: string,
    last_name?: string,
    email?: string,
    phone?: string,
    password?: string
}

const formDefaults = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: ""
};

export const CreateAccount = ({open, onClose}: ModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [accountReady, setAccountReady] = useState(false);
    const [account, setAccount] = useState<any>({});
    const [formData, setFormData] = useState<Form>(formDefaults);
    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onProceedToAccount = () => {
        return navigate(`/account/${account.id}`);
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const {data} = await ApiAccounts.create(formData);

            if (data.password) {
                setAccount(data);
                setAccountReady(true);
            } else {
                return navigate(`/account/${data.id}`);
            }

        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    }

    const onCloseModal = () => {
        onClose(accountReady);
    }

    return (
        <ModalWrapper open={open} onClose={onCloseModal}>
            <DialogTitle>Create new account</DialogTitle>
            <DialogContent>Fill in the information about the account.</DialogContent>
            <form onSubmit={onSubmit}>
                <Stack spacing={2}>
                    <TextInput label={"First Name"} disabled={accountReady} placeholder={"Enter First Name"} name={"first_name"} onChange={onChange} value={formData.first_name} error={errors['first_name'] || ""}/>
                    <TextInput label={"Last Name"} disabled={accountReady} placeholder={"Enter Last Name"} name={"last_name"} onChange={onChange} value={formData.last_name} error={errors['last_name'] || ""}/>
                    <TextInput label={"Email"} disabled={accountReady} placeholder={"Enter Email"} name={"email"} onChange={onChange} value={formData.email} error={errors['email'] || ""}/>
                    <TextInput label={"Phone"} disabled={accountReady} placeholder={"Enter Phone"} name={"phone"} onChange={onChange} value={formData.phone} error={errors['phone'] || ""}/>

                    {
                        accountReady
                            ? <FormControl>
                                <FormLabel>Password (autogenerated)</FormLabel>
                                <Input readOnly={true} value={account.password} />
                            </FormControl>
                            : <TextInput label={"Password"} disabled={accountReady} placeholder={"Enter Password"} name={"password"} onChange={onChange} value={formData.password} error={errors['password'] || ""}/>
                    }

                    {accountReady
                        ? <Button type="submit" loading={isLoading} onClick={onProceedToAccount}>Proceed to account</Button>
                        : <Button type="submit" loading={isLoading}>Create</Button>
                    }
                    <Button color={"neutral"} onClick={onCloseModal}>Close</Button>
                </Stack>
            </form>
        </ModalWrapper>
    );
}

export default CreateAccount;