import * as React from 'react';
import ModalWrapper from "../../../components/modal";
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogActions from '@mui/joy/DialogActions';
import Button from '@mui/joy/Button';
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import JSONPretty from 'react-json-pretty';
import {formatDateTime} from "../../../components/utils";
import {EventLogType} from "../../../components/types";

type ViewEventLogDataProps = {
    open: boolean,
    onClose: () => void,
    onNext: (() => void) | null,
    onPrev: (() => void) | null,
    eventLog: EventLogType,
}

export const ViewEventLogDataModal = ({open, onClose, onNext, onPrev, eventLog}: ViewEventLogDataProps) => {
    return (
        <ModalWrapper open={open} onClose={onClose}>
            <ModalClose variant="plain" sx={{ m: 1 }} onClick={onClose} />
            <DialogTitle>Event Log Data - {formatDateTime(eventLog.created_at)}</DialogTitle>
            <Divider />
            <DialogContent>
                <JSONPretty
                    data={eventLog.event_data}
                    style={{
                        overflow: 'auto',
                    }}
                    theme={{
                        main: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        error: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        key: 'color:brown;',
                        string: 'color:olive;',
                        value: 'color:navy;',
                        boolean: 'color:navy;',
                    }}
                ></JSONPretty>
            </DialogContent>
            <Divider />
            <DialogActions sx={{justifyContent: "space-between"}}>
                <Button variant="outlined" color="primary" disabled={onNext === null} onClick={() => onNext && onNext()}>
                    Next <ArrowRightIcon/>
                </Button>
                <Button variant="solid" color="neutral" onClick={onClose}>
                    Close
                </Button>
                <Button variant="outlined" color="primary" disabled={onPrev === null} onClick={() => onPrev && onPrev()}>
                    <ArrowLeftIcon/> Prev
                </Button>
            </DialogActions>
        </ModalWrapper>
    );
}

export default ViewEventLogDataModal;