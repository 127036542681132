import * as React from 'react';
import {useOutletContext} from "react-router-dom";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import {useEffect, useState, useContext} from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import TableSheet from "../../components/table-sheet";
import {TableHover} from "../../components/table";
import CameraApi from "../../api/cameras";
import {Button} from '@mui/joy';
import ActivateSimModal from "./components/activate-sim-modal";
import {IconButton} from "@mui/joy";
import OpenInNew from '@mui/icons-material/OpenInNew';
import {SimActivationType} from "../../components/types";
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";
import SimActivationDetailsModal from "./components/sim-activation-details-modal";
import Box from '@mui/joy/Box';
import {useSearchParams} from "react-router-dom";
import {getNumberFromSearchParams} from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";
import Divider from "@mui/joy/Divider";
import TextInput from "../../components/form/TextInput";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Card from "@mui/joy/Card";
import Alert from "@mui/joy/Alert";
import InfoIcon from '@mui/icons-material/InfoRounded';
import CodeIcon from '@mui/icons-material/CodeRounded';
import PauseDeviceModal from "./components/pause-device-modal";
import ViewJson from './components/view-json'

type ShowActivationDetailsType = {
    show: boolean,
    activation: SimActivationType | null,
}

export const Sim = () => {

    const {camera} = useOutletContext<{camera: any}>();
    const {onNotification} = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(true);
    const [activationsList, setActivationsList] = useState<SimActivationType[]>([]);
    const [showActivateSimCard, setShowActivateSimCard] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, 'page', 1);
    const limit = getNumberFromSearchParams(searchParams, 'limit', 10);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [info, setHologramInfo] = useState<any>({});

    const [showViewJson, setShowViewJson] = useState({
        show: false,
        json: ''
    })

    const [showPauseDevice, setShowPauseDevice] = useState(false);

    const handlePaginationChange = (page: number, perPage: number)  => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    }

    const [showActivationDetails, setShowActivationDetails] = useState<ShowActivationDetailsType>({
        show: false,
        activation: null,
    });

    useEffect(() => {
        fetchData(camera.id, currentPage, limit);
    }, [camera.id, searchParams]);

    const fetchData = async(id: number, page: number, limit: number) => {
        try {
            const {data} = await CameraApi.simActivationsHistory(id, page, limit);
            const {data: hologramInfo} = await CameraApi.hologramInfo(id);
            setHologramInfo(hologramInfo)
            setActivationsList(data.list);
            setTotalPages(data.pagination.totalPages);
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const successActivate = () => {
        setShowActivateSimCard(false);
        fetchData(camera.id, currentPage, limit);
    }

    const onShowActivationDetailsClick = (activation: SimActivationType) => {
        setShowActivationDetails({show: true, activation});
    }

    const onActivationDetailsClose = () => {
        setShowActivationDetails({show: false, activation: null});
    }

    return (
        <>
            {
                showActivationDetails.show && showActivationDetails.activation !== null
                && (
                    <SimActivationDetailsModal
                        open={showActivationDetails.show}
                        onClose={onActivationDetailsClose}
                        activation={showActivationDetails.activation}
                    />
                )
            }
            <ViewJson open={showViewJson.show} json={showViewJson.json} onClose={() => {setShowViewJson({show: false, json: ''})}}/>
            <PauseDeviceModal open={showPauseDevice} onClose={() => { setShowPauseDevice(false) }} successCallback={() => {window.location.reload()}} camera={camera}/>
            <ActivateSimModal
                open={showActivateSimCard}
                onClose={() => { setShowActivateSimCard(false) }}
                camera={camera}
                successCallback={successActivate}/>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" style={{gap: '10px'}}>
                {camera.is_paused === 0 &&
                    <Button color="danger" size="sm" onClick={() => { setShowPauseDevice(true) }}>
                        Pause Device
                    </Button>
                }
                {camera.is_paused === 1 &&
                    <Button color="warning" size="sm" onClick={() => { setShowPauseDevice(true) }}>
                        UnPause Device
                    </Button>
                }
                <Button color="success" size="sm" onClick={() => { setShowActivateSimCard(true) }}>
                    ReActivate SIM
                </Button>
            </Stack>
            {!isLoading &&
                <Stack direction={"row"} justifyContent="space-between" sx={{gap: "10px"}}>
                    <LinkInfo link={info?.link} setShowViewJson={setShowViewJson}/>
                    <DeviceInfo device={info?.device} setShowViewJson={setShowViewJson}/>
                </Stack>
            }

            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{width: 250}}>ICCID</th>
                        <th style={{width: 200}}>Status</th>
                        <th style={{width: 200}}>Activated At</th>
                        <th style={{width: 80}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        isLoading && <tr style={{textAlign: "center"}}><td colSpan={3}><CircularProgress /></td></tr>
                    }
                    {!isLoading && activationsList.length > 0
                        ? activationsList.map((activation) => {
                            return (
                                <tr key={activation.id}>
                                    <td>
                                        <Typography level="body-xs">{activation?.iccid}</Typography>
                                    </td>
                                    <td>
                                        <Typography level="body-xs">{activation?.status}</Typography>
                                    </td>
                                    <td>
                                        <Typography level="body-xs">{activation?.created_at}</Typography>
                                    </td>
                                    <td>
                                        <IconButton
                                            size={"sm"}
                                            onClick={() => onShowActivationDetailsClick(activation)}
                                            color={"primary"}
                                            variant={"outlined"}
                                        >
                                            <OpenInNew/>
                                        </IconButton>
                                    </td>
                                </tr>
                            )
                        })
                        : !isLoading && <tr>
                        <td colSpan={3} style={{textAlign: "center"}}>Sim card activation log is empty</td>
                    </tr>
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
}

const LinkInfo = ({link, setShowViewJson}: any) => {

    if (!link) {
        return (
            <Card sx={{display: 'flex', flexGrow: 1}}>
                <Box>
                    <Typography level="title-md">Device info</Typography>
                </Box>
                <Divider />
                <Alert variant={"soft"} color={"primary"}>
                    <InfoIcon/> No information about link was pulled
                </Alert>
            </Card>
        )
    }

    const {parsed} = link || {};

    return (
        <Card sx={{display: 'flex', flexGrow: 1}}>
            <Box>
                <Typography level="title-md">Link info</Typography>
            </Box>
            <Divider />
            <Stack spacing={2}>
                <Alert variant={"soft"} color={"primary"}>
                    <InfoIcon/> Information about sim card might be not full, <br/>it depends on place where it was taken
                </Alert>
                <Stack spacing={1}>
                    <TextInput label={"Link ID"} placeholder={"Link ID"} readOnly={true} onChange={() => {}} name={"link_id"} value={link?.link_id}/>
                    <TextInput label={"Apn"} placeholder={"Apn"} readOnly={true} onChange={() => {}} name={"apn"} value={parsed?.apn || "N/A"}/>
                    <TextInput label={"State"} placeholder={"State"} readOnly={true} onChange={() => {}} name={"state"} value={parsed?.state || "N/A"}/>
                    <TextInput label={"Profile State"} placeholder={"Profile State"} readOnly={true} onChange={() => {}} name={"profile_State"} value={parsed?.profile_state || "N/A"}/>
                    <TextInput label={"Sim"} placeholder={"Sim"} readOnly={true} onChange={() => {}} name={"sim"} value={parsed?.sim || ""}/>
                </Stack>
            </Stack>
            <CardOverflow>
                <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                    <Button size="sm" type={"button"} variant="solid" onClick={() => {
                        setShowViewJson({show: true, json: link.json})
                    }}>
                        <CodeIcon/> JSON
                    </Button>
                </CardActions>
            </CardOverflow>
        </Card>
    );
}

const DeviceInfo = ({device, setShowViewJson}: any) => {

    if (!device) {
        return (
            <Card sx={{display: 'flex', flexGrow: 1}}>
                <Box>
                    <Typography level="title-md">Device info</Typography>
                </Box>
                <Divider />
                <Alert variant={"soft"} color={"primary"}>
                    <InfoIcon/> No information about device was pulled
                </Alert>
            </Card>
        )
    }

    return (
        <Card sx={{display: 'flex', flexGrow: 1}}>
            <Box>
                <Typography level="title-md">Device info</Typography>
            </Box>
            <Divider />
            <Stack spacing={1}>
                <TextInput label={"Device ID"} placeholder={"Device ID"} readOnly={true} onChange={() => {}} name={"device_id"} value={device?.device_id || "N/A"}/>
                <TextInput label={"Model"} placeholder={"Model"} readOnly={true} onChange={() => {}} name={"model"} value={device?.model || "N/A"}/>
                <TextInput label={"Manufacturer"} placeholder={"Manufacturer"} readOnly={true} onChange={() => {}} name={"manufacturer"} value={device?.manufacturer || "N/A"}/>
                <TextInput label={"When Created"} placeholder={"When Created"} readOnly={true} onChange={() => {}} name={"when_created"} value={device?.when_created || "N/A"}/>
                <TextInput
                    label={"Lat/Lon"}
                    placeholder={"Lat/Lon"}
                    readOnly={true}
                    onChange={() => {}}
                    name={"lat_lon"}
                    value={`${device?.latitude || "Not set"}/${device?.longitude || "Not set"}`}
                />
            </Stack>
            <CardOverflow>
                <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                    <Button size="sm" type={"button"} variant="solid" onClick={() => {
                        setShowViewJson({show: true, json: device.json})
                    }}>
                        <CodeIcon/> JSON
                    </Button>
                </CardActions>
            </CardOverflow>
        </Card>
    )
}