import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import {Button, FormControl, FormLabel, Input} from "@mui/joy";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import {useEffect, useState} from "react";
import {storage} from "../../../api/cameras";
import AspectRatio from "@mui/joy/AspectRatio";
import Textarea from "@mui/joy/Textarea";
import AddIcon from "@mui/icons-material/Add";
import {useOutletContext} from "react-router-dom";
import {CameraStorageType} from "../../../components/types";
import {ChangeStorageModal} from "./change-storage-modal";

const Storage = () => {

    const {camera, fetchCamera} = useOutletContext<{camera: any, fetchCamera: any}>();
    const [storages, setStorages] = useState<CameraStorageType[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        storage().then(({data}) => {
            setStorages(data.list);
        });
    }, []);

    const onChangeClick = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const modalSuccessCallback = () => {
        setShowModal(false);
        fetchCamera();
    }

    const currentStorage: CameraStorageType | undefined = storages.find((storage: CameraStorageType) => storage?.id === camera?.storage?.id);

    return (
        <>
            {currentStorage && (
                <ChangeStorageModal
                    open={showModal}
                    onClose={closeModal}
                    successCallback={modalSuccessCallback}
                    storages={storages}
                    initialStorage={currentStorage}
                    cameraId={camera.id}
                />
            )}
            <Card>
                <Box>
                    <Typography level="title-md">Camera SD Card</Typography>
                </Box>
                <Divider />
                {currentStorage ? <StorageInfo storage={currentStorage}/> : <NoStorage/>}
                {currentStorage &&
                    <CardOverflow>
                      <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm" variant="solid" onClick={onChangeClick}>
                          Change
                        </Button>
                      </CardActions>
                    </CardOverflow>
                }
            </Card>
        </>
    );
}

export default Storage;

const NoStorage = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Button size={"sm"}>
                <AddIcon/>
                Add Storage
            </Button>
        </Stack>
    );
}

type StrorageInfoProps = {
    storage: CameraStorageType
}

const StorageInfo = ({storage}: StrorageInfoProps) => {
    return (
        <Stack
            direction="row"
            spacing={3}
            sx={{ display: 'flex', my: 1 }}
        >
            <Stack direction="column" spacing={1}>
                <AspectRatio
                    ratio="1"
                    maxHeight={200}
                    sx={{ flex: 1, minWidth: 120 }}
                >
                    <img
                        src="https://exodusoutdoorgear.com/cdn/shop/products/EOGTCA010FrontPackage-2_1800x1800.png?v=1654022159"
                        srcSet="https://exodusoutdoorgear.com/cdn/shop/products/EOGTCA010FrontPackage-2_1800x1800.png?v=1654022159"
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
            </Stack>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                    <FormControl>
                        <FormLabel>Brand</FormLabel>
                        <Input size="sm" placeholder="Name" sx={{ flexGrow: 1 }} readOnly value={storage?.brand?.name || ""} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input size="sm" placeholder="Name" sx={{ flexGrow: 1 }} readOnly value={storage?.label || ""} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Capacity</FormLabel>
                        <Input size="sm" placeholder="Camera Name" readOnly value={storage?.capacity || ""} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Speed Class</FormLabel>
                        <Input size="sm" placeholder="Camera Name" readOnly value={storage?.speed_class || ""} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Type</FormLabel>
                        <Input size="sm" placeholder="Camera Name" readOnly value={storage?.type || ""} />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Textarea minRows={2} readOnly value={storage?.description || ""} />
                    </FormControl>
                </Stack>
            </Stack>
        </Stack>
    )
}