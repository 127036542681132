import client from './api-v1';
import clientV0 from "./api";
import {
    AccountCreateUpdateNoteResponse, AccountNoteRequest,
    AccountNotesResponse,
    AllAccountsResponse,
    OneAccountCountersResponse,
    OneAccountResponse
} from "../types/account";
import { AxiosResponse } from "axios";

const profile = async () => {
    return client.get('/profile');
}

const profilePasswordChange = async (data: any) => {
    return client.put('/profile/password', data);
}

const all = async (filters: any, page: number, limit: number): Promise<AllAccountsResponse> => {
    return client.get('/accounts', {
        params: {
            filters,
            page,
            limit
        }
    });
}

const create = async (data: any) => {
    return client.post('/account', data);
}

const update = async (id: number, data: any) => {
    return client.post(`/account/${id}`, data);
}

const view = async (id: number): Promise<OneAccountResponse> => {
    return client.get(`/account/${id}`);
}

const getCounters = async (id: number): Promise<OneAccountCountersResponse> => {
    return client.get(`/account/${id}/counters`);
}

const getNotes = async (id: number): Promise<AccountNotesResponse> => {
    return clientV0.get(`/account/${id}/notes`);
}

const deleteNote = async (id: number, noteId: number): Promise<void> => {
    return clientV0.delete(`/account/${id}/notes/${noteId}`);
}

const createNote = async (id: number, data: AccountNoteRequest): Promise<AccountCreateUpdateNoteResponse> => {
    return clientV0.post(`/account/${id}/notes`, data);
}

const updateNote = async (id: number, noteId: number, data: AccountNoteRequest): Promise<AccountCreateUpdateNoteResponse> => {
    return clientV0.put(`/account/${id}/notes/${noteId}`, data);
}

const activationEmail = async (id: number) => {
    return client.post(`account/${id}/activation-link`);
}

const restoreAccount = async (id: number) => {
    return client.post(`account/${id}/restore`);
}

const manualActivateAccount = async (id: number) => {
    return client.post(`account/${id}/activate-account`);
}

const exportAccounts = async (query?: string):Promise<AxiosResponse<string>> => {
    return client.get(`/accounts/export?${query}`);
}

const remove = async (id: number) => {
    return client.delete(`/account/${id}`);
}

export default {
    profile,
    all,
    create,
    update,
    createNote,
    getCounters,
    updateNote,
    deleteNote,
    exportAccounts,
    view,
    remove,
    profilePasswordChange,
    activationEmail,
    getNotes,
    restoreAccount,
    manualActivateAccount,
}
