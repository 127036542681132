import {AbilityBuilder, PureAbility} from '@casl/ability';
import {User} from './types';


export type AppActionType = 'create' | 'read' | 'update' | 'delete' | 'manage';
export type AppSubjectType = 'User' | 'Account' | 'Camera' | 'Firmware';
export type AppAbilityType = PureAbility<[AppActionType, AppSubjectType]>;
export type PermissionType = {
    action: AppActionType,
    subject: AppSubjectType,
}

export function defineAbilityFor (user: User | null | undefined) {
    const {can, cannot, build} = new AbilityBuilder<AppAbilityType>(PureAbility);
    can('manage', 'Account');
    can('manage', 'Camera');


    if (user && user?.role?.permissions) {
        if (user.role.permissions.includes('dash_users_access')) {
            can('manage', 'User');
        }
        if (user.role.permissions.includes('dash_firmware_access')) {
            can('manage', 'Firmware');
        }
    }

    return build();
}
