import * as React from 'react';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import Checkbox from '@mui/joy/Checkbox';
import ModalWrapper from "../../../components/modal";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import accountsApi from "../../../api/accounts";
import { useCallback, useContext, useState } from "react";
import { AppContext } from "../../../components/app-context";
import { handleAxiosError } from "../../../components/utils";
import moment from "moment";
import { AxiosError } from "axios";
import qs from "qs";

type Props = {
    open: boolean;
    onClose: () => void;
    filters: any;
}

const allFields = [
    'id',
    'first_name',
    'last_name',
    'email',
    'phone',
    'status',
    'registration_source',
    'created_at',
];

const ExportAccountsModal = ({ open, filters, onClose }: Props) => {
    const [fields, onChangeFields] = useState<string[]>(allFields);
    const [loading, onChangeLoading] = useState<boolean>(false);
    const {onNotification} = useContext(AppContext);

    const handleChangeCheckbox = useCallback((field: string) => {
        let newFields = fields.includes(field)
            ? fields.filter((item) => item !== field)
            : [...fields, field];
        if (field === "first_name") {
            newFields = newFields.includes("last_name")
                ? newFields.filter((item) => item !== "last_name")
                : [...newFields, "last_name"];
        }
        onChangeFields(newFields);
    }, [fields]);

    const handleSubmit = useCallback(async() => {
        try {
            onChangeLoading(true);
            const cols = fields.length === allFields.length ? undefined : fields;
            const query = qs.stringify({
                filters,
                cols
            });
            const { data } = await accountsApi.exportAccounts(query);
            const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
            const url = URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            const date = moment().format("YYYY-MM-DD_HH-mm-ss.")
            anchor.setAttribute('download', `accounts_${date}.csv`);
            anchor.click();
            onChangeLoading(false);
            onClose();
        } catch (error) {
            onChangeLoading(false);
            handleAxiosError(
                error as AxiosError,
                onNotification
            );
        }
    }, [onNotification, onClose, fields]);

    return (
        <ModalWrapper open={open} onClose={onClose}>
            <DialogTitle>Export accounts</DialogTitle>
            <DialogContent>Choose fields for export:</DialogContent>
            <Box>
                <Stack spacing={2}>
                    <Checkbox
                        label="ID"
                        value="id"
                        checked={fields.includes("id")}
                        onChange={() => handleChangeCheckbox("id")}
                    />
                    <Checkbox
                        label="Username"
                        value="first_name"
                        checked={fields.includes("first_name")}
                        onChange={() => handleChangeCheckbox("first_name")}
                    />
                    <Checkbox
                        label="Email"
                        value="email"
                        checked={fields.includes("email")}
                        onChange={() => handleChangeCheckbox("email")}
                    />
                    <Checkbox
                        label="Phone"
                        value="phone"
                        checked={fields.includes("phone")}
                        onChange={() => handleChangeCheckbox("phone")}
                    />
                    <Checkbox
                        label="Status"
                        value="status"
                        checked={fields.includes("status")}
                        onChange={() => handleChangeCheckbox("status")}
                    />
                    <Checkbox
                        label="Registration date"
                        value="created_at"
                        checked={fields.includes("created_at")}
                        onChange={() => handleChangeCheckbox("created_at")}
                    />
                    <Checkbox
                        label="Registration source"
                        value="registration_source"
                        checked={fields.includes("registration_source")}
                        onChange={() => handleChangeCheckbox("registration_source")}
                    />
                    <Button type="submit" loading={loading} onClick={handleSubmit}>Export</Button>
                    <Button color="neutral" onClick={onClose}>Close</Button>
                </Stack>
            </Box>
        </ModalWrapper>
    );
}

export default ExportAccountsModal;
