import * as React from "react";
import { cameraCheckDeleted } from "../../api/cameras";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, ButtonGroup, Chip, ColorPaletteProp, Typography } from "@mui/joy";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import ListPage from "../../components/listPage";
import { formatDateTime } from "../../components/utils";
import Battery from "../../components/camera/battery";
import Signal from "../../components/camera/signal";
import PowerSource from "../../components/camera/power-source";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import CreateCamera from "./components/create";
import DeleteCamera from "./components/delete";
import DeleteSoftCamera from "./components/delete-soft";
import Filters from "./components/filters";
import { useSearchParams } from "react-router-dom";
import Highlighted from "../../components/highlighted";
import { getNumberFromSearchParams } from "../../components/utils";
import qs from "qs";
import { useGetCamerasList } from "../../query/cameras-query";

function chipStatusColor(status: string): string {

    if (status === "deleted") {
        return "danger";
    }

    if (status === "stolen") {
        return "danger";
    }

    if (status === "returned") {
        return "warning";
    }

    if (status === "inactive") {
        return "neutral";
    }

    return "success";
}

function chipSimStatusColor(status: string): string {

    if (status.toLowerCase() === "not-found") {
        return "danger";
    }

    if (status.toLowerCase() === "activation-failed") {
        return "danger";
    }

    if (status.toLowerCase() === "disabled") {
        return "neutral";
    }

    return "success";
}

export const Cameras = () => {
    const navigate = useNavigate();
    const [ showCreateCamera, setShowCreateCamera ] = useState(false);
    const [ cameraSoftDelete, setSoftDeleteCamera ] = useState<any>({
        show: false,
        warnings: [],
        camera: null
    });
    const [ searchParams, setSearchParams ] = useSearchParams();
    const filters = qs.parse(searchParams.toString()).filters || {};
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, isLoading, refetch } = useGetCamerasList({
        filters,
        page: currentPage,
        limit
    });

    const textToHighlight = data?.filters?.search ?? "";

    const totalPages = data?.pagination?.totalPages ?? 1;
    const totals = data?.totals ?? {
        total: 0,
        simActivationFailed: 0,
        simNotFound: 0,
        notActiveSim: 0,
        paused: 0,
        notActive: 0,
        noAccount: 0,
    };
    const cameras = data?.list ?? [];

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                filters: filters,
                limit: perPage,
                page,
            })
        );
    };

    const onSoftDeleteClick = useCallback(async (camera: any) => {
        try {
            const { data } = await cameraCheckDeleted(camera.id);
            setSoftDeleteCamera({
                show: true,
                warnings: data.warnings ?? [],
                camera: camera
            });
        } catch (error) {

        }
    }, []);

    const onSoftDeleteClose = () => {
        setSoftDeleteCamera({ show: false, warnings: [], camera: null });
    };

    const onSoftDeleteSuccess = async (cameraDelete: boolean = false) => {
        if (cameraDelete) {
            await refetch();
        }
        setSoftDeleteCamera({ show: false, camera: null });
    };

    const applyFilters = (filters: any) => {
        setSearchParams(
            qs.stringify({
                filters: filters,
                page: 1,
                limit: limit
            })
        );
    };

    const headers = [
        { width: 200, label: "Account" },
        { width: 200, label: "Name" },
        { width: 150, label: "IMEI" },
        { width: 150, label: "Model" },
        { width: 100, label: "Pw./Bat./Sig." },
        { width: 85, label: "SIM" },
        { width: 85, label: "Status" },
        { width: 85, label: "Paused?" },
        { width: 150, label: "Created At" },
        { width: 120, label: "" },
    ];

    const pagination = {
        count: totalPages,
        page: currentPage,
        onChange: handlePaginationChange,
        limit: limit
    };

    return (
        <>
            <CreateCamera open={showCreateCamera} onClose={() => {
                setShowCreateCamera(false);
            }}/>
            <DeleteSoftCamera warnings={cameraSoftDelete.warnings} open={cameraSoftDelete.show}
                              camera={cameraSoftDelete.camera} onClose={onSoftDeleteClose}
                              onSuccessCallback={onSoftDeleteSuccess}/>
            <ListPage
                title="Manage Cameras"
                breadcrumbs={[ { name: "Cameras" } ]}
                headers={headers}
                pagination={pagination}
                loading={isLoading}
                empty={cameras.length < 1}
                placeholder="Cameras not found"
                actions={(
                    <Button size={"sm"} onClick={() => {
                        setShowCreateCamera(true);
                    }}>
                        <AddIcon/> Create
                    </Button>
                )}
                filters={(
                    <>
                        <div className={"cameras-totals"}>
                            <Chip color={"primary"} variant={"solid"} onClick={() => {
                                applyFilters({});
                            }} className={"chip-total"}>Total: {totals?.total || 0}</Chip>
                        </div>
                        <div className={"cameras-totals"}>
                            <Chip color={"danger"} variant={"solid"} onClick={() => {
                                applyFilters({ sim_status: "failed" });
                            }} className={"chip-total"}>Sim Activation Failed: {totals?.simActivationFailed || 0}</Chip>
                            <Chip color={"danger"} variant={"solid"} onClick={() => {
                                applyFilters({ sim_status: "not_found" });
                            }} className={"chip-total"}>Sim Not Found: {totals?.simNotFound || 0}</Chip>
                            <Chip color={"warning"} variant={"solid"} onClick={() => {
                                applyFilters({ sim_status: "disabled" });
                            }} className={"chip-total"}>Sim Not Active: {totals?.notActiveSim || 0}</Chip>
                        </div>
                        <div className={"cameras-totals"}>
                            <Chip color={"neutral"} variant={"solid"} onClick={() => {
                                applyFilters({ is_paused: "1" });
                            }} className={"chip-total"}>Paused: {totals?.paused || 0}</Chip>
                            <Chip color={"neutral"} variant={"solid"} onClick={() => {
                                applyFilters({ status: "inactive" });
                            }} className={"chip-total"}>Not active: {totals?.notActive || 0}</Chip>
                            <Chip color={"neutral"} variant={"solid"} onClick={() => {
                                applyFilters({ no_account: "yes" });
                            }} className={"chip-total"}>No account: {totals?.noAccount || 0}</Chip>
                        </div>
                        <Filters filters={filters} applyFilters={applyFilters}/>
                    </>
                )}
            >
                {cameras.map((camera) => {
                    let chipColor = chipStatusColor(camera.status) as ColorPaletteProp;
                    let chipSimColor = chipSimStatusColor(camera.sim_status) as ColorPaletteProp;

                    return (
                        <tr key={camera.id} onClick={() => navigate(`/camera/${camera.id}`)}>
                            <td>
                                <Typography level="body-xs">
                                    {
                                        camera?.account ? <Highlighted text={camera.account.name}
                                                                       highlight={textToHighlight}/> :
                                            <i>Not Set</i>
                                    }
                                </Typography>
                            </td>
                            <td>
                                <Typography level="body-xs">
                                    {
                                        camera?.name ? <Highlighted text={camera.name}
                                                                    highlight={textToHighlight}/> :
                                            <i>Not Set</i>
                                    }
                                </Typography>
                            </td>
                            <td>
                                <Typography level="body-xs"><Highlighted text={camera.imei}
                                                                         highlight={textToHighlight}/></Typography>
                            </td>

                            <td>
                                <Typography level="body-xs">{camera?.model?.label}</Typography>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <Typography level="body-xs" className={"camera-stats"}>
                                    <PowerSource number={camera?.stats?.power_source}/>/
                                    <Battery number={camera?.stats?.battery}/>/
                                    <Signal number={camera?.stats?.signal}/>
                                </Typography>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <Typography level="body-xs"><Chip color={chipSimColor} size={"sm"}
                                                                  variant={"solid"}>{camera.sim_status}</Chip></Typography>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <Typography level="body-xs"><Chip color={chipColor} size={"sm"}
                                                                  variant={"solid"}>{camera.status}</Chip></Typography>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <Typography level="body-xs">
                                    {camera.is_paused ?
                                        <Chip size={"sm"} color={"danger"}>Yes</Chip> :
                                        <Chip size={"sm"} color={"success"}>No</Chip>}
                                </Typography>
                            </td>
                            <td>
                                <Typography
                                    level="body-xs">{formatDateTime(camera?.created_at)}</Typography>
                            </td>
                            <td
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <ButtonGroup
                                    color="neutral"
                                    disabled={false}
                                    size="sm"
                                    variant="outlined"
                                >
                                    <IconButton variant={"outlined"} color={"primary"}
                                                to={`/camera/${camera.id}`}
                                                component={Link}><EditRoundedIcon
                                        fontSize={"small"}/></IconButton>
                                    <IconButton variant={"outlined"} color={"warning"}
                                                onClick={() => {
                                                    onSoftDeleteClick(camera);
                                                }}><DeleteIcon/></IconButton>
                                </ButtonGroup>
                            </td>
                        </tr>
                    );
                })}
            </ListPage>
        </>
    );
};
