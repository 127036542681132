import * as React from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";
import CircularProgress from "@mui/joy/CircularProgress";
import NoContent from "../../components/no-content";
import Typography from "@mui/joy/Typography";
import { Media as CameraMedia } from "../../components/camera/media";
import { getNumberFromSearchParams } from "../../components/utils";
import Pagination from "../../components/pagination";
import { useGetAccountMedia } from "../../query/accounts-query";
import qs from "qs";
import { Account } from "../../types/account";

export function Media() {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const totalPages = getNumberFromSearchParams(searchParams, "totalPages", 10);
    const { account } = useOutletContext<{ account: Account }>();

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };
    const { data, isLoading } = useGetAccountMedia(account.id, currentPage, limit);
    const mediaList = data?.list ?? [];

    return (
        <Stack spacing={4} sx={{
            display: "flex",
            minWidth: "600px",
            paddingLeft: "48px",
            paddingRight: "48px",
            px: { xs: 2, md: 6 },
        }}>
            {isLoading && <Grid container sx={{
                justifyContent: "center",
                alignContent: "center",
                padding: "100px"
            }}><CircularProgress sx={{
                "--CircularProgress-size": "60px"
            }}/></Grid>}
            {(!isLoading && mediaList.length === 0) && <NoContent><Typography>No media found</Typography></NoContent>}
            {(!isLoading && mediaList.length > 0) && (
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                        gap: 2,
                    }}
                >
                    {mediaList.map(media => {
                        return ( //@ts-ignore
                            <CameraMedia account key={media.id} media={media}/>
                        );
                    })}
                </Box>
            )}
            {!isLoading && mediaList.length > 0 && (
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            )}
        </Stack>
    );
}
