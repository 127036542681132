import * as React from 'react';
import {Chip} from "@mui/joy";
import Battery from "@mui/icons-material/BatteryCharging50";
import Outlet from "@mui/icons-material/Outlet";

type PowerSource = {
    number: number
};

export const PowerSource = ({number}: PowerSource): any => {
    if (!number) {
        return <Chip color={"neutral"} size={"sm"}>{'N/A'}</Chip>
    }

    if (number === 1) {
        return <Battery />
    }

    return <Outlet/>
}

export default PowerSource;

