import ListPage from "../../components/listPage";
import { getNumberFromSearchParams } from "../../components/utils";
import qs from "qs";
import { useSearchParams, Link } from "react-router-dom";
import { Typography } from "@mui/joy";
import { useGetExchangeHistory } from "../../query/exchange-query";

const ExchangeHistory = () => {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const page = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, isLoading } = useGetExchangeHistory({ page, limit });

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    const pagination = {
        count: data?.pagination?.totalPages ?? 1,
        page,
        limit,
        onChange: handlePaginationChange,
    };

    const headers = [
        { label: "Account", width: 150 },
        { label: "Type", width: 150 },
        { label: "Camera", width: 150 },
        { label: "Old name", width: 150 },
        { label: "Old ICCID", width: 200 },
        { label: "Old IMEI", width: 200 },
        { label: "New name", width: 150 },
        { label: "New ICCID", width: 200 },
        { label: "New IMEI", width: 200 },
        { label: "Status", width: 100 },
        { label: "Date", width: 100 },
    ];

    const list = data?.list ?? [];

    return (
        <ListPage
            pagination={pagination}
            breadcrumbs={[ { name: "Exchange history" } ]}
            title="Exchange history"
            loading={isLoading}
            empty={list.length < 1}
            headers={headers}
        >
            <>
                {list.map((item, idx) => (
                    <tr key={idx}>
                        <td width={150}>
                            <Link to={`/account/${item.account.id}`} style={{textDecoration: "none"}}>
                                <Typography
                                    sx={{overflow: "hidden", textOverflow: "ellipsis"}}
                                    color="primary"
                                    level="body-xs"
                                    title={item.account.email}
                                >
                                    {item.account.email}
                                </Typography>
                            </Link>
                        </td>
                        <td width={150}>
                            <Typography level="body-xs">{item.type}</Typography>
                        </td>

                        <td width={150}>
                            <Typography level="body-xs">{item.camera.model ?? "-"}</Typography>
                        </td>

                        <td width={200}>
                            <Typography level="body-xs" sx={{overflow: "hidden", textOverflow: "ellipsis"}}>{item.oldName ?? "-"}</Typography>
                        </td>

                        <td width={200}>
                            <Typography level="body-xs">{item.oldIccid ?? "-"}</Typography>
                        </td>

                        <td width={200}>
                            <Typography level="body-xs">{item.oldImei ?? "-"}</Typography>
                        </td>

                        <td width={150}>
                            <Typography
                                sx={{overflow: "hidden", textOverflow: "ellipsis"}}
                                level="body-xs"
                                title={item.newName ?? "-"}
                            >
                                {item.newName ?? "-"}
                            </Typography>
                        </td>

                        <td width={200}>
                            <Typography level="body-xs">{item.newIccid ?? "-"}</Typography>
                        </td>

                        <td width={200}>
                            <Typography level="body-xs">{item.newImei ?? "-"}</Typography>
                        </td>

                        <td width={100}>
                            <Typography level="body-xs">{item.camera.status ?? "-"}</Typography>
                        </td>

                        <td width={100}>
                            <Typography level="body-xs">{item.timestamp ?? "-"}</Typography>
                        </td>
                    </tr>
                ))}
            </>
        </ListPage>
    );
};

export default ExchangeHistory;
