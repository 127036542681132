import React, {Dispatch, SetStateAction} from "react";
import {NotificationType, OnNotificationType, User} from "./types";
import {AppAbilityType} from "./ability";
import {PureAbility} from "@casl/ability";


export type AppContextType = {
    user?: User | null,
    setUser: Dispatch<SetStateAction<User | null>>,
    authenticated: boolean,
    setAuthenticated: (authenticated: boolean) => void,
    notification: {
        message: string,
        type: NotificationType
    },
    onNotification: OnNotificationType,
    ability: AppAbilityType,
    glossary: any,
};

export const AppContext = React.createContext<AppContextType>({
    user: null,
    authenticated: !!window.localStorage.getItem('TOKEN'),
    setUser: () => {},
    setAuthenticated: () => {},
    onNotification: () => {},
    notification: {
        message: "",
        type: "success"
    },
    ability: new PureAbility(),
    glossary: {},
});