import client from './api';
import { AllSubscriptionsResponse } from "../types/subscription";

export const getSubstiptionsByAccountId = async (accountId: number, page: number, limit: number):Promise<AllSubscriptionsResponse> => {
    return client.get(`/account/${accountId}/subscriptions`, {
        params: {
            page,
            limit
        }
    });
}

export const getSubstiptionsHistoryByAccountId = async (accountId: number, page: number, limit: number):Promise<AllSubscriptionsResponse> => {
    return client.get(`/account/${accountId}/subscriptions-history`, {
        params: {
            page,
            limit
        }
    });
}
