import * as React from 'react';
import {ChangeEvent, useEffect, useState, useContext} from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import {models} from "../../../api/cameras";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/joy/Button";
import {AppContext} from "../../../components/app-context";
import { useGetCameraModels } from "../../../query/cameras-query";

type FiltersProps = {
    filters: any,
    applyFilters: any
}

const defaultFilters = {
    search: "",
    status: "",
    type: "",
    model_id: ""
}

export const Filters = ({filters, applyFilters}: FiltersProps) => {
    const {glossary} = useContext(AppContext);

    const { data } = useGetCameraModels();
    const [filterData, setFilterData] = useState<any>({...defaultFilters, ...filters});
    const modelsList = data?.list ?? [];

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFilterData({
            ...filterData,
            [name]: value
        });
    }

    const onSelectChange = (name: string, value: number) => {
        setFilterData({
            ...filterData,
            [name]: value
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const o = Object.fromEntries(Object.entries(filterData).filter(([_, v]) => (v != null && v != "")));
        applyFilters(o);
    }

    const onReset = async (event: any) => {
        setFilterData(defaultFilters);
        applyFilters({});
    }

    return (
        <form onSubmit={onSubmit}>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: 'sm',
                    py: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: '160px',
                    },
                }}
            >
                <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search</FormLabel>
                    <Input
                        name="search"
                        size="sm"
                        placeholder="Search by Version, File Name.."
                        startDecorator={<SearchIcon />}
                        onChange={onChange}
                        value={filterData.search}
                    />
                </FormControl>
                <React.Fragment>
                    <FormControl size="sm">
                        <FormLabel>Status</FormLabel>
                        <Select
                            size="sm"
                            placeholder="Status"
                            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                            name="status"
                            value={filterData.status}
                            onChange={(event: any, value: any) => {
                                onSelectChange('status', value);
                            }}
                        >
                            <Option value="">All</Option>
                            <Option value="active">Active</Option>
                            <Option value="inactive">Not active</Option>
                            <Option value="deleted">Deleted</Option>
                        </Select>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>Type</FormLabel>
                        <Select
                            size="sm"
                            placeholder="Type"
                            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                            name="type"
                            value={filterData.type}
                            onChange={(event: any, value: any) => {
                                onSelectChange('type', value);
                            }}
                        >
                            <Option value="">All</Option>
                            {glossary?.firmware?.types.map((type: any, index: number) =>
                                <Option key={index} value={type.name}>{type.label}</Option>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>Model</FormLabel>
                        <Select
                            size="sm"
                            placeholder="Model"
                            name={"model_id"}
                            onChange={(event: any, value: any) => {
                                onSelectChange('model_id', value);
                            }}
                            value={filterData.model_id}
                        >
                            <Option value="">All</Option>
                            {modelsList.map(model =>
                                <Option key={model.id} value={model.id}>{model.label}</Option>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>&nbsp;</FormLabel>
                        <Button type="submit" size="sm">Apply</Button>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>&nbsp;</FormLabel>
                        <Button type={"button"} onClick={onReset} size={"sm"} color={"neutral"}>Reset</Button>
                    </FormControl>
                </React.Fragment>
            </Box>
        </form>
    );
}

export default Filters;
