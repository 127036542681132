import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {useEffect, useState} from "react";
import ApiAccounts from "../../../api/accounts";
import Typography from "@mui/joy/Typography";

type ModalWrapperProps = {
    open: boolean,
    onClose: any,
    account: any,
    onSuccessCallback: any
}

export const ActivationEmail = ({open, onClose, onSuccessCallback, account}: ModalWrapperProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMessage('');
    }, [account, open]);

    const onConfirmClick = async () => {
        setIsLoading(true);
        setMessage('');
        try {
            await ApiAccounts.activationEmail(account.id);
            onSuccessCallback();
        } catch (err: any) {
            setMessage(err?.response?.data?.message || 'Internal Server Error');
        }

        setIsLoading(false);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon />
                    Re-send activation email
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <p>Are you sure that you want send activation email to account {account?.first_name} {account?.last_name}?</p>
                    {message.length > 0 && <Typography color="warning">{message}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="success" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default ActivationEmail;