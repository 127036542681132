import * as React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {Link} from "react-router-dom";
import {TabList, Tabs} from '@mui/joy';
import Tab, { tabClasses } from '@mui/joy/Tab';

type NavTab = {
    name: string,
    to: string
}

type PageTabNavProps = {
    list: NavTab[]
};

export const PageNavTab = ({list}: PageTabNavProps) => {

    let location = useLocation();

    const current = list.findIndex(item => item.to === location.pathname);

    return (
        <Tabs
            value={current}
            sx={{
                bgcolor: 'transparent',
            }}
        >
            <TabList
                tabFlex={1}
                size="sm"
                sx={{
                    pl: { xs: 0, md: 4 },
                    justifyContent: 'left',
                    [`&& .${tabClasses.root}`]: {
                        fontWeight: '600',
                        flex: 'initial',
                        color: 'text.tertiary',
                        [`&.${tabClasses.selected}`]: {
                            bgcolor: 'transparent',
                            color: 'text.primary',
                            '&::after': {
                                height: '2px',
                                bgcolor: 'primary.500',
                            },
                        },
                    },
                }}
            >
                {
                    list.map((item, index) => {
                        return (
                            <Tab key={`nav-${index}`} sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={index} to={item.to} component={Link}>
                                {item.name}
                            </Tab>
                        )
                    })
                }
            </TabList>
        </Tabs>
    );
}