import client from './api';
import { AllUsersResponse, OneUserResponse, RolesResponse } from "../types/user";

const usersList = async (page: number, limit: number):Promise<AllUsersResponse> => {
    return client.get('/users', {
        params: {
            page,
            limit
        }
    });
}

const view = async (id: number | string):Promise<OneUserResponse> => {
    return client.get(`/users/${id}`);
}

const create = async (data: any) => {
    return client.post('/users', {user: data});
}

const update = async (id: number, data: any) => {
    return client.post(`/users/${id}`, data);
}

const remove = async (id: number) => {
    return client.delete(`/users/${id}`);
}

const assignRole = async (id: number, role: string)=> {
    return client.post(`/users/${id}/role`, {role});
}

export const rolesList = async ():Promise<RolesResponse> => {
    return client.get('/auth/roles');
}

export default {
    usersList,
    view,
    create,
    update,
    remove,
    rolesList,
    assignRole,
}
