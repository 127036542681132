import * as React from 'react';
import {useOutletContext} from "react-router-dom";
import Stack from "@mui/joy/Stack";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import {Button, FormControl, FormLabel, Input} from "@mui/joy";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import ApiCameraSettings from "../../api/camera-settings";
import Grid from "@mui/joy/Grid";
import CircularProgress from "@mui/joy/CircularProgress";
import Option from "@mui/joy/Option";
import Select from '@mui/joy/Select';
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";

export const Settings = () => {

    const {onNotification} = useContext(AppContext);
    const {camera} = useOutletContext<{camera: any}>();

    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [settings, setSettings] = useState<any>({});
    const [formData, setFormData] = useState<any>({});

    useEffect(() => {
        fetchSettings();
    }, [camera.id]);

    const fetchSettings = async () => {
        setIsLoading(true);

        try {
            const {data} = await ApiCameraSettings.settings(camera.id);
            setSettings(data);
            setFormData(data.values || {});
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });
    }

    const onSelectChange = (name: string, value: number) => {
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsProcessing(true);

        try {
            await ApiCameraSettings.updateSettings(camera.id, formData);
            onNotification("Settings successfully updated");
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsProcessing(false);
    }

    const onCancel = () => {
        setFormData(settings.values);
    }

    const {schema} = settings;

    return (
        <Stack spacing={4} sx={{
            display: 'flex',
            minWidth: '800px',
            maxWidth: '1200px',
            mx: 'auto',
            px: { xs: 2, md: 6 },
        }}>
            <form onSubmit={onSubmit}>
                <Card>
                    <Box>
                        <Typography level="title-md">Camera settings</Typography>
                        <Typography level="body-sm">
                            Customize camera settings.
                        </Typography>
                    </Box>
                    <Divider />
                    {isLoading && <Grid container sx={{justifyContent: "center", alignContent: "center", padding: "100px"}}><CircularProgress sx={{
                        "--CircularProgress-size": "60px"
                    }}/></Grid>}
                    {
                        !isLoading &&
                        <CardOverflow>
                            <CameraSettings schema={schema || []} values={formData} onSelectChange={onSelectChange} onChange={onChange}/>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                <Button size="sm" variant="outlined" color="neutral" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button size="sm" variant="solid" type={"submit"} loading={isProcessing}>
                                    Save
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    }
                </Card>
            </form>
        </Stack>
    );
}

type CameraSettingsProps = {
    schema: any[],
    values: any,
    onSelectChange: any,
    onChange: any
}

const CameraSettings = (props: CameraSettingsProps) => {

    const {schema, values, onChange, onSelectChange} = props;

    return (
        <Stack spacing={2}>
            {
                schema.map((item: any) => {
                   if (item.type === "select") {
                       return <SelectRow
                           key={item.id}
                           id={item.id}
                           label={item.label}
                           type={item.type}
                           default={item.default}
                           dependsOn={item.dependsOn}
                           options={item.options}
                           values={values}
                           onSelectChange={onSelectChange}
                       />
                   }

                   if (item.type === "time") {
                       return <Time
                           key={item.id}
                           id={item.id}
                           label={item.label}
                           type={item.type}
                           default={item.default}
                           values={values}
                           dependsOn={item.dependsOn || {}}
                           onChange={onChange}/>
                   }
                })
            }
        </Stack>
    )
}

type SelectSetting = {
    id: string,
    label: string,
    type: string,
    options: SelectOption[],
    default: string|number,
    values: any,
    dependsOn: any,
    onSelectChange: any
}

type SelectOption = {
    value: string|number,
    label: string
}

const SelectRow = (props: SelectSetting) => {

    const {id, label, options, onSelectChange, values} = props;

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <Select
                placeholder={label}
                name={id}
                color={"neutral"}
                value={values[id]}
                onChange={(event: any, value: any) => {
                    onSelectChange(id, value);
                }}
            >
                {options.map((option: SelectOption, index) => {
                    return (
                        <Option key={`${option.value}-${index}`} value={option.value}>{option.label}</Option>
                    )
                })}
            </Select>
        </FormControl>
    )
}

type TimeSetting = {
    id: string,
    label: string,
    type: string,
    default: string|number,
    values: any,
    dependsOn: any,
    onChange: any
}

const Time = (props: TimeSetting) => {

    const {id, type, label, onChange, values} = props;

    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <Input
                type={type}
                placeholder={label}
                name={id}
                onChange={onChange}
                value={values[id] || props.default} />
        </FormControl>
    );
}