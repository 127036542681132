import React, {useState} from 'react';
import {useDropzone, FileRejection} from 'react-dropzone';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Box from '@mui/joy/Box';
import {Stack} from '@mui/joy';
import {fileSize} from "../utils";

type DropzoneProps = {
    label: string,
    text: string,
    files: File[],
    onChange: (files: File[]) => void,
    maxFiles?: number,
    error?: string,
}

const Dropzone = (props: DropzoneProps) => {
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        props.onChange(acceptedFiles);
        setRejectedFiles(fileRejections);
    }

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        maxFiles: props.maxFiles || 0,
    })

    const hasError = !!props.error;

    return (
        <FormControl error={hasError}>
            <Stack spacing={1}>
                <FormLabel>{props.label}</FormLabel>
                <Box
                    {...getRootProps()}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                        borderWidth: '2px',
                        borderRadius: '2px',
                        borderColor: 'divider',
                        borderStyle: 'dashed',
                        outline: 'none',
                        transition: 'border .24s ease-in-out',
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography level="body-sm" color={hasError ? "danger" : "neutral"}>{props.text}</Typography>
                </Box>
                {props.files.length > 0 && (
                    <Box>
                        <Typography level="title-sm">
                            {props.files.length === 1 ? 'File' : 'Files'}
                        </Typography>
                        <List>
                            {props.files.map(file => (
                                <ListItem key={file.name}>
                                    <Typography level="body-sm">{file.name} - {fileSize(file.size)}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
                {rejectedFiles.length > 0 && (
                    <Box>
                        <Typography level="title-sm">
                            Rejected {rejectedFiles.length === 1 ? 'file' : 'files'}
                        </Typography>
                        <List
                            sx={{
                                '--List-nestedInsetStart': '12px'
                            }}
                        >
                            {rejectedFiles.map(({file, errors}) => (
                                <ListItem key={file.name} nested>
                                    <Typography level="body-sm">{file.name} - {fileSize(file.size)}</Typography>
                                    <List>
                                        {errors.map((e) => (
                                            <ListItem key={e.code}>
                                                <Typography
                                                    level="body-sm"
                                                    color="danger"
                                                    startDecorator={<ErrorOutline />}
                                                >
                                                    {e.message}
                                                </Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
                {
                    hasError &&
                    <FormHelperText>
                      <InfoOutlined />
                        {props.error}
                    </FormHelperText>
                }
            </Stack>
        </FormControl>
    );
}

export default Dropzone;