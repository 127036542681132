import * as React from 'react';
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {Link as RouterLink} from "react-router-dom";
import Stack from "@mui/joy/Stack";

export type BreadCrumb = {
    name: string,
    to?: string,
    icon?: any,
    isLast?: boolean
};

type PageHeaderProps = {
    title: string,
    breadCrumbs?: BreadCrumb[],
    children?: any
};

export const PageHeader = ({title, children, breadCrumbs}: PageHeaderProps) => {
    return (
        <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon fontSize="small" />}
                sx={{ pl: 0 }}
            >
                <Link
                    underline="none"
                    color="neutral"
                    to={"/"}
                    aria-label="Home"
                    component={RouterLink}
                >
                    <HomeRoundedIcon />
                </Link>
                {breadCrumbs && breadCrumbs.map((item, index) => {
                    const breadCrumb = {
                        ...item,
                        isLast: (index === breadCrumbs.length - 1)
                    }
                    return (
                        <BreadCrumb key={`bread-${index}`} {...breadCrumb}/>
                    )
                })}
            </Breadcrumbs>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                    {title}
                </Typography>
                {children}
            </Stack>

        </Box>
    );
}

export default PageHeader;

const BreadCrumb = (props: BreadCrumb) => {

    const {name, to, icon, isLast} = props;

    if (to) {
        return (
            <Link
                underline="none"
                color="neutral"
                to={to}
                aria-label="Home"
                component={RouterLink}
            >
                {!!icon && icon}
                {name &&
                    <Typography color={isLast ? "primary" : "neutral"} fontWeight={500} fontSize={12}>
                        {name}
                    </Typography>
                }
            </Link>
        )
    }

    return (
        <Typography color="primary" fontWeight={500} fontSize={12}>
            {name}
        </Typography>
    )
}
