import client from './api-v1';
import clientV2 from './api';
import { OneResponseData } from "../types/common";
import { FullMediaResponse } from "../types/media";
import { CameraListResponse, CameraModelsListResponse } from "../types/camera";

export const camerasList = async (filters: any, page: number, limit: number):Promise<CameraListResponse> => {
    return client.get(`/cameras`, {
        params: {
            filters,
            page,
            limit
        }
    });
}

export const findCamera = async (id: number) => {
    return client.get(`/camera/${id}`);
}

export const findCameraMedia = async (id: number, page: number, limit: number) => {
    return client.get(`/camera/${id}/media`, {
        params: {
            page,
            limit,
        }
    });
}

export const getCameraCommandsErrors = async (id: number | string, page: number, limit: number) => {
    return clientV2.get(`/camera/${id}/errors/commands`, {
        params: {
            page,
            limit,
        }
    });
}

export const getCameraMediaErrors = async (id: number | string, page: number, limit: number) => {
    return clientV2.get(`/camera/${id}/errors/media`, {
        params: {
            page,
            limit,
        }
    });
}

export const getCameraMediaErrorLink = async (id: number | string, mediaId: number) => {
    return clientV2.get(`/camera/${id}/errors/media/${mediaId}/link`);
}

export const getCameraOneMedia = async (id: number | string, mediaId: number | string): Promise<FullMediaResponse> => {
    return client.get(`/camera/${id}/media/${mediaId}`);
}

export const findCameraReports = async (id: number, page: number, limit: number) => {
    return client.get(`/camera/${id}/reports-log`, {
        params: {
            page,
            limit,
        }
    });
}

export const findCameraEventsLog = async (id: number, page: number, limit: number) => {
    return client.get(`/camera/${id}/events-log`, {
        params: {
            page,
            limit,
        }
    });
}

export const models = async ():Promise<CameraModelsListResponse> => {
    return client.get(`/camera-models`);
}

export const model = async (id: number) => {
    return client.get(`/camera-models/${id}`);
}

export const storage = async () => {
    return client.get(`/camera-storage`);
}

export const createCamera = async (data: any) => {
    return client.post(`/camera`, data);
}

export const searchSingleCamera = async (imei: string) => {
    return client.post(`/cameras/search`, {
        search: {
            imei: imei
        }
    });
}

export const cameraMarkAsDeleted = async (id: number) => {
    return client.delete(`/camera/${id}?approved=true`);
}

export const cameraCheckDeleted = async (id: number):Promise<OneResponseData<{warnings: string[]}>> => {
    return client.get(`/camera/${id}/delete`);
}

export const cameraDeletePermanent = async (id: number) => {
    return client.delete(`/camera/${id}/permanent`);
}

const update = async (id: number, data: any) => {
    return client.put(`/cameras/${id}`, {
        camera: data
    });
}

const simActivationsHistory = async (id: number, page: number, limit: number) => {
    return client.get(`/cameras/${id}/sim-activations`, {
        params: {
            page,
            limit
        }
    });
}

const activateSim = async (id: number) => {
    return client.post(`/cameras/${id}/activate-sim`);
}

const hologramInfo = async (id: number) => {
    return client.get(`/camera/${id}/hologram-info`);
}

const pauseDevice = async (id: number) => {
    return client.post(`/cameras/${id}/device-pause`);
}

const unPauseDevice = async (id: number) => {
    return client.post(`/cameras/${id}/device-unpause`);
}

export default {
    findCameraReports,
    cameraCheckDeleted,
    camerasList,
    update,
    simActivationsHistory,
    activateSim,
    getCameraOneMedia,
    hologramInfo,
    pauseDevice,
    unPauseDevice,
}
