import * as React from "react";
import { useOutletContext } from "react-router-dom";
import { Button, FormControl, Sheet, FormLabel, Input, Box } from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ProfileImg from "../../images/profile.png";
import { ChangeEvent, useState, useContext } from "react";
import { isEqual } from "../../components/utils";
import ApiAccounts from "../../api/accounts";
import { errorsToObject } from "../../components/utils";
import { AppContext } from "../../components/app-context";
import { handleAxiosError } from "../../components/utils";
import { Account } from "../../types/account";

type Form = {
    first_name: string,
    last_name: string,
    email: string,
    phone?: string
};

export const General = () => {

    const { account } = useOutletContext<{ account: Account }>();
    const { onNotification } = useContext(AppContext);

    const [ initialForm, setInitialForm ] = useState<Form>({
        first_name: account?.first_name,
        last_name: account?.last_name,
        email: account?.email,
        phone: account?.phone || ""
    });
    const [ formData, setFormData ] = useState<Form>(initialForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errors, setErrors ] = useState<any[]>([]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    };

    const onCancel = () => {
        setFormData(initialForm);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await ApiAccounts.update(account.id, formData);
            setInitialForm(formData);
            onNotification("Account updated");
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    };

    return (
        <Stack
            spacing={4}
            sx={{
                display: "flex",
                maxWidth: "800px",
                mx: "auto",
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}
        >
            <form onSubmit={onSubmit}>
                <Card>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Account info</Typography>
                        <Typography level="body-sm">Customize account information.</Typography>
                    </Box>
                    <Divider/>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
                    >
                        <Stack direction="column" spacing={1}>
                            <AspectRatio
                                ratio="1"
                                maxHeight={200}
                                sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                            >
                                <img
                                    src={ProfileImg}
                                    srcSet={ProfileImg}
                                    loading="lazy"
                                    alt=""
                                />
                            </AspectRatio>
                        </Stack>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                            <Stack spacing={1}>
                                <FormControl sx={{ display: { sm: "flex-column", md: "flex-row" } }}>
                                    <FormLabel>First Name</FormLabel>
                                    <Input size="sm" placeholder="First name" name={"first_name"} onChange={onChange}
                                           value={formData?.first_name || ""}/>
                                </FormControl>
                                <FormControl sx={{ display: { sm: "flex-column", md: "flex-row" } }}>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input size="sm" placeholder="Last name" sx={{ flexGrow: 1 }} onChange={onChange}
                                           name={"last_name"} value={formData?.last_name || ""}/>
                                </FormControl>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        size="sm"
                                        type="email"
                                        startDecorator={<EmailRoundedIcon/>}
                                        placeholder="email"
                                        sx={{ flexGrow: 1 }}
                                        name={"email"}
                                        value={formData?.email}
                                        onChange={onChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Phone</FormLabel>
                                    <Input
                                        name="phone"
                                        size="sm"
                                        startDecorator={<PhoneRoundedIcon/>}
                                        onChange={onChange}
                                        value={formData?.phone || ""}
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Stack>
                    <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
                        <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                            <Button size="sm" variant="outlined" color="neutral" onClick={onCancel} loading={isLoading}>
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                size="sm"
                                variant="solid"
                                disabled={isEqual(formData, initialForm)}
                                loading={isLoading}
                            >
                                Save
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </form>
        </Stack>
    );
};
