import * as React from 'react';
import {Chip} from "@mui/joy";

type SignalProps = {
    number?: number
};

export const Signal = ({number}: SignalProps): any => {
    if (!number) {
        return <Chip color={"neutral"} size={"sm"}>{'N/A'}</Chip>
    }

    const percentage = Math.round(100 / number);

    if (percentage >= 80) {
        return <Chip color={"success"}  size={"sm"}>{percentage}%</Chip>
    }

    if (percentage >= 40 && percentage < 80) {
        return <Chip color={"warning"}  size={"sm"}>{percentage}%</Chip>
    }

    return <Chip color={"danger"} size={"sm"}>{percentage}%</Chip>
}

export default Signal;

