import * as React from 'react';
import {Box} from '@mui/joy';

type StickyHeaderProps = {
    children?: any
};

const StickyHeader = ({children}: StickyHeaderProps) => {

    return (
        <Box
            sx={{
                position: 'sticky',
                top: -110,
                bgcolor: 'background.body',
                zIndex: 10,
            }}
        >
            {children}
        </Box>
    );
}

export default StickyHeader;