import {
    useQuery,
} from 'react-query'
import { createQueryKeys, inferQueryKeys } from "@lukemorales/query-key-factory";
import usersApi from "../api/users";

const usersQK = createQueryKeys("users",{
    list: (params?: GetListParams) => [ params ?? {} ],
    one: (id: number | string) => [ id ],
    roles: null,
});

type GetListParams = {
    filters?: any;
    page: number;
    limit: number;
}

export type UsersQK = inferQueryKeys<typeof usersQK>;

export const useGetAllUsers = (params: GetListParams) => {
    return useQuery({
        queryKey: usersQK.list(params).queryKey,
        queryFn: () => usersApi
            .usersList(params.page, params.limit)
            .then((response) => response.data),
    });
};

export const useGetOneUser = (id: string | number) => {
    return useQuery({
        queryKey: usersQK.one(id).queryKey,
        queryFn: () => usersApi
            .view(id)
            .then((response) => response.data),
    });
};

export const useGetRoles = () => {
    return useQuery({
        queryKey: usersQK.roles.queryKey,
        queryFn: () => usersApi
            .rolesList()
            .then((response) => response.data),
    });
};
