import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import {ChangeEvent, useState, useContext} from "react";
import {CameraStorageType} from "../../../components/types";
import Select from "../../../components/form/Select";
import {AppContext} from "../../../components/app-context";
import ApiCamera from "../../../api/cameras";
import {handleAxiosError} from "../../../components/utils";

type ChangeStorageModalProps = {
    open: boolean,
    onClose: any,
    successCallback: any,
    cameraId: number,
    storages: CameraStorageType[],
    initialStorage: CameraStorageType,
}

export const ChangeStorageModal = ({open, onClose, successCallback, storages, initialStorage, cameraId}: ChangeStorageModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [storageId, setStorageId] = useState<number>(initialStorage.id);

    const onSubmit = async () => {
        try {
            await ApiCamera.update(cameraId, {storage_id: storageId});
            onNotification('Camera storage successfully changed', 'success');
            successCallback();
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification
            );
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setStorageId(parseInt(value));
    }

    const onModalClose = () => {
        setStorageId(initialStorage.id);
        onClose();
    }

    return (
        <Modal open={open} onClose={onModalClose}>
            <ModalDialog variant="outlined" role="alertdialog" minWidth={500}>
                <DialogTitle>
                    Change Camera Storage
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Select
                        label="Storage"
                        placeholder="Select a storage"
                        name="storage"
                        data={storages.map(storage => ({
                            value: storage.id,
                            label: storage.label,
                        }))}
                        onChange={onChange}
                        value={storageId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="solid"
                        color="primary"
                        onClick={onSubmit}
                        disabled={storageId === initialStorage.id}
                    >
                        Change
                    </Button>
                    <Button variant="plain" color="neutral" onClick={onModalClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default ChangeStorageModal;