import * as React from 'react';
import {CircularProgress} from '@mui/joy';
import Box from '@mui/joy/Box'


const Loader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}
        >
            <CircularProgress />
        </Box>
    );
}

export default  Loader;