import * as React from 'react';
import {useState, useRef} from "react";
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { styled } from '@mui/joy/styles';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import MenuList from '@mui/joy/MenuList';
import MenuItem from '@mui/joy/MenuItem';
import {CommandType} from "../../../components/types";

type TriggerCommandProps = {
    onClick: any,
    commands: CommandType[]
};

const Popup = styled(Popper)({
    zIndex: 1000,
});

export const TriggerCommand = ({onClick, commands}: TriggerCommandProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    const handleClose = (command?: any) => {
        setOpen(false);

        if (command) {
            onClick(command);
        }
    };

    const handleListKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Tab') {
            setOpen(false);
        } else if (event.key === 'Escape') {
            buttonRef.current!.focus();
            setOpen(false);
        }
    };

    return (
        <>
            <Button
                ref={buttonRef}
                id="composition-button"
                aria-controls={'composition-menu'}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                color="neutral"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                Trigger Command
            </Button>
            <Popup
                role={undefined}
                id="composition-menu"
                open={open}
                anchorEl={buttonRef.current}
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 4],
                        },
                    },
                ]}
            >
                <ClickAwayListener
                    onClickAway={(event) => {
                        if (event.target !== buttonRef.current) {
                            handleClose();
                        }
                    }}
                >
                    <MenuList
                        variant="outlined"
                        onKeyDown={handleListKeyDown}
                        sx={{ boxShadow: 'md' }}
                    >
                        {commands.map(command =>
                            <MenuItem key={command.name} onClick={() => {
                                handleClose(command);
                            }}><Typography level={"body-sm"}>{command.name}</Typography></MenuItem>
                        )}
                    </MenuList>
                </ClickAwayListener>
            </Popup>
        </>
    )
}