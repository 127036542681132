import { useOutletContext, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { getCameraCommandsErrors } from "../../api/cameras";
import { CameraError } from "../../types/camera";
import { getNumberFromSearchParams } from "../../components/utils";
import qs from "qs";
import Pagination from "../../components/pagination";
import { TableHover } from "../../components/table";
import TableSheet from "../../components/table-sheet";
import Box from "@mui/joy/Box";
import * as React from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";

const CameraErrors = () => {
    const { camera } = useOutletContext<{ camera: any }>();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const [ totalPages, setTotalPages ] = useState<number>(1);
    const [ loading, onChangeLoading ] = useState<boolean>(false);
    const [ list, onChangeList ] = useState<CameraError[]>([]);

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    const fetchErrors = useCallback(async () => {
        try {
            onChangeLoading(true);
            const { data } = await getCameraCommandsErrors(camera.id, currentPage, limit);
            setTotalPages(data.pagination.totalPages);
            onChangeLoading(false);
            onChangeList(data.list as CameraError[]);
        } catch (e) {
            onChangeLoading(false);
            console.log(e);
        }
    }, [currentPage, limit, camera ]);

    useEffect(() => {
        fetchErrors();
    }, [ camera.id, searchParams ]);

    return (
        <div>
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{ width: 180 }}>Command</th>
                        <th style={{ width: 300 }}>Error</th>
                        <th style={{ width: 100 }}>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading
                            ? <tr style={{ textAlign: "center" }}>
                                <td colSpan={3}><CircularProgress/></td>
                            </tr>
                            : (
                                list.length > 0 ?
                                    list.map((item, idx) => (
                                        <tr key={`command-${idx}`}>
                                            <td><Typography level="body-xs">{item.command}</Typography></td>
                                            <td><Typography level="body-xs">{item.errorMessage}</Typography></td>
                                            <td><Typography level="body-xs">{item.logDate} {item.logTime}</Typography></td>
                                        </tr>
                                    ))
                                    :
                                    <tr style={{ textAlign: "center" }}>
                                        <td colSpan={3}>No errors found</td>
                                    </tr>
                            )
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </div>
    );
};

export default CameraErrors;
