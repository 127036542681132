import React, {useEffect, useState} from "react";
import { AppContext } from "./app-context";
import ApiProfile from "../api/profile";
import ApiGlossary from "../api/glossary";
import {Notification, NotificationType, User} from "./types";
import {defineAbilityFor} from "./ability";

export const AppProvider = ({children}: any) => {

    const [authenticated, setAuthenticated] = useState(!!window.localStorage.getItem('TOKEN'));
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [notification, setNotification] = useState<Notification>({message: "", type: "success"});
    const [glossary, setGlossary] = useState({});

    useEffect(() => {
        getAppData();
    }, []);

    const token = window.localStorage.getItem('TOKEN');

    const getAppData = async() => {
        if (token) {
            try {
                const userResponse = await ApiProfile.me();
                const glossaryResponse = await ApiGlossary.info();
                setUser(userResponse.data);
                setAuthenticated(true);
                setGlossary(glossaryResponse.data);
                setIsLoading(false);
            } catch (err) {
                setUser(null);
                setAuthenticated(false);
                setIsLoading(false);
            }
        }
        setIsLoading(false);
    }

    const onNotification = (message: string, type: NotificationType = "success") => {
        setNotification({
            message: message,
            type: type
        })
    }

    const ability = defineAbilityFor(user);

    return (
        <AppContext.Provider value={{authenticated, setAuthenticated, user, setUser, notification, onNotification, ability, glossary}}>
            {!isLoading && children}
        </AppContext.Provider>
    )
}