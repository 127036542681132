import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import * as React from "react";
import {useContext} from "react";
import {AppContext} from "../../components/app-context";
import {useNavigate} from "react-router-dom";
import AccountCircle from '@mui/icons-material/AccountCircle';
import {Link} from "react-router-dom";

export const Profile = () => {

    const {user, setUser, setAuthenticated} = useContext(AppContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        setAuthenticated(false);
        setUser(null);
        window.localStorage.removeItem('TOKEN');

        navigate('/login');
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', textDecoration: "none" }} component={Link} to={"/profile"}>
            <AccountCircle />
            <Box sx={{ minWidth: 0, flex: 1}}>
                <Typography level="title-sm">{user?.first_name} {user?.last_name}</Typography>
                <Typography level="body-xs">{user?.email}</Typography>
            </Box>
            <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
                <LogoutRoundedIcon />
            </IconButton>
        </Box>
    );
}