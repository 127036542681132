import {
    useQuery,
} from 'react-query'
import { createQueryKeys, inferQueryKeys } from "@lukemorales/query-key-factory";
import { getGlobalExchangeHistory } from "../api/exchange";

const exchangeQK = createQueryKeys("exchange-history",{
    list: (params?: GetListParams) => [ params ?? {} ],
});

type GetListParams = {
    filters?: any;
    page: number;
    limit: number;
}

export type ExchangeQK = inferQueryKeys<typeof exchangeQK>;

export const useGetExchangeHistory = (params: GetListParams) => {
    return useQuery({
        queryKey: exchangeQK.list(params).queryKey,
        queryFn: () => getGlobalExchangeHistory(params.page, params.limit)
            .then((response) => response.data),
    });
};
