import * as React from 'react';
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

type TextInputProps = {
    label: string,
    placeholder: string,
    name: string,
    error?: string,
    onChange?: any,
    value: any,
    disabled?: boolean,
    type?: string,
    readOnly?: boolean
};

export const TextInput = (props: TextInputProps) => {

    const hasError = !!props.error;
    const type = props.type || "text";
    const readOnly = props.readOnly || false;

    return (
        <FormControl error={hasError}>
            <FormLabel>{props.label}</FormLabel>
            <Input
                disabled={props.disabled || false}
                readOnly={readOnly}
                type={type}
                placeholder={props.placeholder}
                name={props.name}
                onChange={props.onChange}
                value={props.value} />
            {
                hasError &&
                    <FormHelperText>
                        <InfoOutlined />
                        {props.error}
                    </FormHelperText>
            }
        </FormControl>
    );
}

export default TextInput;