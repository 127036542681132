import * as React from 'react';
import ModalWrapper from "../../../components/modal";
import ModalClose from '@mui/joy/ModalClose';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import JSONPretty from 'react-json-pretty';
import {formatDateTime} from "../../../components/utils";
import {SimActivationType} from "../../../components/types";

type SimActivationDetailsProps = {
    open: boolean,
    onClose: () => void,
    activation: SimActivationType,
}

export const SimActivationDetailsModal = ({open, onClose, activation}: SimActivationDetailsProps) => {
    return (
        <ModalWrapper open={open} onClose={onClose}>
            <ModalClose variant="plain" sx={{ m: 1 }} onClick={onClose} />
            <DialogTitle>SIM Activation Details - {formatDateTime(activation.created_at)}</DialogTitle>
            <Divider />
            <DialogContent>
                <JSONPretty
                    data={activation.json}
                    style={{
                        overflow: 'auto',
                    }}
                    theme={{
                        main: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        error: 'line-height:1.3;color:navy;background:rbga(255,255,255,0);overflow:auto;',
                        key: 'color:brown;',
                        string: 'color:olive;',
                        value: 'color:navy;',
                        boolean: 'color:navy;',
                    }}
                ></JSONPretty>
            </DialogContent>
        </ModalWrapper>
    );
}

export default SimActivationDetailsModal;