import * as React from 'react';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Button from '@mui/joy/Button';
import {Stack} from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import {useNavigate} from "react-router-dom";
import {models} from "../../../api/cameras";
import ApiFirmware from "../../../api/firmware";
import ModalWrapper from "../../../components/modal";
import {AppContext} from "../../../components/app-context";
import Dropzone from "../../../components/form/Dropzone";
import Select from "../../../components/form/Select";
import TextInput from "../../../components/form/TextInput";
import {errorsToObject} from "../../../components/utils";
import {handleAxiosError} from "../../../components/utils";
import { useGetCameraModels } from "../../../query/cameras-query";

type ModalProps = {
    open: boolean,
    onClose: any,
    onSuccessCallback: any,
}

type Form = {
    model: string | null,
    type: string | null,
    version: string | null,
    firmware: File | null,
}

export const CreateFirmware = ({open, onClose, onSuccessCallback}: ModalProps) => {
    const {onNotification, glossary} = useContext(AppContext);

    const initialFormData: Form = {
        model: null,
        type: null,
        version: null,
        firmware: null,
    };
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState<Form>(initialFormData);
    const [errors, setErrors] = useState<any>({});
    const { data } = useGetCameraModels();
    const cameraModels = data?.list ?? [];

    const setInitialState = () => {
        setFormData(initialFormData);
        setErrors({});
    }

    const onModalClose = () => {
        setInitialState();
        onClose();
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onDropzoneChange = (name: string, files: File[]) => {
        setFormData({
            ...formData,
            [name]: files ? files[0] : null,
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await ApiFirmware.create({
                firmware: formData.firmware,
                info: {
                    type: formData.type,
                    model: formData.model,
                    version: formData.version,
                    filename: formData.firmware?.name || ''
                }
            });
            onNotification("Firmware successfully created");
            setInitialState();
            onSuccessCallback();
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    }

    return (
        <ModalWrapper open={open} onClose={onModalClose}>
            <DialogTitle>Create new firmware</DialogTitle>
            <DialogContent>Fill in the information about the firmware.</DialogContent>
            <form onSubmit={onSubmit}>
                <Stack spacing={2}>
                    <Select
                        label="Model"
                        placeholder="Select a model"
                        name="model"
                        error={errors['model']}
                        data={cameraModels.map(model => ({
                            value: model.code,
                            label: model.label,
                        }))}
                        onChange={onChange}
                        value={formData.model}
                    />
                    <TextInput
                        label="Version"
                        placeholder="Enter version"
                        name="version"
                        error={errors['version']}
                        onChange={onChange}
                        value={formData.version}
                    />
                    <Select
                        label="Type"
                        placeholder="Select a type"
                        name="type"
                        error={errors['type']}
                        data={glossary?.firmware?.types.map((type: any) => ({
                            value: type.name,
                            label: type.label,
                        }))}
                        onChange={onChange}
                        value={formData.type}
                    />
                    <Dropzone
                        label="Firmware"
                        text="Drag 'n' drop file here, or click to select file"
                        files={formData.firmware ? [formData.firmware] : []}
                        onChange={(files: File[]) => onDropzoneChange('firmware', files)}
                        maxFiles={1}
                        error={errors['firmware']}
                    />
                    <Button
                        type="submit"
                        loading={isLoading}
                    >
                        Create
                    </Button>
                    <Button color={"neutral"} onClick={onModalClose}>Close</Button>
                </Stack>
            </form>
        </ModalWrapper>
    );
}

export default CreateFirmware;
