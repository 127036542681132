import {
    useQuery,
} from 'react-query'
import { createQueryKeys, inferQueryKeys } from "@lukemorales/query-key-factory";
import camerasApi from "../api/cameras";
import accountsApi from "../api/account-media";

const mediaQK = createQueryKeys("media",{
    one: (cameraId: number, id: number) => [ {cameraId, id} ],
    accountOne: (accountId: number, id: number) => [ {accountId, id} ],
});

export type MediaQK = inferQueryKeys<typeof mediaQK>;

export const useGetOneMedia = (cameraId: number, id: number) => {
    return useQuery({
        queryKey: mediaQK.one(cameraId, id).queryKey,
        queryFn: () => camerasApi
            .getCameraOneMedia(cameraId, id)
            .then((response) => response.data),
    });
};
export const useGetOneAccountMedia = (accountId: number, id: number) => {
    return useQuery({
        queryKey: mediaQK.one(accountId, id).queryKey,
        queryFn: () => accountsApi
            .one(accountId, id)
            .then((response) => response.data),
    });
};
