import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {useContext, useState} from "react";
import {AppContext} from "../../../components/app-context";
import ApiFirmware from "../../../api/firmware";
import {FirmwareType} from "../../../components/types";
import {handleAxiosError} from "../../../components/utils";

type ModalWrapperProps = {
    open: boolean,
    onClose: any,
    firmware: FirmwareType | null,
    onSuccessCallback: any
}

export const DeleteFirmware = ({open, onClose, onSuccessCallback, firmware}: ModalWrapperProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    const onConfirmClick = async () => {
        if (firmware) {
            setIsLoading(true);
            try {
                await ApiFirmware.remove(firmware.id);
                onSuccessCallback();
                onNotification("firmware successfully deleted", "success");
            } catch (error: any) {
                handleAxiosError(error, onNotification);
            }
            setIsLoading(false);
        }
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon />
                    Confirm firmware delete
                </DialogTitle>
                <Divider />
                <DialogContent>
                    Are you sure that you want delete firmware {firmware?.filename}?
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="danger" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default DeleteFirmware;