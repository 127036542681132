import React, {useContext, useState, useEffect} from 'react';
import {AppContext} from "./app-context";
import Snackbar from '@mui/joy/Snackbar';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from "@mui/joy/IconButton";

export const NotificationBar = () => {

    const {notification} = useContext(AppContext);
    const {message, type} = notification;
    const [open, setOpen] = useState(true);

    const onClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        setOpen(true);
    }, [notification])

    const isWarning = type === "danger" || type === "warning";

    return (
        <Snackbar
            variant="soft"
            color={type}
            autoHideDuration={3000}
            open={Boolean(message && message.length > 0) && open}
            onClose={onClose}
            size={"md"}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            startDecorator={isWarning ? <WarningRoundedIcon /> : <InfoRoundedIcon/>}
            endDecorator={
                <IconButton
                    onClick={onClose}
                    size="sm"
                    variant="plain"
                    color={type}
                >
                    <CloseRoundedIcon/>
                </IconButton>
            }
        >
            {message}
        </Snackbar>
    );
}

export default NotificationBar;