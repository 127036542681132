import React, { useMemo } from "react";
import PageHeader from "../../components/page-header";
import Box from "@mui/joy/Box";
import Graph, { Item, PieGraph } from "../../components/graph";
import moment from "moment";
import { groupBy } from "lodash";
import Grid from "@mui/joy/Grid";
import {
    useGetAccountDashboard,
    useGetCamerasDashboard,
    useGetMediaByCameraDashboard, useGetMediaDashboard
} from "../../query/dashboard-query";

const emptyDashboardItem = {
    title: "",
    labels: {
        axis: {
            x: "",
            y: "",
        }
    },
    label: {
        x: "",
        y: "",
    },
    data: []
};
const emptyDashboardMediaByModelItem = {
    title: "",
    labels: {
        axis: {
            x: "",
            y: "",
        }
    },
    label: {
        x: "",
        y: "",
    },
    data: { }
};

const DashboardAccounts = () => {
    const { data, isLoading } = useGetAccountDashboard();

    const accounts = data ?? emptyDashboardItem;

    const list = accounts.data.map((item) => ({
        label: item.label,
        count: item.value
    }));

    return (
        <Grid lg={6} md={12} xs={12}>
            <Graph loading={isLoading} title={accounts.title} axis={accounts.labels.axis} data={list}/>
        </Grid>
    )
}

const DashboardCameras = () => {
    const { data, isLoading } = useGetCamerasDashboard();

    const cameras = data ?? emptyDashboardItem;

    const list = cameras.data.map((item) => ({
        label: moment(item.label).format("MM/DD"),
        count: item.value
    }));

    return (
        <Grid lg={6} md={12} xs={12}>
            <Graph loading={isLoading} title={cameras.title} axis={cameras.labels.axis} data={list}/>
        </Grid>
    )
}

const DashboardMediaByCamera = () => {
    const { data, isLoading } = useGetMediaByCameraDashboard();

    const mediaByCameraModel = data ?? emptyDashboardMediaByModelItem;
    const list = useMemo(() => {
        const arr = Object.keys(mediaByCameraModel.data).reduce((result, key) => {
            //@ts-ignore
            const modelData = Object.keys(mediaByCameraModel.data[key] as Record<string, number>).map((label) => ({
                label,
                //@ts-ignore
                count: mediaByCameraModel.data[key][label],
                model: key
            }));
            return [
                ...result,
                ...modelData
            ]
        }, [] as Item[]);
        const groupedByLabel = groupBy(arr, "label");
        return Object.values(groupedByLabel).reduce((result, item) => {
            const newItem = item.reduce((result, di) => ({
                ...result,
                label: di.label,
                [di.model]: di.count
            }), {});
            return [...result, newItem];
        }, []);
    }, [mediaByCameraModel]);

    const fields = Object.keys(mediaByCameraModel.data);

    return (
        <Grid lg={6} md={12} xs={12}>
            <Graph loading={isLoading} fields={fields} title={mediaByCameraModel.title} axis={mediaByCameraModel.label} data={list}/>
        </Grid>
    )
}

const DashboardMedia = () => {
    const { data, isLoading } = useGetMediaDashboard();

    const medias = data ?? emptyDashboardItem;

    const list = medias.data.map((item) => ({
        label: item.label,
        count: item.value
    }));

    return (
        <Grid lg={6} md={12} xs={12}>
            <PieGraph loading={isLoading} title={medias.title} axis={medias.label} data={list}/>
        </Grid>
    )
}

export const Dashboard = () => {
    return (
        <>
            <PageHeader title={"Dashboard"}/>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: { xs: 2, md: 6 },
                py: { md: 3 },
            }}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <DashboardAccounts/>
                    <DashboardCameras/>
                    <DashboardMedia/>
                    <DashboardMediaByCamera/>
                </Grid>
            </Box>
        </>
    );
};
