import * as React from 'react';
import {Chip} from "@mui/joy";

type CommandStatusProps = {
    status: string
};

export const CommandStatus = ({status}: CommandStatusProps): any => {

    const lower = status.toLowerCase();

    if (lower === 'pending') {
        return <Chip color={"neutral"} size={"sm"}>{status}</Chip>
    }

    if (lower === 'cancelled') {
        return <Chip color={"danger"} size={"sm"}>{status}</Chip>
    }

    if (lower === 'failed') {
        return <Chip color={"danger"} size={"sm"}>{status}</Chip>
    }

    return <Chip color={"success"} size={"sm"}>{status}</Chip>
}

export default CommandStatus;

