import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {useState} from "react";
import ApiAccounts from "../../../api/accounts";

type ModalWrapperProps = {
    open: boolean,
    onClose: any,
    account: any,
    onSuccessCallback: any
}

export const DeactivateAccount = ({open, onClose, onSuccessCallback, account}: ModalWrapperProps) => {

    const [isLoading, setIsLoading] = useState(false);

    const onConfirmClick = async () => {
        setIsLoading(true);
        try {
            //await ApiAccounts.remove(account.id);
        } catch (err) {

        }
        onSuccessCallback();
        setIsLoading(false);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon />
                    Confirm account deactivate
                </DialogTitle>
                <Divider />
                <DialogContent>
                    Are you sure that you want deactivate account {account?.first_name} {account?.last_name}?
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="danger" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default DeactivateAccount;