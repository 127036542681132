import * as React from 'react';
import {useOutletContext} from "react-router-dom";
import Box from "@mui/joy/Box";
import TableSheet from "../../components/table-sheet";
import {IconButton, Chip} from "@mui/joy";
import OpenInNew from '@mui/icons-material/OpenInNew';
import {useEffect, useState, useContext} from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import {TableHover} from "../../components/table";
import {findCameraEventsLog} from "../../api/cameras";
import ViewEventLogDataModal from "./components/view-event-log-data-modal";
import {EventLogType} from "../../components/types";
import {formatDateTime} from "../../components/utils";
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";
import {useSearchParams} from "react-router-dom";
import {getNumberFromSearchParams} from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";

type ShowEventDataType = {
    show: boolean,
    eventIndex: number | null,
}

export const EventsLog = () => {

    const {onNotification} = useContext(AppContext);
    const {camera} = useOutletContext<{camera: any}>();
    const [events, setEvents] = useState<EventLogType[]>([]);
    const [isLoading, setIsLoading] = useState(true);


    const [showEventData, setShowEventData] = useState<ShowEventDataType>({
        show: false,
        eventIndex: null,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, 'page', 1);
    const limit = getNumberFromSearchParams(searchParams, 'limit', 10);
    const [totalPages, setTotalPages] = useState<number>(1);

    const handlePaginationChange = (page: number, perPage: number)  => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    }

    useEffect(() => {
        fetchData(camera.id, currentPage, limit);
    }, [camera.id, searchParams]);

    const fetchData = async (id: number, page: number, limit: number) => {
        try {
            const {data} = await findCameraEventsLog(id, page, limit);
            setEvents(data.list);
            setTotalPages(data.pagination.totalPages);
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const onShowEventDataClick = (eventIndex: number) => {
        setShowEventData({show: true, eventIndex});
    }

    const onEventDataClose = () => {
        setShowEventData({show: false, eventIndex: null});
    }

    const onNext = (): (() => void) | null => {
        if (showEventData.eventIndex !== null) {
            const nextIndex = showEventData.eventIndex + 1;
            if (events[nextIndex]) {
                return () => onShowEventDataClick(nextIndex);
            }
        }
        return null;
    }

    const onPrev = (): (() => void) | null => {
        if (showEventData.eventIndex !== null) {
            const prevIndex = showEventData.eventIndex - 1;
            if (events[prevIndex]) {
                return () => onShowEventDataClick(prevIndex);
            }
        }
        return null;
    }

    return (
        <>
            {
                showEventData.show && showEventData.eventIndex !== null
                && (
                    <ViewEventLogDataModal
                        open={showEventData.show}
                        onClose={onEventDataClose}
                        eventLog={events[showEventData.eventIndex]}
                        onNext={onNext()}
                        onPrev={onPrev()}
                    />
                )
            }
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{width: 150}}>Created At</th>
                        <th style={{width: 200}}>Type</th>
                        <th style={{width: 200}}>Command</th>
                        <th style={{width: 200}}>Source</th>
                        <th style={{width: 80}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        isLoading
                            ? <tr style={{textAlign: "center"}}><td colSpan={4}><CircularProgress /></td></tr>
                            : events.length > 0
                                ? events.map((event: EventLogType, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{formatDateTime(event.created_at)}</td>
                                            <td>{event.event_type}</td>
                                            <td>{event?.event_source || ""}</td>
                                            <td><Chip color={"neutral"}>{event?.command || ""}</Chip></td>
                                            <td>
                                                <IconButton
                                                    size={"sm"}
                                                    onClick={() => onShowEventDataClick(index)}
                                                    color={"primary"}
                                                    variant={"outlined"}
                                                >
                                                    <OpenInNew />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    );

                                })
                                : <tr style={{textAlign: "center"}}><td colSpan={4}>No events found</td></tr>
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
}