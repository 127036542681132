import {
    useQuery,
} from 'react-query'
import { createQueryKeys, inferQueryKeys } from "@lukemorales/query-key-factory";
import * as dashboardApi from "../api/dashboard";
const dashboardQK = createQueryKeys("dashboard",{
    account: null,
    media: null,
    mediaByCamera: null,
    cameras: null,
});

export type DashboardQK = inferQueryKeys<typeof dashboardQK>;

export const useGetAccountDashboard = () => {
    return useQuery({
        queryKey: dashboardQK.account.queryKey,
        queryFn: () => dashboardApi
            .getAccountStats()
            .then((response) => response.data),
    });
};

export const useGetCamerasDashboard = () => {
    return useQuery({
        queryKey: dashboardQK.cameras.queryKey,
        queryFn: () => dashboardApi
            .getCamerasStats()
            .then((response) => response.data),
    });
};

export const useGetMediaByCameraDashboard = () => {
    return useQuery({
        queryKey: dashboardQK.mediaByCamera.queryKey,
        queryFn: () => dashboardApi
            .getMediaByCameraStats()
            .then((response) => response.data),
    });
};

export const useGetMediaDashboard = () => {
    return useQuery({
        queryKey: dashboardQK.media.queryKey,
        queryFn: () => dashboardApi
            .getMediaStats()
            .then((response) => response.data),
    });
};
