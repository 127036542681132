import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {useContext, useState} from "react";
import {cameraMarkAsDeleted} from "../../../api/cameras";
import {AppContext} from "../../../components/app-context";
import {handleAxiosError} from "../../../components/utils";
import Typography from "@mui/joy/Typography";

type ModalWrapperProps = {
    open: boolean;
    onClose: () => void;
    camera: any;
    onSuccessCallback: any;
    warnings: string[];
}

export const DeleteSoftCamera = ({open, warnings = [], onClose, onSuccessCallback, camera}: ModalWrapperProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    const onConfirmClick = async () => {
        setIsLoading(true);
        try {
            await cameraMarkAsDeleted(camera.id);
            onSuccessCallback(true);
            onNotification("Camera successfully marked as deleted", "warning");
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
        setIsLoading(false);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon />
                    Confirm mark camera as delete
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {warnings.map((warning, idx) => (
                        <Typography
                            variant="soft"
                            color="danger"
                            sx={{ padding: "0.5rem 1rem", mb: 0.5 }}
                            startDecorator="🚨"
                            key={idx}
                        >
                            {warning}
                        </Typography>
                    ))}
                    <Typography>Are you sure that you want mark camera {camera?.imei} as deleted?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="danger" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default DeleteSoftCamera;
