import * as React from 'react';
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import {TableHover} from "../../components/table";
import TableSheet from "../../components/table-sheet";
import CircularProgress from "@mui/joy/CircularProgress";
import {IconButton} from "@mui/joy";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import {useOutletContext} from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import {formatDateTime} from "../../components/utils";
import CommandStatus from "../../components/camera/command-status";
import {TriggerCommand} from "./components/trigger-command";
import TriggerCommandModal from "./components/trigger-command-modal";
import CancelCommandModal from "./components/cancel-command-modal";
import ApiCameraCommands from "../../api/camera-command";
import {CommandType} from "../../components/types";
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";
import Box from '@mui/joy/Box';
import {useSearchParams} from "react-router-dom";
import {getNumberFromSearchParams} from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";

export const Commands = () => {
    const {onNotification} = useContext(AppContext);
    const {camera} = useOutletContext<{camera: any}>();
    const [commands, setCommands] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [supportedCommands, setSupportedCommands] = useState<CommandType[]>([]);
    const [triggerCommand, setTriggerCommand] = useState<any>({
        show: false,
        command: null
    });

    const [cancelCommand, setCancelCommand] = useState<any>({
        show: false,
        command: null
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, 'page', 1);
    const limit = getNumberFromSearchParams(searchParams, 'limit', 10);
    const [totalPages, setTotalPages] = useState<number>(1);

    const handlePaginationChange = (page: number, perPage: number)  => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    }

    useEffect(() => {
        fetchSupportedCommands();
    }, []);

    useEffect(() => {
        fetchCommands(camera.id, currentPage, limit);
    }, [camera.id, searchParams]);

    const fetchSupportedCommands = async () => {
        setIsLoading(true);
        try {
            const {data: supportedCommands} = await ApiCameraCommands.help();
            setSupportedCommands(supportedCommands.list.filter((command: any) => command.name !== 'UpdateFirmware'));
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const fetchCommands = async (id: number, page: number, limit: number) => {
        setIsLoading(true);
        try {
            const {data} = await ApiCameraCommands.commands(id, page, limit);
            setCommands(data.list);
            setTotalPages(data.pagination.totalPages);
            setIsLoading(false);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    }

    const successTrigger = async () => {
        const {data} = await ApiCameraCommands.commands(camera.id, currentPage, limit);
        setCommands(data.list);
    }

    const onCommandTriggerClick = (command: any) => {
        setTriggerCommand({show: true, command: command});
    }

    const onCommandTriggerClose = () => {
        setTriggerCommand({show: false, command: null});
    }

    const onCancelCommand = (command: any) => {
        setCancelCommand({show: true, command: command});
    }

    const onCancelCommandClose = () => {
        setCancelCommand({show: false, command: null});
    }

    return (
        <>
            <TriggerCommandModal cameraId={camera.id} open={triggerCommand.show} onClose={onCommandTriggerClose} command={triggerCommand.command} successCallback={successTrigger}/>
            <CancelCommandModal cameraId={camera.id} open={cancelCommand.show} onClose={onCancelCommandClose} command={cancelCommand.command} successCallback={successTrigger}/>
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
                <TriggerCommand onClick={onCommandTriggerClick} commands={supportedCommands}/>
            </Stack>
            <TableSheet>
                    <TableHover>
                        <thead>
                        <tr>
                            <th style={{width: 180}}>Command</th>
                            <th style={{width: 100}}>RequestID</th>
                            <th style={{width: 150}}>Status</th>
                            <th style={{width: 180}}>Created</th>
                            <th style={{width: 180}}>Accepted</th>
                            <th style={{width: 150}}>Completed</th>
                            <th style={{width: 150}}>FailReason</th>
                            <th style={{width: 150}}>Media</th>
                            <th style={{width: 80}}/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            isLoading
                                ? <tr style={{textAlign: "center"}}><td colSpan={9}><CircularProgress /></td></tr>
                                : commands.length > 0
                                    ? commands.map((command: any, index) => {
                                        return (
                                            <tr key={`command-${index}`}>
                                                <td><Typography level="body-xs">{command?.request_info?.label}</Typography></td>
                                                <td><Typography level="body-xs">{command?.request_id}</Typography></td>
                                                <td>
                                                    {command.status &&  <CommandStatus status={command.status}/>}
                                                </td>
                                                <td><Typography level="body-xs">{formatDateTime(command.created_at)}</Typography></td>
                                                <td><Typography level="body-xs">{command?.accepted_at ? formatDateTime(command.accepted_at) : ""}</Typography></td>
                                                <td><Typography level="body-xs">{command?.completed_at ? formatDateTime(command.completed_at) : ""}</Typography></td>
                                                <td><Typography level="body-xs">{command?.fail_reason || ""}</Typography></td>
                                                <td></td>
                                                <td>
                                                    {command.status.toLowerCase() !== 'cancelled' && (
                                                        <IconButton
                                                            onClick={() => {
                                                                onCancelCommand(command);
                                                            }}
                                                            color="neutral"
                                                            size="sm"
                                                            title="Cancel"
                                                            variant="outlined"
                                                        >
                                                            <CancelIcon />
                                                        </IconButton>
                                                    )}
                                                </td>
                                            </tr>
                                        );

                                    })
                                    : <tr style={{textAlign: "center"}}><td colSpan={9}>No commands found</td></tr>
                        }

                        </tbody>
                    </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
         </>
    )
}
