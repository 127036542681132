import * as React from 'react';
import {Outlet} from "react-router-dom";
import {useContext} from "react";
import PageHeader from "../../components/page-header";
import {PageNavTab} from "../../components/page-nav-tab";
import StickyHeader from "../../components/sticky-header";
import {AppContext} from "../../components/app-context";

export const ProfileIndex = () => {
    const {user} = useContext(AppContext);

    return (
        <>
            <StickyHeader>
                <PageHeader title={`Profile`} breadCrumbs={[{name: `Profile`}]}/>
                <PageNavTab list={[
                    {name: "General", to: `/profile`},
                    {name: "Password", to: `/profile/password`}
                ]}/>
            </StickyHeader>
            <Outlet context={{user: user}}/>
        </>
    );
}