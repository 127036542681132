import * as React from 'react';
import {useOutletContext} from "react-router-dom";
import {Button, Box} from '@mui/joy';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Snackbar from '@mui/joy/Snackbar';
import {ChangeEvent, useState, useContext} from "react";
import PasswordInput from "../../components/form/PasswordInput";
import ApiProfile from "../../api/profile";
import {errorsToObject} from "../../components/utils";
import PasswordRoundedIcon from "@mui/icons-material/PasswordRounded";
import {AppContext} from "../../components/app-context";
import {handleAxiosError} from "../../components/utils";

type Form = {
    old_password?: string,
    new_password?: string,
    repeat_password?: string,
};

const initialForm = {
    old_password: "",
    new_password: "",
    repeat_password: "",
}

export const Password = () => {

    const {onNotification} = useContext(AppContext);
    const {user} = useOutletContext<{ user: any }>();
    const [formData, setFormData] = useState<Form>(initialForm);
    const [errors, setErrors] = useState<any>({});
    const [showNotification, setShowNotification] = useState(false);
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await ApiProfile.changePassword({passwords: formData});
            setFormData(initialForm);
            setShowNotification(true);
            setText("Password successfully changed");
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }
        setIsLoading(false);
    }

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
            <Stack
                spacing={4}
                sx={{
                    display: 'flex',
                    maxWidth: '800px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                }}
            >
                <Snackbar
                    variant="soft"
                    color="success"
                    autoHideDuration={10000}
                    open={showNotification}
                    onClose={() => setShowNotification(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    startDecorator={<PasswordRoundedIcon />}
                    endDecorator={
                        <Button
                            onClick={() => setShowNotification(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {text}
                </Snackbar>
                <form onSubmit={onSubmit}>
                    <Card>
                        <Box sx={{ mb: 1 }}>
                            <Typography level="title-md">Change Password</Typography>
                        </Box>
                        <Divider />

                        <Stack
                            direction="row"
                            spacing={3}
                            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
                        >
                            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                                <Stack spacing={1}>
                                    <PasswordInput error={errors['old_password'] || ""} label={"Old Password"} placeholder={"Enter current password"} name={"old_password"} onChange={onChange} value={formData.old_password}/>
                                    <PasswordInput error={errors['new_password'] || ""} label={"New Password"} placeholder={"Enter new password"} name={"new_password"} onChange={onChange} value={formData.new_password}/>
                                    <PasswordInput error={errors['repeat_password'] || ""} label={"Password Repeat"} placeholder={"Enter current password"} name={"repeat_password"} onChange={onChange} value={formData.repeat_password}/>
                                </Stack>
                            </Stack>
                        </Stack>
                        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                <Button size="sm" loading={isLoading} variant="solid" type={"submit"}>
                                    Save
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>
                </form>
            </Stack>
        </Box>
    );
}