import { useOutletContext, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { getCameraMediaErrorLink, getCameraMediaErrors } from "../../api/cameras";
import { CameraMediaError } from "../../types/camera";
import { formatDateTime, getNumberFromSearchParams } from "../../components/utils";
import qs from "qs";
import Pagination from "../../components/pagination";
import { TableHover } from "../../components/table";
import TableSheet from "../../components/table-sheet";
import Download from "@mui/icons-material/Download"
import Box from "@mui/joy/Box";
import * as React from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";

const CameraMediaErrors = () => {
    const { camera } = useOutletContext<{ camera: any }>();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const [ totalPages, setTotalPages ] = useState<number>(1);
    const [ loading, onChangeLoading ] = useState<boolean>(false);
    const [ list, onChangeList ] = useState<CameraMediaError[]>([]);

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    const fetchErrors = useCallback(async () => {
        try {
            onChangeLoading(true);
            const { data } = await getCameraMediaErrors(camera.id, currentPage, limit);
            setTotalPages(data.pagination.totalPages);
            onChangeLoading(false);
            onChangeList(data.list as CameraMediaError[]);
        } catch (e) {
            onChangeLoading(false);
            console.log(e);
        }
    }, [currentPage, limit, camera ]);

    useEffect(() => {
        fetchErrors();
    }, [ camera.id, searchParams ]);

    const handleDownloadFile = useCallback(async (id: number, name: string) => {
        try {
            const { data } = await getCameraMediaErrorLink(camera.id, id);
            const a = document.createElement("a");
            a.href = data.link;
            a.download = name;
            a.setAttribute("style", "display: none;")
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            console.log(data);
        } catch (e) {
            console.log(e);
        }
    }, [camera]);

    return (
        <div>
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{ width: 120 }}>Command</th>
                        <th style={{ width: 100 }}>Error reason</th>
                        <th style={{ width: 300 }}>Error message</th>
                        <th style={{ width: 100 }}>Date</th>
                        <th style={{ width: 100 }}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading
                            ? <tr style={{ textAlign: "center" }}>
                                <td colSpan={5}><CircularProgress/></td>
                            </tr>
                            : (
                                list.length > 0 ?
                                    list.map((item, idx) => (
                                        <tr key={`command-${idx}`}>
                                            <td><Typography level="body-xs">{item.command}</Typography></td>
                                            <td><Typography level="body-xs">{item.failReason}</Typography></td>
                                            <td><Typography level="body-xs">{item.failMessage}</Typography></td>
                                            <td><Typography level="body-xs">{formatDateTime(item.timestamp)}</Typography></td>
                                            <td style={{ textAlign: "center" }}>
                                                {item.file.canBeDownloaded
                                                    ? <Button onClick={() => handleDownloadFile(item.id, item.file.name)}><Download/></Button>
                                                    : null}
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr style={{ textAlign: "center" }}>
                                        <td colSpan={5}>No media errors found</td>
                                    </tr>
                            )
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </div>
    );
};

export default CameraMediaErrors;
