import * as React from 'react';
import {Chip} from "@mui/joy";

type BatteryProps = {
    number?: number
};

export const Battery = ({number}: BatteryProps): any => {
    if (!number) {
        return <Chip color={"neutral"} size={"sm"}>{'N/A'}</Chip>
    }

    if (number >= 80) {
        return <Chip color={"success"} size={"sm"}>{number}%</Chip>
    }

    if (number >= 40 && number < 80) {
        return <Chip color={"warning"} size={"sm"}>{number}%</Chip>
    }

    return <Chip color={"danger"} size={"sm"}>{number}%</Chip>
}

export default Battery;

