import React, { useContext, useState } from "react";
import ListPage from "../../components/listPage";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, Button, IconButton, Chip, ColorPaletteProp, Box, Typography, Avatar } from "@mui/joy";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import CreateUser from "./components/create";
import DeleteUser from "./components/delete";
import { AppContext } from "../../components/app-context";
import { useSearchParams } from "react-router-dom";
import { getNumberFromSearchParams } from "../../components/utils";
import qs from "qs";
import { useGetAllUsers } from "../../query/users-query";

function chipStatusColor(status: string): string {

    if (status === "deleted") {
        return "error";
    }

    if (status === "inactive") {
        return "warning";
    }

    return "success";
}

export const Users = () => {
    const navigate = useNavigate();
    const [ showCreateUser, setShowCreateUser ] = useState(false);
    const { user } = useContext(AppContext);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, isLoading, refetch } = useGetAllUsers({
        page: currentPage,
        limit
    });

    const users = data?.list ?? [];
    const totalPages = data?.pagination?.totalPages ?? 1;

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    const [ deleteUser, setDeleteUser ] = useState<any>({
        show: false,
        user: null
    });

    const onDeleteUserClick = (user: any) => {
        setDeleteUser({ show: true, user: user });
    };

    const deleteUserSuccessCallback = async () => {
        await refetch();
        setDeleteUser({ show: false, user: null });
    };

    const onDeleteAccountClose = () => {
        setDeleteUser({ show: false, user: null });
    };

    const onCreateSuccessCallback = async () => {
        await refetch();
        setShowCreateUser(false);
    };

    const profileId = user?.id;

    const headers = [
        { width: 250, label: "Name" },
        { width: 140, label: "Phone" },
        { width: 140, label: "Status" },
        { width: 140, label: "Role" },
        { width: 150, label: "Created At" },
        { width: 80, label: "" },
    ];

    return (
        <>
            <DeleteUser open={deleteUser.show} onClose={onDeleteAccountClose} user={deleteUser.user}
                        onSuccessCallback={deleteUserSuccessCallback}/>
            <CreateUser open={showCreateUser} successCallback={onCreateSuccessCallback} onClose={() => {
                setShowCreateUser(false);
            }}/>
            <ListPage
                title="Manage Users"
                headers={headers}
                loading={isLoading}
                pagination={{
                    count: totalPages,
                    page: currentPage,
                    onChange: handlePaginationChange,
                    limit: limit,
                }}
                empty={users.length < 1}
                breadcrumbs={[ { name: "Users" } ]}
                actions={(
                    <Button color="primary" size="sm" onClick={() => {
                        setShowCreateUser(true);
                    }}>
                        <PersonAddRoundedIcon/>
                        Create
                    </Button>
                )}
            >
                {users.map((user) => {
                    const chipColor = chipStatusColor(user.status) as ColorPaletteProp;

                    const initials = (user?.first_name.substring(0, 1) + user?.last_name.substring(0, 1)).toUpperCase();

                    return (
                        <tr key={user.id}
                            onClick={() => profileId !== user.id && navigate(`/user/${user.id}`)}>
                            <td>
                                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                    <Avatar size="sm">{initials}</Avatar>
                                    <div>
                                        <Typography
                                            level="body-xs">{user?.first_name} {user?.last_name}</Typography>
                                        <Typography level="body-xs">{user?.email}</Typography>
                                    </div>
                                </Box>
                            </td>
                            <td>
                                <Typography level="body-xs">{user?.phone}</Typography>
                            </td>
                            <td>
                                <Chip color={chipColor} size={"sm"}>{user.status}</Chip>
                            </td>
                            <td>
                                <Typography level="body-xs">{user?.role?.name}</Typography>
                            </td>
                            <td>
                                <Typography level="body-xs">{user?.created}</Typography>
                            </td>
                            <td
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {
                                    profileId !== user.id && (
                                        <ButtonGroup
                                            color="neutral"
                                            size="sm"
                                            variant="outlined"
                                        >
                                            <IconButton variant={"outlined"} color={"primary"}
                                                        to={`/user/${user.id}`}
                                                        component={Link}><EditRoundedIcon
                                                fontSize={"small"}/></IconButton>
                                            <IconButton variant={"outlined"} color={"danger"} size={"sm"}
                                                        onClick={() => {
                                                            onDeleteUserClick(user);
                                                        }}><DeleteIcon/></IconButton>
                                        </ButtonGroup>
                                    )
                                }

                            </td>
                        </tr>
                    );
                })}
            </ListPage>
        </>
    );
};
