import * as React from 'react';
import {useState} from "react";
import Typography from '@mui/joy/Typography';
import usePagination from '@mui/material/usePagination';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {styled} from '@mui/material/styles';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Box from '@mui/joy/Box';

export type PaginationProps = {
    count: number,
    page: number,
    limit: number,
    onChange: (page: number, perPage: number) => void,
}

type PerPageSelectProps = {
    value: number,
    onChange: (value: number) => void,
};

const PerPageSelect = ({value, onChange}: PerPageSelectProps) => {
    const options: string[] = ['5','10', '25', '50'];
    let perPageValue: string = value.toString();

    if (!options.includes(perPageValue)) {
        perPageValue = options[0];
    }

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {
        onChange(Number(newValue));
    };

    return (
        <Select value={perPageValue} onChange={handleChange}>
            {options.map(option => {
                return (
                    <Option value={option} key={option}>{option}</Option>
                )
            })}
        </Select>
    );
}

const List = styled('ul')({
	listStyle: 'none',
	padding: 0,
	margin: 0,
	display: 'flex',
	columnGap: 6,
	alignItems: 'center',
})

const Pagination = ({count, page, limit, onChange}: PaginationProps) => {

    const [ perPage, setPerPage ] = useState<number>(limit);

    const usePaginationResult = usePagination({
        count,
        page,
        onChange: (event:  React.ChangeEvent<unknown>, page: number) => {
            onChange(page, perPage);
        }
    });

    const handlePerPageChange = (value: number) => {
        setPerPage(value);
        onChange(1, value);
    }

    if (count < 1) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
           }}
        >
            <List>
                {usePaginationResult.items.map(({page, type, selected, ...item }, index) => {
                    let children = null;

                    const arrowSize = 12;

                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = (
                            <div style={{padding: 12}}>…</div>
                        );
                    } else if (type === 'page') {
                        item.disabled = selected;
                        children = (
                            <Button
                                color="neutral"
                                variant={selected ? 'solid' : 'outlined'}
                                {...item}
                            >
                                {page}
                            </Button>
                        );
                    } else if (type === 'previous') {
                        children = (
                            <IconButton variant="outlined" color="neutral" {...item}>
                                <ArrowBackIos sx={{ fontSize: arrowSize }} />
                            </IconButton>
                        );
                    } else if (type === 'next') {
                        children = (
                            <IconButton variant="outlined" color="neutral" {...item}>
                                <ArrowForwardIosIcon sx={{ fontSize: arrowSize }} />
                            </IconButton>
                        );
                    } else {
                        children = (
                            <Button type="button" {...item}>
                                {type}
                            </Button>
                        );
                    }
                    return <li key={index}>{children}</li>;
                })}
            </List>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                }}>
                <Typography level="title-sm">Show</Typography>
                <PerPageSelect
                    value={perPage}
                    onChange={handlePerPageChange}
                />
            </Box>
        </Box>
    );
}

export default Pagination;
