import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {useState, useContext} from "react";
import Typography from "@mui/joy/Typography";
import CameraApi from "../../../api/cameras";
import {AppContext} from "../../../components/app-context";
import {handleAxiosError} from "../../../components/utils";

type ActivateSimModalProps = {
    open: boolean,
    onClose: any,
    successCallback: any,
    camera: any
}

export const ActivateSimModal = ({open, onClose, camera, successCallback}: ActivateSimModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    const onConfirmClick = async () => {
        setIsLoading(true);
        try {
            await CameraApi.activateSim(camera.id);
            successCallback();
            onClose();
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsLoading(false);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <InfoRoundedIcon />
                    Activate Sim Card
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Are you sure that you want activate current sim card "{camera?.iccid}"?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="warning" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default ActivateSimModal;