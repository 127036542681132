import clientv2 from "./api";
import { DashboardMediaByCamerasResponse, DashboardResponse } from "../types/dashboard";

export const getAccountStats = async ():Promise<DashboardResponse> => {
    return await clientv2.get(`/stats/accounts/new?forced=1`);
}

export const getMediaStats = async ():Promise<DashboardResponse> => {
    return await clientv2.get(`/stats/media/incoming-vs-failed?forced=1`);
}

export const getMediaByCameraStats = async ():Promise<DashboardMediaByCamerasResponse> => {
    return await clientv2.get(`/stats/media/by-camera-models?forced=1`);
}

export const getCamerasStats = async ():Promise<DashboardResponse> => {
    return await clientv2.get(`/stats/cameras/new?forced=1`);
}
