import * as React from 'react';
import FormLabel from "@mui/joy/FormLabel";
import Select from "@mui/joy/Select";
import Option from '@mui/joy/Option';
import FormControl from "@mui/joy/FormControl";
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

type ItemData = {
    value: any,
    label: string,
    disabled?: boolean,
}

type SelectProps = {
    label?: string,
    placeholder: string,
    name: string,
    error?: string,
    onChange: any,
    value: any,
    disabled?: boolean,
    data: ItemData[],
};

export default (props: SelectProps) => {

    const hasError = !!props.error;

    return (
        <FormControl error={hasError}>
            {props.label && (
                <FormLabel>{props.label}</FormLabel>
            )}
            <Select
                disabled={props.disabled || false}
                placeholder={props.placeholder}
                name={props.name}
                onChange={(event: any, value: any) => {
                    props.onChange({
                        currentTarget: {
                            name: props.name,
                            value
                        }
                    })
                }}
                value={props.value || null}
            >
                {props.data.map((dataItem, key) =>
                    <Option key={key} value={dataItem.value} disabled={!!dataItem.disabled}>{dataItem.label}</Option>
                )}
            </Select>
            {
                hasError &&
                <FormHelperText>
                  <InfoOutlined />
                    {props.error}
                </FormHelperText>
            }
        </FormControl>
    );
}
