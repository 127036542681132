import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {useState, useContext} from "react";
import Typography from "@mui/joy/Typography";
import ApiCameraCommands from "../../../api/camera-command";
import {AppContext} from "../../../components/app-context";
import {handleAxiosError} from "../../../components/utils";

type CancelCommandModalProps = {
    open: boolean,
    onClose: any,
    command: any,
    successCallback: any,
    cameraId: number
}

export const CancelCommandModal = ({open, onClose, command, successCallback, cameraId}: CancelCommandModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    const onConfirmClick = async () => {
        setIsLoading(true);
        try {
            await ApiCameraCommands.cancel(cameraId, command.id);
            successCallback();
            onClose();
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsLoading(false);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <InfoRoundedIcon />
                    Cancel Command
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Are you sure that you want cancel "{command?.request_info?.label}" command?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="danger" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default CancelCommandModal;