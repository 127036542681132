import * as React from 'react';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Stack} from '@mui/joy';
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import {createCamera, models, storage} from "../../../api/cameras";
import {errorsToObject} from "../../../components/utils";
import {useNavigate} from "react-router-dom";
import ModalWrapper from "../../../components/modal";
import {AppContext} from "../../../components/app-context";
import {handleAxiosError} from "../../../components/utils";
import { useGetCameraModels } from "../../../query/cameras-query";

type ModalProps = {
    open: boolean,
    onClose: any
}

type Form = {
    imei?: string,
    iccid?: string,
    model_id?: number,
    storage_id?: number
}

export const CreateCamera = ({open, onClose}: ModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [sdCards, setSdCards] = useState<any[]>([]);
    const { data } = useGetCameraModels();
    const cameraModels = data?.list ?? [];
    const [formData, setFormData] = useState<Form>({});
    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const responseStorage = await storage();
            setSdCards(responseStorage.data.list);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsLoading(false);
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSelectChange = (name: string, value: number) => {
        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: ""
        });
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const {data} = await createCamera(formData);
            onNotification("Camera successfully created");
            navigate(`/camera/${data.id}`);
        } catch (error: any) {
            handleAxiosError(
                error,
                onNotification,
                (data) => setErrors(errorsToObject(data))
            );
        }

        setIsLoading(false);
    }

    return (
        <ModalWrapper open={open} onClose={onClose}>
            <DialogTitle>Create new camera</DialogTitle>
            <DialogContent>Fill in the information about the camera.
            <form onSubmit={onSubmit}>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>IMEI</FormLabel>
                        <Input disabled={isLoading} error={!!errors['imei']} name={"imei"} onChange={onChange}
                               value={formData?.imei || ""}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>ICCID</FormLabel>
                        <Input disabled={isLoading} error={!!errors['iccid']} name={"iccid"} onChange={onChange}
                               value={formData?.iccid || ""}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Model</FormLabel>
                        <Select
                            placeholder="Select a model"
                            name="model_id"
                            color={!!errors['model_id'] ? "danger" : "neutral"}
                            disabled={isLoading}
                            onChange={(event: any, value: any) => {
                                onSelectChange('model_id', value);
                            }}
                            sx={{minWidth: 200}}
                        >
                            {cameraModels.map((model: any) => {
                                return (
                                    <Option key={model.id} value={model.id}>{model?.label}</Option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>SD Card</FormLabel>
                        <Select
                            placeholder="Select SD Card"
                            name="storage_id"
                            disabled={isLoading}
                            onChange={(event: any, value: any) => {
                                onSelectChange('storage_id', value);
                            }}
                            sx={{minWidth: 200}}
                        >
                            {sdCards.map((card: any) => {
                                return (
                                    <Option key={card.id} value={card.id}>{card?.label}</Option>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Button type="submit" loading={isLoading}>Create</Button>
                    <Button color={"neutral"} onClick={onClose}>Close</Button>
                </Stack>
            </form>
            </DialogContent>
        </ModalWrapper>
    );
}

export default CreateCamera;
