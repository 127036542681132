export type CameraStorageType = {
    id: number,
    brand: StorageBrand,
    label: string,
    description?: string,
    image: string,
    capacity: number,
    speed_class: string,
    type: string,
}

export type StorageBrand = {
    id: number,
    name: string
}

export type CommandType = {
    name: string,
    label: string,
    priority?: "low" | "high",
    description?: string
}

export type MediaType = {
    id: number,
    account_id?: number,
    dateTaken: string;
    createdAt: string;
    camera_id: number,
    type: string,
    status: string,
    name: string,
    file: string,
    file_size: number,
    size: number,
    hd_uploaded: number,
    trigger?: number,
    weather: {
        temp: string;
        mintemp: string;
        maxtemp: string;
        wind: {
            speed: string;
            direction: string;
            degree: string;
        },
        moonPhase: {
            label: string;
            description: string;
        },
        conditions: {
            code: number;
            icon: string;
            label: string;
        },
        sunset: string;
        sunrise: string;
        moonrise: string;
        moonset: string;
        precipitation: string;
        barometer: string;
    },
    temperature?: string,
    path_on_sd?: string,
    videoLength?: string,
    video_length?: string,
    date_taken: string,
    filename_on_sd?: string,
    created_at: string,
    thumb: string,
    original: string
}

export const emptyMedia = {
    id: 0,
    account_id: 0,
    camera_id: 0,
    type: "",
    dateTaken: "",
    createdAt: "",
    status: "",
    name: "",
    file: "",
    file_size: 0,
    size: 0,
    hd_uploaded: 0,
    trigger: 0,
    temperature: "",
    path_on_sd: "",
    video_length: "",
    date_taken: "",
    filename_on_sd: "",
    videoLength: "",
    created_at: "",
    thumb: "",
    original: "",
    weather: {
        temp: "",
        mintemp: "",
        maxtemp: "",
        wind: {
            speed: "",
            direction: "",
            degree: "",
        },
        moonPhase: {
            label: "",
            description: "",
        },
        conditions: {
            code: 0,
            icon: "",
            label: "",
        },
        sunset: "",
        sunrise: "",
        moonrise: "",
        moonset: "",
        precipitation: "",
        barometer: "",
    },
}

export type NotificationType = "primary" | "danger" | "warning" | "success" | "neutral";

export type Notification = {
    message: string,
    type: NotificationType
}

export type OnNotificationType = (message: string, type?: NotificationType) => void;

export type UserRoleName = "admin" | "manager";

export type UserRole = {
    description: string,
    name: UserRoleName,
    permissions?: string[],
}

export type User = {
    id: number,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    role: UserRole,
    created: Date
};

export type PaginationMetadata = {
    totalPages: number,
    currentPage: number,
    limit: number,
}

export type FirmwareType = {
    id: number
    type: string,
    camera_code: string,
    version: string,
    filename: string,
    blocks: number,
    file_size: number,
    created_at: string,
    status: string
}

export type EventLogType = {
    created_at: string,
    event_data: string,
    event_source: string,
    command?: string,
    event_type: string,
}

export type CameraModelType = {
    id: number,
    name: string,
    code: string,
    description: string,
    label: string,
}

export type SimActivationType = {
    id: number,
    camera_id: number,
    created_at: string,
    iccid: string,
    status: string,
    json: string,
}
