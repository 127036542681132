import client from './api';

const me = async () => {
    return client.get('/profile');
}

const update = async (data: any) => {
    return client.put('/profile', {
        profile: data
    });
}

const changePassword = async (data: any) => {
    return client.put('/profile/password', data);
}

export default {
    me,
    update,
    changePassword
}