import * as React from "react";
import { useOutletContext, Link } from "react-router-dom";
import { IconButton, Stack, Typography, Button, ButtonGroup, Chip, ColorPaletteProp } from "@mui/joy";
import { useState } from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableHover } from "../../components/table";
import TableSheet from "../../components/table-sheet";
import { formatDateTime } from "../../components/utils";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddCamera from "./components/add-camera";
import Box from "@mui/joy/Box";
import { useSearchParams } from "react-router-dom";
import { getNumberFromSearchParams } from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";
import DeleteCamera from "./components/delete";
import DeleteSoftCamera from "./components/delete-soft";
import { useGetAccountCameras } from "../../query/accounts-query";
import { Account } from "../../types/account";

function chipStatusColor(status: string): string {

    if (status === "deleted") {
        return "error";
    }

    if (status === "disabled") {
        return "warning";
    }

    if (status === "inactive") {
        return "warning";
    }

    return "success";
}

export const Cameras = () => {
    const { account } = useOutletContext<{ account: Account }>();
    const [ showAddCamera, setShowAddCamera ] = useState(false);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, refetch, isLoading } = useGetAccountCameras(account.id, currentPage, limit);
    const camerasList = data?.list ?? [];
    const totalPages = data?.pagination?.totalPages ?? 1;
    const [ cameraDelete, setCameraDelete ] = useState<any>({
        show: false,
        camera: null
    });
    const [ cameraSoftDelete, setSoftDeleteCamera ] = useState<any>({
        show: false,
        camera: null
    });

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    const showAddCameraClick = () => {
        setShowAddCamera(true);
    };

    const onSoftDeleteClick = (camera: any) => {
        setSoftDeleteCamera({
            show: true,
            camera: camera
        });
    };

    const onHardDeleteClick = (camera: any) => {
        setCameraDelete({
            show: true,
            camera: camera
        });
    };

    const onDeleteClose = () => {
        setCameraDelete({ show: false, camera: null });
    };

    const onSoftDeleteClose = () => {
        setSoftDeleteCamera({ show: false, camera: null });
    };

    const onDeleteSuccess = async (cameraDelete: boolean = false) => {
        if (cameraDelete) {
            await refetch();
        }
        setCameraDelete({ show: false, camera: null });
    };

    const onSoftDeleteSuccess = async (cameraDelete: boolean = false) => {
        if (cameraDelete) {
            await refetch();
        }
        setSoftDeleteCamera({ show: false, camera: null });
    };

    return (
        <>
            <DeleteCamera open={cameraDelete.show} camera={cameraDelete.camera} onClose={onDeleteClose}
                          onSuccessCallback={onDeleteSuccess}/>
            <DeleteSoftCamera open={cameraSoftDelete.show} camera={cameraSoftDelete.camera} onClose={onSoftDeleteClose}
                              onSuccessCallback={onSoftDeleteSuccess}/>
            <AddCamera open={showAddCamera} account={account} onClose={() => {
                setShowAddCamera(false);
            }}/>
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>IMEI</th>
                        <th>Model</th>
                        <th style={{ width: "85px", textAlign: "center" }}>Status</th>
                        <th style={{ width: "190px" }}>Created At</th>
                        <th style={{ width: "100px" }}/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        isLoading
                            ? <tr style={{ textAlign: "center" }}>
                                <td colSpan={6}><CircularProgress/></td>
                            </tr>
                            :
                            camerasList.length > 0
                                ? camerasList.map((camera) => {
                                    let chipColor = chipStatusColor(camera.status) as ColorPaletteProp;

                                    return (
                                        <tr key={camera.id}>
                                            <td>
                                                <Typography level="body-xs">
                                                    {
                                                        camera?.accountCamera?.name ? camera.accountCamera.name :
                                                            <i>Not Set</i>
                                                    }
                                                </Typography>
                                            </td>
                                            <td>
                                                <Typography level="body-xs">{camera?.imei}</Typography>
                                            </td>
                                            <td>
                                                <Typography level="body-xs">{camera?.model?.label}</Typography>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <Typography level="body-xs"><Chip
                                                    color={chipColor}>{camera.status}</Chip></Typography>
                                            </td>
                                            <td>
                                                <Typography
                                                    level="body-xs">{formatDateTime(camera?.created_at)}</Typography>
                                            </td>
                                            <td>
                                                <ButtonGroup
                                                    color="neutral"
                                                    disabled={false}
                                                    size="sm"
                                                    variant="outlined"
                                                >
                                                    <IconButton variant={"outlined"} color={"primary"}
                                                                to={`/camera/${camera.id}`}
                                                                component={Link}><EditRoundedIcon
                                                        fontSize={"small"}/></IconButton>
                                                    <IconButton variant={"outlined"} color={"warning"} onClick={() => {
                                                        onSoftDeleteClick(camera);
                                                    }}><DeleteIcon/></IconButton>
                                                    <IconButton variant={"outlined"} color={"danger"} onClick={() => {
                                                        onHardDeleteClick(camera);
                                                    }}><DeleteIcon/></IconButton>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td colSpan={6} style={{ textAlign: "center" }}>
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{
                                                paddingTop: "20px",
                                                paddingBottom: "20px"
                                            }}
                                        >
                                            <Typography level="body-md">Cameras not found</Typography>
                                            <Button size={"sm"} onClick={showAddCameraClick}>
                                                <AddIcon/> Add
                                            </Button>
                                        </Stack>
                                    </td>
                                </tr>
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
};
