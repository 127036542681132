import * as React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { findCamera } from "../../api/cameras";
import PageHeader from "../../components/page-header";
import StickyHeader from "../../components/sticky-header";
import { PageNavTab } from "../../components/page-nav-tab";
import Loader from "../../components/loader";
import Box from "@mui/joy/Box";
import { AppContext } from "../../components/app-context";
import { handleAxiosError } from "../../components/utils";
import { Button } from "@mui/joy";
import MemoryIcon from "@mui/icons-material/MemoryOutlined";
import FirmwareUpdateModal from "./components/firmware-update-modal";
import MediaInfoDrawer from "../../components/camera/media-info-drawer";

export const CameraIndex = () => {
    const { id, mediaId } = useParams();
    const { onNotification } = useContext(AppContext);
    const [ camera, setCamera ] = useState<any>(null);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ showFirmwareUpdate, setShowFirmwareUpdate ] = useState(false);

    useEffect(() => {
        fetchCamera();
    }, [ id ]);

    const fetchCamera = async () => {
        if (id) {
            setIsLoading(true);
            try {
                const responseCamera = await findCamera(parseInt(id));
                setCamera(responseCamera.data);
            } catch (error: any) {
                handleAxiosError(error, onNotification);
            }
            setIsLoading(false);
        }
    };

    let name = camera?.imei;
    if (camera?.accountCamera?.name) {
        name = camera?.accountCamera?.name;
    }

    return (
        <>
            {!isLoading &&
              <FirmwareUpdateModal
                open={showFirmwareUpdate}
                onClose={() => setShowFirmwareUpdate(false)}
                successCallback={() => setShowFirmwareUpdate(false)}
                camera={camera}
              />
            }
            <StickyHeader>
                <PageHeader title={`Camera: ${name}`}
                            breadCrumbs={[ { name: "Cameras", to: "/cameras" }, { name: `Camera "${name}"` } ]}>
                    <Button color={"warning"} onClick={() => setShowFirmwareUpdate(true)}><MemoryIcon/> Firmware
                        Update</Button>
                </PageHeader>
                <PageNavTab list={[
                    { name: "General", to: `/camera/${id}` },
                    { name: "SIM", to: `/camera/${id}/sim` },
                    { name: "Settings", to: `/camera/${id}/settings` },
                    { name: "Report", to: `/camera/${id}/report` },
                    { name: "Media", to: `/camera/${id}/media` },
                    { name: "Commands", to: `/camera/${id}/commands` },
                    { name: "Raw Reports Log", to: `/camera/${id}/reports` },
                    { name: "Errors", to: `/camera/${id}/errors` },
                    { name: "Media Errors", to: `/camera/${id}/media-errors` },
                    { name: "Events Log", to: `/camera/${id}/events-log` },
                ]}/>
            </StickyHeader>
            {isLoading && <Loader/>}
            {!isLoading && (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    px: { xs: 2, md: 6 },
                    py: { md: 3 },
                }}>
                    <Outlet context={{ camera, fetchCamera }}/>

                </Box>
            )}
            {id && mediaId ? <MediaInfoDrawer id={id} mediaId={mediaId}/> : null}
        </>
    );
};
