import * as React from "react";
import { useState, useContext } from "react";
import ListPage from "../../components/listPage";
import { IconButton, ButtonGroup, Box, Typography, Chip } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownload from "@mui/icons-material/FileDownload";
import { formatDateTime } from "../../components/utils";
import Button from "@mui/joy/Button";
import AddIcon from "@mui/icons-material/Add";
import Filters from "./components/filters";
import ApiFirmware from "../../api/firmware";
import { fileSize, downloadUrl } from "../../components/utils";
import CreateFirmware from "./components/create";
import { FirmwareType } from "../../components/types";
import DeleteFirmware from "./components/delete";
import { useSearchParams } from "react-router-dom";
import { getNumberFromSearchParams } from "../../components/utils";
import qs from "qs";
import { AppContext } from "../../components/app-context";
import { handleAxiosError } from "../../components/utils";
import { ColorPaletteProp } from "@mui/material";
import { useGetFirmwares } from "../../query/firmware-query";

function chipStatusColor(status: string): string {

    if (status === "deleted") {
        return "danger";
    }

    if (status === "inactive") {
        return "neutral";
    }

    return "success";
}

type FirmwareDeleteState = {
    show: boolean,
    firmware: FirmwareType | null,
}

export const Firmwares = () => {
    const { onNotification } = useContext(AppContext);
    const [ showCreateFirmware, setShowCreateFirmware ] = useState<boolean>(false);
    const [ firmwareDelete, setFirmwareDelete ] = useState<FirmwareDeleteState>({
        show: false,
        firmware: null
    });
    const [ searchParams, setSearchParams ] = useSearchParams();
    const filters = qs.parse(searchParams.toString()).filters || {};
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, refetch, isLoading } = useGetFirmwares({
        filters,
        page: currentPage,
        limit
    });

    const firmwareList = data?.list ?? [];

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                filters: filters,
                limit: perPage,
                page,
            })
        );
    };

    const onDownload = async (firmware: FirmwareType) => {
        try {
            const { data } = await ApiFirmware.downloadUrl(firmware.id);
            downloadUrl(data.url, firmware.filename);
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }
    };

    const onDeleteClick = (firmware: FirmwareType) => {
        setFirmwareDelete({
            show: true,
            firmware: firmware
        });
    };

    const onDeleteClose = () => {
        setFirmwareDelete({ show: false, firmware: null });
    };

    const onDeleteSuccess = async () => {
        setFirmwareDelete({ show: false, firmware: null });
        await refetch();
    };

    const onCreateClose = () => {
        setShowCreateFirmware(false);
    };

    const onCreateSuccess = async () => {
        onCreateClose();
        await refetch();
    };

    const applyFilters = (filters: any) => {
        setSearchParams(
            qs.stringify({
                filters: filters,
                page: 1,
                limit: limit
            })
        );
    };

    const headers = [
        { width: 150, label: "Uploaded By" },
        { width: 120, label: "Status" },
        { width: 120, label: "Type" },
        { width: 150, label: "Model" },
        { width: 150, label: "Version" },
        { width: 200, label: "FileName" },
        { width: 100, label: "Blocks/Size" },
        { width: 150, label: "Created At" },
        { width: 80, label: "" },
    ];

    const pagination = {
        count: data?.pagination?.totalPages ?? 1,
        page: currentPage,
        limit,
        onChange: handlePaginationChange,
    };

    return (
        <>
            <CreateFirmware
                open={showCreateFirmware}
                onClose={onCreateClose}
                onSuccessCallback={onCreateSuccess}
            />
            <DeleteFirmware open={firmwareDelete.show} firmware={firmwareDelete.firmware} onClose={onDeleteClose}
                            onSuccessCallback={onDeleteSuccess}/>
            <ListPage
                title="Manage Firmwares"
                pagination={pagination}
                breadcrumbs={[ { name: "Firmwares" } ]}
                loading={isLoading}
                headers={headers}
                empty={firmwareList.length < 1}
                filters={(<Filters filters={filters} applyFilters={applyFilters}/>)}
                placeholder="Firmware not found"
                actions={(
                    <Button
                        size={"sm"}
                        disabled={isLoading}
                        onClick={() => {
                            setShowCreateFirmware(true);
                        }}
                    >
                        <AddIcon/> Create
                    </Button>
                )}
            >
                {firmwareList.map((firmware) => {
                    const statusColor = chipStatusColor(firmware.status) as ColorPaletteProp;
                    return (
                        <tr key={firmware.id}>
                            <td>
                                <Typography level="body-xs"><i>Not set</i></Typography>
                            </td>
                            <td>
                                <Typography level="body-xs"><Chip
                                    color={statusColor}>{firmware.status}</Chip></Typography>
                            </td>
                            <td>
                                <Typography level="body-xs">{firmware.type}</Typography>
                            </td>
                            <td>
                                <Typography level="body-xs">{firmware.camera_code}</Typography>
                            </td>
                            <td>
                                <Typography level="body-xs">{firmware.version}</Typography>
                            </td>
                            <td>
                                <Typography level="body-xs">{firmware.filename}</Typography>
                            </td>
                            <td>
                                <Typography
                                    level="body-xs">{firmware.blocks} / {fileSize(firmware.file_size)}</Typography>
                            </td>
                            <td>
                                <Typography
                                    level="body-xs">{formatDateTime(firmware.created_at)}</Typography>
                            </td>
                            <td>
                                <ButtonGroup
                                    color="neutral"
                                    disabled={false}
                                    size="sm"
                                    variant="outlined"
                                >
                                    <IconButton
                                        variant={"outlined"}
                                        color={"primary"}
                                        onClick={() => onDownload(firmware)}>
                                        <FileDownload/>
                                    </IconButton>
                                    <IconButton
                                        variant={"outlined"}
                                        color={"danger"}
                                        onClick={() => onDeleteClick(firmware)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </ButtonGroup>
                            </td>
                        </tr>
                    );
                })}
            </ListPage>
        </>
    );
};
