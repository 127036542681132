import * as React from 'react';
import Box from '@mui/joy/Box';
import Sidebar from './sidebar';
import Header from './header';
import {Outlet} from "react-router-dom";

export const Main = () => {
    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Header />
            <Sidebar />
            <Box
                component="main"
                className="MainContent"
                sx={{
                    pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
                    pb: { xs: 2, sm: 2, md: 3 },
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    height: '100dvh',
                    gap: 1,
                    overflow: 'auto',
                }}
            >
                <Outlet/>
            </Box>
        </Box>
    );
}