import * as React from 'react';
import {useState, useContext} from "react";
import {CommandType} from "../../../components/types";
import {
    Modal,
    ModalDialog,
    Button,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Typography,
} from '@mui/joy';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ApiCameraCommands from "../../../api/camera-command";
import {AppContext} from "../../../components/app-context";
import {handleAxiosError} from "../../../components/utils";

type TriggerCommandModalProps = {
    open: boolean,
    onClose: any,
    command?: CommandType,
    successCallback: any,
    cameraId: number
}

export const TriggerCommandModal = ({open, onClose, command, successCallback, cameraId}: TriggerCommandModalProps) => {

    const {onNotification} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    const onConfirmClick = async () => {
        setIsLoading(true);
        try {
            await ApiCameraCommands.trigger(cameraId, command?.name || "");
            successCallback();
            onClose();
        } catch (error: any) {
            handleAxiosError(error, onNotification);
        }

        setIsLoading(false);
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <InfoRoundedIcon />
                    Trigger Command
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography>Are you sure that you want trigger "{command?.name}" command?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="warning" loading={isLoading} onClick={onConfirmClick}>
                        Confirm
                    </Button>
                    <Button variant="plain" disabled={isLoading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default TriggerCommandModal;