import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
});

instance.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem('TOKEN');
        const auth = token ? `Bearer ${token}` : '';
        config.headers['Authorization'] = auth;
        return config;
    },
    (error) => Promise.reject(error),
);

instance.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        window.localStorage.removeItem('TOKEN');
        window.location.href = '/login';
    }

    return Promise.reject(error);
});

export default instance;